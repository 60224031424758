.order-wrapper,
.page-sbbu,
.page-order-receipt-selfcare-step {
    .progress-bar {
        display:        block;
        font-family:    $font-cervo;
        text-transform: uppercase;
        font-weight:    300;
        letter-spacing: .035em;
        line-height:    1.2;

        a, span {
            display: inline-block;
        }

        a {
            color:                 $hiper-red;
            border:                none;
            text-decoration:       underline;
            text-decoration-color: $hiper-red;
            text-underline-offset: .1em;
        }

        a:hover {
            color:                 rgba($hiper-red, .75);
            text-decoration-color: rgba($hiper-red, .75);
        }

        & > span.item {
            color: rgba($hiper-blue, .5);

            &.current {
                color:       $hiper-blue;
                font-weight: 400;
            }
        }

        .progress-arrow {
            position: relative;
            width:    .5em;
            height:   .5em;

            &::before, &::after {
                content:          '';
                position:         absolute;
                top:              0;
                left:             0;
                width:            8px;
                height:           1px;
                background-color: $hiper-blue;
            }

            &::before {
                transform: rotate(45deg);
            }

            &::after {
                transform: translate(0, 5px) rotate(-45deg);
            }
        }

        @media (min-width: 961px) {
            padding:       .5em .75em .2em;
            width:         fit-content;
            border-radius: 1em;
            box-shadow:    var(--box-shadow);
        }
    }
}

.order-wrapper {
    display:   grid;
    max-width: 68em;

    input, label, .address-widget input, .address-widget li {
        &, &::placeholder {
            text-align: left;
        }
    }

    select {
        text-align:      left;
        text-align-last: left;
    }

    .heading {
        color: $hiper-blue;

        &:not(.heading--montserrat) {
            font-weight: 300;
        }
    }

    h1:not(.heading--big),
    &:not(.new-receipt-flow) .order-summary .accordion h2 {
        font-size:   2em;
        line-height: .8;
    }

    .order-content h2,
    .order-content h3 {
        padding-bottom: .5rem;

        &:not(.heading--montserrat) {
            font-size: 1.75em;
        }

        &:not(:first-child) {
            padding-top: 1rem;
        }
    }

    &:not(.new-receipt-flow) {
        .order-summary .accordion-container {
            border-bottom: none;
        }

        .order-summary .accordion-content-container {
            max-height:     none;
            border:         none;
            opacity:        1;
            overflow:       visible;
            pointer-events: all;
            transition:     max-height .2s $ease-in-out-cubic;
        }
    }

    .order-summary .accordion .symbol {height: .6em}

    .campaign-code-container {
        p:first-of-type {padding-bottom: 0;}

        form {
            margin-top:     .25em;
            padding-bottom: .45em;  // Optical spacing to match the other stuff
        }

        .campaign-code > div,
        .campaign-code-active > div {
            display:     grid;
            align-items: center;
            width:       100%;
            height:      2.5rem;
        }

        .campaign-code > div {
            grid-template-columns: auto 5em;
            gap:                   .25em;

            .code, .button {
                padding:    .2em .5em;
                height:     2.5rem;
                appearance: none;
            }

            .button {
                width:            auto;
                font-size:        1.2em;
                text-align:       center;
                background-color: $hiper-teal;

                &:hover {
                    background-color: $hiper-darkteal;
                }
            }
        }

        #continue-campaign-code-warning {
            display: none;

            .red {
                color: $hiper-red;
            }

            &.visible {
                display: block;
            }
        }

        .campaign-code-active > div {
            grid-template-columns: 2em auto 2em;
            padding:               0 .25em;
            border-radius:         .5em;
            background-color:      $hiper-teal;

            .icon {
                display: block;
                width:   2em;
                height:  2em;
                color:   transparent;
            }

            .text {
                width:          100%;
                margin-right:   .25em;
                padding:        0;
                font-size:      .9em;
                color:          #ffffff;
                text-transform: lowercase;
                letter-spacing: .05em;
            }

            .remove {
                border-radius:    1em;
                border:           0;
                font-size:        1em;
                background-color: $hiper-darkteal;
                position:         relative;
                transition:       background-color .2s $ease-in-out-cubic;

                &:hover {
                    background-color: $hiper-teal;
                }

                &, input {
                    width:  2em;
                    height: 2em;
                    cursor: pointer;
                }

                input {
                    appearance: none;
                    opacity:    0;
                }

                &::before, &::after {
                    content:          '';
                    height:           2px;
                    width:            1em;
                    position:         absolute;
                    top:              50%;
                    left:             50%;
                    background-color: #ffffff;
                    pointer-events:   none;
                }

                &::before {
                    transform: translate(-50%, -50%) rotate(45deg);
                }

                &::after {
                    transform: translate(-50%, -50%) rotate(-45deg);
                }
            }
        }

        .campaign-code-invalid {
            @include font-weight-variable(500);
            margin-top: -.15rem;
            padding:    0;
            color:      $hiper-red;
        }
    }

    .flatpickr-wrapper .flatpickr-calendar {
        margin-top: .5em;
        padding:    0;

        @media (max-width: 400px) {min-width: 0;}
        @media (max-width: $phone-small) {
            .flatpickr-day {width: calc(calc(100vw - 1em) / 7 - 4px );} // Adaptive width
        }
    }

    .order-header  {grid-area: header;}
    .order-coop    {grid-area: coop;}
    .order-content {grid-area: content;}
    .order-summary {grid-area: summary;}

    @media (min-width: 961px) {
        padding:               2em 0;
        grid-template-columns: auto 22em;
        grid-template-areas:   "header header"
                               "content summary";

        &.order-wrapper--coop {
            grid-template-areas:   "header header"
                                   "coop coop"
                                   "content summary";
        }

        .order-header                       {padding: 0 1em 2em 1em;}
        &.order-wrapper--coop .order-header {padding: 0 1em .5em 1em;}
        .order-content, .order-summary      {padding: 0 2em;}

        .order-content {
            border-right: 1px solid $hiper-darkgray;
        }

        h1,
        &:not(.new-receipt-flow) .order-summary .accordion h2 {
            padding-bottom: 1.5rem;
        }

        &:not(.new-receipt-flow) .order-summary .accordion,
        &:not(.new-receipt-flow) .order-summary .accordion-content {padding: 0;}

        &:not(.new-receipt-flow) .order-summary .accordion {
            display:        block;
            border:         none;
            pointer-events: none;

            .hide-on-large-devices,
            .symbol,
            .price {display: none;}
        }

        .campaign-code-container p:first-of-type {
            margin-top: -.45em; // Optical alignment to .order-content stuff
        }
    }

    @media (max-width: 960px) {
        padding:               0;
        grid-template-columns: 1fr;
        grid-template-areas:   "summary"
                               "header"
                               "content";

        &.order-wrapper--coop {
            grid-template-areas: "summary"
                                 "header"
                                 "coop"
                                 "content";
        }

        .order-header                       {padding: 1em;}
        &.order-wrapper--coop .order-header {padding: 1em 1em .5em;}
        .order-content                      {padding: 0 1em 1em;}

        h1 {padding: .5rem 0 1rem;}

        &:not(.new-receipt-flow) .order-summary {
            $transition:      .3s $ease-in-out-cubic;

            background-color: $hiper-lightergray;
            border-bottom:    1px solid $hiper-gray;

            .accordion {
                padding:       .5em 1em;
                border-top:    none;
                border-bottom: 1px solid $hiper-gray;
                transition:    border-bottom-color $transition;

                &, &:hover {
                    color: $hiper-blue;
                }

                .symbol, h2 {display:     inline-block;}
                h2, .price  {line-height: 1.2;}

                h2 {
                    @include        font-weight-variable(400);
                    font-family:    $font-montserrat;
                    font-size:      1.4em;
                    text-transform: none;
                    letter-spacing: normal;

                    .hide-on-small-devices {display: none;}
                }

                .price {
                    @include   font-weight-variable(500);
                    font-size: 1.2em;
                }

                &:hover:not(.active) {
                    .symbol span:first-child,
                    .symbol span:nth-child(2) {background-color: $hiper-blue;}
                    h2                        {color:            $hiper-blue;}
                }

                &:not(.active) h2 .hide-on-large-devices span:nth-child(2) {display: none;}

                &.active {
                    border-bottom-color: transparent;

                    h2 .hide-on-large-devices span:first-child {display: none;}
                }
            }

            .accordion-content-container {
                display:          flex;
                flex-direction:   column-reverse;
                justify-content:  center;
                align-items:      center;
                margin:           0 auto;
                padding:          0 1em;
                max-width:        26em;
                background-color: $hiper-lightergray;
            }

            .campaign-code-container, .accordion-content {
                width: 100%;
            }

            .accordion-content {
                padding:        0;
                max-height:     0;
                opacity:        0;
                overflow:       hidden;
                pointer-events: none;
                transition:     max-height $transition,
                                opacity $transition,
                                margin-bottom $transition;
            }

            .accordion-content-container.active .accordion-content {
                max-height:     999em;
                opacity:        1;
                pointer-events: all;
                margin-bottom:  1em;
            }
        }
    }

    @media (max-width: $phone) {
        .order-header                               {padding: 1em .5em;}
        &.order-wrapper--coop .order-header         {padding: 1em .5em .5em;}
        .order-content                              {padding: 0 .5em 1em .5em;}
        .order-summary .accordion                   {padding: .5em;}
        .order-summary .accordion-content-container {padding: 0 .5em;}

        &:not(.new-receipt-flow) .order-summary .accordion h2 {
            font-size: 1.2em;
        }
    }
}

.order-content {
    .permissions-disclaimer {
        padding-bottom: 1em;
    }

    .order-submit-back {
        display:         flex;
        justify-content: center;
        align-items:     center;
        flex-wrap:       wrap-reverse;
        gap:             .5em 1em;
        margin-top:      1em;

        .button {
            padding:     .8em 1.6em;
            line-height: .8;
            text-align:  center;
        }

        .back {
            border:           1px solid $hiper-darkgray;
            background-color: $hiper-lightergray;
            color:            $hiper-blue;

            &:hover {
                background-color: $hiper-lightgray;
            }
        }

        .submit {
            background-color: $hiper-teal;

            &:hover {
                background-color: $hiper-darkteal;
            }

            &.shimmer {
                position: relative;
                overflow: hidden;

                &::after {
                    content:        '';
                    width:          100%;
                    height:         100%;
                    position:       absolute;
                    top:            0;
                    left:           0;
                    transform:      translateX(-100%);
                    pointer-events: none;
                    animation:      shimmer 4s linear 5;
                    background:     linear-gradient(120deg,
                                    rgba(#ffffff, 0)  25%,
                                    rgba(#ffffff, .4) 50%,
                                    rgba(#ffffff, 0)  75%);

                    @keyframes shimmer {
                        0%   {transform: translateX(-100%);}
                        10%  {transform: translateX(-100%);}
                        25%  {transform: translateX(100%);}
                        100% {transform: translateX(100%);}
                    }
                }
            }
        }

        .text {
            font-size:   1.6em;
            white-space: nowrap;
        }

        @media (min-width: 521px) {
            #submit-form-button, .button {flex: 0 1 10em;}
        }

        @media (max-width: $phone) {
            #submit-form-button, .button {width: 100%;}
        }
    }
}

.order-summary {
    .accordion .star {
        margin-left:    -.15em;
        opacity:        .75;
        font-size:      .8em;
        vertical-align: top;
    }

    p, th, td {
        font-size: .8em;
    }

    p {line-height: 1.4;}

    hr {
        margin:           1em 0;
        padding:          0;
        max-width:        none;
        width:            100%;
        background-color: $hiper-darkgray;
    }

    .changeable {
        display:         flex;
        justify-content: space-between;
        align-items:     center;
        gap:             1em;

        &:first-child {
            padding-top: 0;
        }

        p {
            flex:    1 1 calc(100% - 2.5em);
            padding: 0;
        }

        a {
            border: 0;
            cursor: pointer;
        }

        & > a {
            width:  1.5em;
            height: 1.5em;
        }

        .icon {
            width:      1.5em;
            height:     1.5em;
            color:      $hiper-blue;
            fill:       #ffffff;
            transition: color .1s $ease-in-out-quad;

            &:hover {
                color: lighten($hiper-blue, 5%);
            }
        }

        .mailbox-address {
            text-transform: capitalize;
            font-size:      .9em;
        }

        &.changeable--address {
            position: relative;

            .changeable-overlay {
                display:  none;
                position: fixed;
                top:      0;
                left:     0;
                right:    0;
                bottom:   0;
            }

            .changeable-warning {
                display:          none;
                position:         absolute;
                width:            100%;
                left:             0;
                border-radius:    1em;
                background-color: $hiper-teal;
                padding:          .5em;

                @media (min-width: 961px) {
                    top:        50%;
                    transform:  translateY(-50%);
                    box-shadow: 0 2px 18px -2px rgba(#000000, .2),
                                0 1px 6px rgba(#000000, .2);
                }

                @media (max-width: 960px) {
                    top: 0;
                }

                p {
                    padding:    .5em 0;
                    font-size:  .9em;
                    text-align: center;
                    color:      #ffffff;
                }

                div {
                    display:         flex;
                    justify-content: center;
                    align-items:     center;
                    gap:             .25em;

                    a {
                        flex:             1 1 50%;
                        border:           none;
                        padding:          .2em .4em;
                        border-radius:    .5rem;
                        font-family:      $font-cervo;
                        font-size:        1.4em;
                        font-weight:      300;
                        text-transform:   uppercase;
                        text-align:       center;
                        line-height:      1.2;

                        &.back {
                            color:            $hiper-blue;
                            background-color: #ffffff;
                        }

                        &.continue {
                            color:  #ffffff;
                            border: 1px solid #ffffff;
                        }
                    }
                }
            }
        }
    }

    .summary-table {
        padding-bottom: 0;
    }

    @media (max-width: 960px) {
        .accordion-content-container > hr:last-of-type,
        .page-order-summary & .accordion-content hr:last-of-type {
            display: none;
        }

        .campaign-code-container {
            padding:    .25em 0 0;
            transition: border-top .3s $ease-in-out-cubic,
                        padding .3s $ease-in-out-cubic;
        }

        .accordion.active + .accordion-content-container .campaign-code-container {
            border-top: 1px solid $hiper-darkgray;
            padding:    1em 0;
        }
    }
}

.order-usp {
    margin-top:       2em;
    padding-bottom:   .5em;
    border-radius:    1em;
    background-color: $hiper-lightergray;
}

.page-order-products {
    .content.content--order {
        padding-left:  0;
        padding-right: 0;
        max-width:     100%;
    }
}

.page-order-products-show {
    .site-wrap {
        padding-bottom: 10em;

        @media (max-width: 870px) {
            padding-bottom: 20em;
        }
    }

    @at-root {
        .chosen-address {
            font-size: 1.1em;
            text-align: center;
            color:      $hiper-blue;
        }

        .chosen-address__address {
            @include font-weight-variable(500);
        }

        @media (min-width: 751px) {
            .hide-on-large-devices {display: none;}
        }

        @media (max-width: 750px) {
            .hide-on-small-devices {display: none;}
        }
    }
}

.page-order {
    section {
        height: 80vh;

        .content {
            .heading {
                color: $hiper-blue;
            }

            p {
                text-align: center;
            }

            .address-lookup {
                display:       flex;
                min-height:    100vh;
                min-height:    calc(100vh - (4.375em + 3.125em));
                margin-top:    -4.375em;
                margin-bottom: -3.125em;
                padding:       0;
            }
        }
    }
}

.page-order-products-not-found {
    .order-wrapper {
        margin:          0 auto;
        display:         flex;
        justify-content: center;
        align-items:     center;
        min-height:      30vh;

        .order-content {
            max-width:    40em;
            border-right: none;

            @media (max-width: 960px)  {padding: 2em 1em;}
            @media (max-width: $phone) {padding: 2em .5em;}
        }
    }

    .order-content > p:first-of-type {
        padding-bottom: 0;
    }

    .address, .new-address {
        text-align: center;
    }

    .address {
        padding-bottom: 0;
        font-size:      1.6em;
        line-height:    1.2;
        color:          $hiper-blue;
    }

    .new-address {
        margin-bottom: .7em;
    }

    form {
        margin:          0 auto;
        display:         flex;
        flex-flow:       row wrap;
        justify-content: center;
        align-items:     center;
        gap:             .5em;

        p {
            margin:  0;
            padding: 0;
        }

        .icon {
            width:  2.5rem;
            height: 2.5rem;
        }

        @media (min-width: 351px) {
            p {
                &:first-of-type,
                &:nth-of-type(2) {
                    flex: 1 1 calc(50% - 1em);
                }

                &:nth-of-type(3) {
                    flex: 0 0 calc(100% - 3em);
                }
            }
        }

        @media (max-width: $phone-small) {
            p {
                flex: 0 0 100%;

                &:last-child {
                    background-color: $hiper-red;
                    border-radius:    .5em;
                }
            }
        }
    }
}

.order-coop {
    display:         flex;
    justify-content: center;
    align-items:     center;

    .hiper-coop-logo {
        max-width:     50vw;
        width:         20em;
        margin-bottom: .5em;

        @media (max-width: $phone) {
            max-width: 60vw;
        }
    }
}

.page-order-products,
.order-coop {
    .hiper-coop-logo .white {fill: $hiper-blue;}
}

.page-order-coop {
    .coop-card,
    #coop_membership_no {
        display: block;
        margin:  0 auto;
    }

    #coop_membership_no {
        max-width: 21em;
    }

    .order-content .accordion-container {
        margin-top: 2em;
    }
}

.page-order-contact {
    form p {
        padding-bottom: 1em;

        &:last-of-type {
            padding-bottom: 0;
        }

        &.error-message {
            @include font-weight-variable(500);
            margin-top:     -.5em;
            padding-bottom: .25em;
            font-size:      .8em;
            color:          $hiper-red;
        }
    }

    @at-root {
        .mailcheck-suggestion {
            display:    none;
            text-align: center;

            &.visible {
                display: block;
            }
        }
    }
}

.page-order-sbbu {
    @at-root {
        .sbbu-details {
            @include    hidden;
            max-width:  32em;
            max-height: 0;

            &.visible {
                @include       visible;
                max-height:    100em;
                margin-bottom: 1em;
                transition:    max-height,
                               opacity .5s ease;
            }
        }

        .sbbu-details__hint {
            padding-top: .5em;
        }

        .small-label {
            color:      $hiper-blue;
            text-align: center;
            padding:    1em 0 0 0;
        }

        .small-label--left {
            text-align: left;
        }

        .sbbu-details .small-label:first-child {
            padding-top: 0;
        }
    }
}

.page-order-installation {
    .installation-banner {
        margin-bottom: 1em;
        padding:       .8em 1.2em;
        border-radius: .5em;
        background:    $hiper-darkred;
        color:         #ffffff;

        .heading {
            color: inherit;
        }
    }

    .tech-call-before-label {
        display:  inline-block;
        width:    auto;
        position: relative;
    }

    .inline.tech-call-before {
        width:         7.5em;
        padding-right: 1.5em;

        + .icon {
            display:  inline-block;
            height:   1em;
            position: absolute;
            top:      .375em;
            left:     6em;
        }
    }

    .yes-no {
        flex-direction:  column;
        align-items:     flex-start;
        justify-content: center;
    }

    .yes, .no {
        min-width: 10.6em;
    }

    .installation-date {
        @include         hidden;
        display:         flex;
        margin-bottom:   1em;
        max-height:      0;
        flex-direction:  column;
        align-items:     center;
        justify-content: center;
        opacity:         0;
        transition:      .3s all $ease-in-out-cubic;

        div:first-child {
            width: 100%;
        }

        p {
            opacity:    0;
            transition: .3s all $ease-in-out-cubic;
        }

        &.visible {
            @include        visible;
            max-height:     100em;

            p {opacity: 1;}
        }
    }

    .order-content li {
        display:         inline-flex;
        justify-content: flex-start;
        align-items:     center;
        gap:             .75em;

        &:last-child {padding-bottom: 1.4em}

        .icon {
            width:       2.5em;
            height:      2.5em;
            flex-shrink: 0;

            @media (max-width: $phone) {
                width:  3em;
                height: 3em;
            }
        }

        .text {width: 100%;}
    }
}

.select-shipping-address {
    .input-container {padding-bottom: .25em;}

    .input-container label {
        display:        block;
        padding-bottom: .25em;
        hyphens:        manual;
        line-height:    1.4;
    }

    .input-with-button {
        &, .button-container {
            display:         flex;
            justify-content: center;
            align-items:     center;
        }

        input {
            border-top-right-radius:    0;
            border-bottom-right-radius: 0;
            border-right:               0;
        }

        .button-container {
            padding:                    0 .2em;
            height:                     2.5rem;
            border-top-right-radius:    .5rem;
            border-bottom-right-radius: .5rem;
            border:                     1px solid $hiper-darkgray;
            border-left:                0;
        }

        .icon-button {
            margin:     0;
            padding:    0;
            border:     0;
            background: none;
            font-size:  1.75em;
            cursor:     pointer;

            &, .icon {
                width:  1em;
                height: 1em;
            }

            &:hover {
                .icon {
                    transform: rotate(-15deg) scale(1.25);
                    opacity:   .75;
                }
            }
        }

        .icon {
            display:          block;
            color:            transparent;
            fill:             $hiper-blue;
            transform-origin: center;
            transition:       .15s transform $ease-in-out-cubic,
                              .15s opacity $ease-in-out-cubic;
        }
    }

    .mailbox-list {
        overflow-y:    auto;
        border-bottom: 1px solid $hiper-gray;
    }

    .mailbox-item {
        display:        block;
        padding-bottom: 0;
        width:          100%;
        border-bottom:  1px solid $hiper-gray;

        &:last-child {border-bottom: none;}

        .checkbox-label {
            position:    relative;
            display:     block;
            padding:     .5em 0 .5em calc(1.2rem + .5em);
            width:       100%;
            line-height: 1.25;

            &::before {
                position:  absolute;
                top:       50%;
                left:      2px;
                transform: translateY(-50%);
                margin:    0;
            }
        }

        .mailbox-name,
        address {
            display: block;
            hyphens: manual;
        }

        .mailbox-distance {
            @include font-weight-variable(500);
        }

        .mailbox-address {
            text-transform: capitalize;
        }
    }

    @media (min-width: 521px) {
        .mailbox-item address {
            font-size: .8em;
        }
    }

    @media (max-width: $phone) {
        .mailbox-list {
            max-height: 35vh;
        }

        .mailbox-item address {
            font-size: .9em;
        }
    }
}

.order-content,
.order-summary,
.wl-overlay {
    .strikethrough {
        position:  relative;

        &::before {
            content:          '';
            position:         absolute;
            top:              100%;
            transform:        translate(-5%, -.6em) rotate(-17deg);
            width:            100%;
            height:           2px;
            border-radius:    1px;
            background-color: $hiper-red;

            @media (max-width: $phone) {
                transform: translate(-5%, -.55em) rotate(-17deg);
            }
        }
    }
}

.summary-table {
    border: 0;

    .summary-table + & {
        margin-top: 1em;
    }

    @media (min-width: 961px) {
        .star {display: none;}
    }

    &, th, tr, td {
        border-radius: 0;
    }

    th, td {
        padding:          .25em 0;
        border:           0;
        background-color: transparent;
        color:            $hiper-blue;
        line-height:      1.2;
    }

    th {
        @include font-weight-variable(600);

        &:last-child   {text-align: right;}
        &[colspan="2"] {text-align: center;}
    }

    td {
        padding-left:   0;
        padding-right:  0;
        vertical-align: middle;

        &:first-child:not(:only-child) {
            padding-right: .4em;
        }

        &:last-child:not(:only-child) {
            text-align:  right;
            white-space: nowrap;
        }
    }

    small {
        font-size: .9em;
        color:     rgba($hiper-blue, .9);
    }

    tbody, tfoot {
        & > tr:first-child > td {
            padding-top: .5em;
        }

        & > tr:last-child > td {
            padding-bottom: .5em;
        }
    }

    .discount-total td {
        @include          font-weight-variable(500);
        vertical-align:   middle;
        background-color: $hiper-green;
        color:            #ffffff;

        &:first-child {
            padding-top:   .25em;
            padding-left:  .4em;
            border-radius: .5em 0 0 .5em;
        }

        &:last-child {
            padding-top:   .25em;
            padding-right: .4em;
            border-radius: 0 .5em .5em 0;

            & > span {font-size: 1.5em;}
        }
    }

    .discount {
        @include font-weight-variable(500);
        color:   $hiper-green;
    }

    tfoot {
        td {@include font-weight-variable(500);}

        .total-row {
            &:first-child td {
                border-top: 1px solid $hiper-gray;
            }

            &:last-child td {
                border-bottom: 1px solid $hiper-gray;
            }

            td {
                padding-bottom: 0;
                border-radius:  0 !important;
            }
        }
    }
}

.summary-table-expandable {
    display:         flex;
    flex-direction:  column-reverse;
    justify-content: center;
    align-items:     center;
    margin-top:      .5em;

    .summary-table > tbody > tr {
        background-color: transparent;

        & > td {
            padding:          0;
            background-color: transparent;
        }

        table {
            td               {font-size:      1em;}
            tr:last-child td {padding-bottom: .4em;}
        }

        div {
            overflow:   hidden;
            opacity:    0;
            max-height: 0;
            transition: opacity .15s $ease-in-out-cubic,
                        max-height .3s $ease-in-out-cubic;
        }
    }
}

.expand-summary-table {
    display:          flex;
    justify-content:  center;
    align-items:      center;
    margin:           0 auto;
    padding:          0 .4em;
    width:            10em;
    border:           0;
    border-radius:    0 0 .5em .5em;
    cursor:           pointer;
    background-color: $hiper-teal;
    color:            #ffffff;
    font:             inherit;
    font-size:        .7em;
    transition:       background-color .15s $ease-in-out-cubic;

    &:hover {background-color: $hiper-darkteal;}

    .show, .hide {margin-left: .25em;}
    .hide        {display:     none;}

    .icon {
        display:    block;
        width:      1.5em;
        height:     1.5em;
        transform:  rotate(90deg);
        color:      transparent;
        fill:       #ffffff;
        transition: transform .15s $ease-in-out-cubic;
    }

    &.visible {
        .show {display:   none;}
        .hide {display:   block;}
        .icon {transform: rotate(-90deg)}
    }
}

.expand-summary-table.visible + .summary-table > tbody > tr div {
    opacity:    1;
    max-height: 50em;
}

.summary-vat {
    @include     font-weight-variable(600);
    padding-top: .5em;
    text-align:  right;

    .order-content & {font-size: .9em;}
}

.permission-disclaimer-wrapper {
    padding: 1em 1.5em;
    background-color: #f6f6f6;
    margin-bottom: 1em;
    border-radius: 1rem;

    p {
        line-height: 1.4;
    }
}

.order-complete {
    .with-checkbox {
        padding-top:    0;
        padding-bottom: 0;

        &:last-of-type {
            padding-bottom: 1rem;
        }
    }

    .ordertake-submit {
        padding-top: 2em;
    }

    .permissions-disclaimer {
        max-width: 36em;

        p {
            font-size:      .7em;
            line-height:    1.2;
            letter-spacing: var(--letter-spacing-small-always);
            color:          rgba($hiper-blue, .75);
        }
    }
}

.page-order-receipt,
.page-sbbu,
.page-order-receipt-selfcare-step {
    .content:not(.new-receipt-flow) .hide-on-old,
    .content.new-receipt-flow .hide-on-new {
        display: none;
    }

    .content.new-receipt-flow {
        padding-top:   1em;
        padding-left:  1em;
        padding-right: 1em;
        max-width:     em(650px);

        h1 {
            @include        font-weight-variable(600);
            display:        block;
            padding:        0 0 .25rem;
            font-size:      1.8em;
            line-height:    1.2;
            font-family:    $font-montserrat;
            text-align:     center;
            text-transform: none;
            letter-spacing: normal;
        }

        @media (max-width: $phone) {
            padding-left:  .5em;
            padding-right: .5em;

            h1 {font-size: 1.4em;}
        }
    }
}

@media (min-width: 961px) {
    .page-order-summary .order-content {
        .summary-table,
        .summary-table-expandable,
        .summary-vat {display: none;}
    }
}

@media (max-width: 960px) {
    .page-order-summary .order-summary {
        .summary-table,
        .summary-table-expandable,
        .summary-vat {display: none;}
    }

    .page-order-payment,
    .page-order-receipt {
        .order-wrapper .order-summary .accordion {
            border-bottom: none !important;
        }
    }

    .page-order-payment .order-wrapper .order-content h1 + p {
        font-size: .8em;
    }
}

.page-order-receipt,
.page-order-receipt-selfcare-step {
    .receipt-selfcare {
        border-radius:    1em;
        background-color: $hiper-lightergray;
        box-shadow:       inset 0 0 0 2px $hiper-red;

        & > h2,
        & > p {text-align:  center;}

        & > h2 {
            padding-bottom: .5rem;
            font-size:      1.6em;
        }

        & > p {
            max-width:   30em;
            line-height: 1.4;
        }

        .flex {
            justify-content: center;
            align-items:     center;
            gap:             .7em .5em;

            @media (max-width: $phone) {flex-direction: column;}
        }

        .image {
            display:         flex;
            flex-direction:  column;
            justify-content: center;
            align-items:     center;
            max-width:       12em;

            img {margin-top: -.5em;}

            .button {
                font-size: 1.2em;
            }
        }

        ul {
            & > p,
            li {padding-bottom: 0;}

            li {
                position:     relative;
                padding-left: .75em;

                &::before {
                    @include  font-weight-variable(900);
                    content:  '\2022';
                    position: absolute;
                    left:     0;
                    color:    $hiper-red;
                }
            }
        }

        .button {cursor: default;}
    }

    .new-receipt-flow .receipt-selfcare {
        padding:       0;
        border-radius: 0;
        background:    transparent;
        box-shadow:    none;

        h2 {@include font-weight-variable(400);}

        h2, h2 + p {
            padding: 0 0 1rem;
        }

        .receipt-selfcare-steps {
            width:  fit-content;
            margin: 0 auto;
        }

        h3 {
            @include             font-weight-variable(600);
            display:             grid;
            grid-template-areas: 'number text';
            align-items:         center;
            padding:             0 0 .75em;
            font-family:         $font-montserrat;
            font-size:           1.1em;
            text-align:          left;
            text-transform:      none;
            line-height:         1.2;
            letter-spacing:      normal;

            .text {
                display:      block;
                grid-area:    text;
                justify-self: start;
            }

            .number {
                display:          block;
                grid-area:        number;
                justify-self:     center;
                width:            1.5em;
                height:           1.5em;
                border-radius:    50%;
                background-color: $hiper-red;
                color:            #ffffff;
                line-height:      1.45;
                text-align:       center;
            }
        }
    }
}

.page-order-receipt {
    .receipt-sbbu,
    .receipt-selfcare {margin-bottom: 2em;}

    .receipt-sbbu + h1,
    .receipt-selfcare + h1 {padding-bottom: .5rem;}

    .receipt-selfcare-heading,
    .receipt-sbbu .receipt-sbbu-step,
    .receipt-sbbu .number {
        display: flex;
    }

    .receipt-selfcare-heading,
    .receipt-sbbu .receipt-sbbu-step {
        justify-content: flex-start;
    }

    .receipt-selfcare-heading,
    .receipt-sbbu .number {
        align-items: center;
    }

    .receipt-selfcare-heading {
        gap:            .25em;
        padding-bottom: .5rem;
        font-size:      2em;
    }

    .receipt-sbbu {
        .receipt-sbbu-step {
            $border-radius: 1em;

            align-items:    stretch;
            margin-bottom:  1em;
            border-radius:  $border-radius;
            box-shadow:     inset 0 0 0 2px $hiper-red;

            .number {
                display:          flex;
                justify-content:  center;
                align-items:      center;
                background-color: $hiper-red;
                border-radius:    $border-radius 0 0 $border-radius;
                color:            #ffffff;

                span {
                    @include   font-weight-variable(600);
                    font-size: 2em;
                }
            }

            .text {flex-grow: 2;}

            h3 {
                padding:     0 0 .1em;
                font-size:   1.2em;
                line-height: 1.2;
            }

            p {line-height: 1.4;}
        }
    }

    .receipt-selfcare {
        max-width: 34em;
    }

    .content.new-receipt-flow {
        grid-template-columns: auto;
        grid-template-areas:   'header'
                               'content'
                               'summary';
        padding-bottom:        2em;

        .order-header,
        .order-content,
        .order-summary {padding: 0;}

        .order-content {
            display:         flex;
            flex-direction:  column;
            justify-content: center;
            align-items:     center;
            padding-bottom:  1em;
            border:          0;
        }

        .receipt-sbbu {
            max-width: 32em;
            width:     100%;

            h1 + p {text-align: center;}

            .receipt-sbbu-step:first-of-type {
                margin-bottom: .5em;
            }
        }

        .receipt-selfcare {padding-top: 1em;}

        .order-summary {
            .accordion {
                .title-container {
                    display:         flex;
                    flex-direction:  row-reverse;
                    justify-content: space-between;
                    align-items:     center;
                    width:           100%;
                }

                h2 {
                    @include        font-weight-variable(400);
                    font-family:    $font-montserrat;
                    font-size:      1em;
                    text-transform: none;
                    letter-spacing: normal;
                }
            }

            .accordion-content {padding: 0 0 .5em;}

            hr {
                margin:           .5em 0;
                background-color: $hiper-gray;
            }
        }
    }
}

.page-sbbu .content.new-receipt-flow {
    h1 + p {
        padding-bottom: .5em;

        @media (max-width: $phone) {font-size: .9em;}
    }

    h1 + p + .button {
        display:   block;
        margin:    0 auto 1.5em;
        width:     fit-content;
        padding:   .25em .75em 0;
        font-size: 1.2em;
    }
}

.page-order-receipt-selfcare-step {
    h1 {padding-bottom: 1rem;}

    .receipt-selfcare {
        margin: 0 auto 1em;
        width:  fit-content;
    }

    .new-receipt-flow .receipt-selfcare h1 {
        padding: 0;
    }
}

@media (min-width: 521px) {
    .page-order-receipt .receipt-sbbu {
        max-width: 32em;

        .receipt-sbbu-step {
            .number {flex:    0 0 4em;}
            .text   {padding: 1em;}
        }
    }

    .page-order-receipt,
    .page-order-receipt-selfcare-step {
        .new-receipt-flow .receipt-selfcare {
            h2     {font-size: 1.4em;}
            h2 + p {font-size: .8em;}

            & > p:nth-of-type(2) {padding: .5em 0 .75em;}

            h3 {
                grid-template-columns: calc(1.6em * 1.5) auto;
                gap:                   .75em;

                .number {font-size: 1.6em;}
            }
        }
    }

    .page-order-receipt               .receipt-selfcare {padding: 1em;}
    .page-order-receipt-selfcare-step .receipt-selfcare {padding: 1em 1.5em;}

    .page-order-receipt,
    .page-sbbu,
    .page-order-receipt-selfcare-step {
        .progress-bar                   {margin-bottom: 2em;}
        .new-receipt-flow .progress-bar {margin-bottom: 3em;}
    }
}

@media (max-width: $phone) {
    .page-order-receipt {
        .receipt-sbbu .receipt-sbbu-step {
            .number {flex:    0 0 3em;}
            .text   {padding: .5em .75em;}
        }

        .new-receipt-flow .receipt-sbbu h1 + p {
            font-size: .9em;
        }
    }

    .page-order-receipt,
    .page-order-receipt-selfcare-step {
        .new-receipt-flow .receipt-selfcare {
            h2     {font-size: 1.2em;}
            h2 + p {font-size: .9em;}

            & > p:nth-of-type(2) {padding: 0 0 1em;}

            h3 {
                grid-template-columns: calc(1.5em * 1.5) auto;
                gap:                   .5em;

                .number {font-size: 1.5em;}
            }
        }
    }

    .page-order-receipt               .receipt-selfcare,
    .page-order-receipt-selfcare-step .receipt-selfcare {padding: 1em .5em;}

    .page-order-receipt,
    .page-sbbu,
    .page-order-receipt-selfcare-step {
        .progress-bar {padding: 0 0 1em;}
    }
}

@media (max-width: $phone) and (min-width: 351px) {
    .order-complete .checkbox-label,
    .order-complete .checkbox-label + a {
        font-size: 1.1em;
    }
}

.no-js {
    .page-selfcare-card-add,
    .page-order-payment {
        .please-wait {
            display: none;
        }
    }
}

.js {
    .page-selfcare-card-add,
    .page-order-payment {
        .ordertake-submit .button {
            display: none;
        }

        .please-wait {
            text-align: center;
        }
    }
}

.products-show-call-me {
    max-width:                 100%;
    max-height:                auto;
    position:                  fixed;
    right:                     0;
    bottom:                    0;
    left:                      0;
    z-index:                   $z-index-call-me;
    margin:                    auto;
    padding:                   em(30px) em(10px) em(40px);
    background:                $hiper-seablue;
    text-align:                center;
    transform-origin:          bottom;
    animation-name:            productsFiberBlurbAnimation;
    animation-duration:        .6s;
    animation-timing-function: $ease-in-out-back;
    animation-delay:           2s;
    animation-iteration-count: 1;
    animation-fill-mode:       backwards;

    &.hidden {
        display: none;
    }

    .heading {
        padding-bottom: em(10px);
        line-height:    1;
    }

    .grid {
        max-width: 700px;
        margin:    auto;
    }

    .item {
        flex:    1 1 auto;
        padding: 0 em(5px);
    }

    .form__sending-overlay {
        background-color: rgba($hiper-seablue, .9);
    }

    .form__not-sent-overlay,
    .form__sent-overlay {
        background-color: $hiper-seablue;
        color:            white;

        p, h3 {
            margin:  0;
            padding: 0;
        }
    }

    @media (max-width: 920px) {
        .wrap-me {
            display: block;
        }

        .item {
            padding-bottom: em(5px);
        }
    }

    @media (max-width: $phone) {
        padding: em(10px);

        .heading {
            font-size: em(24px);
        }
    }
}

@keyframes productsFiberBlurbAnimation {
    0% {
        max-height: 0;
        transform:  scaleX(0) scaleY(.5);
        opacity:    0;
    }

    100% {
        max-height: 20em;
        transform:  scaleX(1) scaleY(1);
        opacity:    1;
    }
}

.contact__grid-item {
    flex: 1 1 50%;
}

.contact__grid {
    margin-bottom: 1em;
    gap:           1em;

    &.error {
        position: relative;

        .contact__grid-item {
            position: relative;
            z-index:  2;
        }

        &:after {
            display:       block;
            position:      absolute;
            top:           0;
            right:         0;
            bottom:        em(10px);
            left:          0;
            z-index:       1;
            border-radius: em(5px);
            box-shadow:    0 0 0 3px $hiper-gray, 0 0 0 5px $hiper-red;
            content:       '';
        }
    }

    @media (max-width: 870px) {
        &.grid {
            display: flex;
        }
    }
}

.page-order-contact {
    .contact-info__address,
    .contact-info__personal-info {
        padding-bottom: 1em;
    }

    .contact__invoice {
        @include hidden;
        max-height:     0;
        padding-bottom: 0;
        transition:     .2s all $ease-in-out-cubic;
        opacity:        0;

        &.visible {
            @include visible;
            max-height: 100em;
            padding:    1em 0;
            transition: .2s all $ease-in-out-cubic;
            opacity:    1;
        }

        .address-widget-wrap {
            min-width: auto;
            height:    auto;

            .address-widget {
                position:         static;
                max-width:        none;
                padding:          0;
                background-color: transparent;
                box-shadow:       none;
                border-radius:    .5em;

                #address-field,
                .manual-address .street input,
                .manual-address .manual-address__row:last-child input {
                    border-radius: .5em;
                }

                #address-field {
                    width:  100%;
                    border: 1px solid $hiper-darkgray;
                }

                .dawa-address__propositions-wrap {
                    margin-top:       0;
                    background-color: #ffffff;
                    border-radius:    .25em .25em .5em .5em;

                    li {
                        color: rgba(#000000, .9);

                        &:nth-child(odd) {
                            background-color: rgba(#000000, .05);
                        }

                        &:hover,
                        &:focus {
                            background-color: $hiper-red;
                            color:            #ffffff;
                        }
                    }

                    .dawa-address__bottom-bar {
                        padding-top: 0;

                        .dawa-address__address-not-found {
                            width:            100%;
                            background-color: $hiper-seablue;
                            color:            #ffffff;
                            border-radius:    0 0 .5em .5em;

                            &:hover,
                            &:focus {
                                background-color: darken($hiper-seablue, 10%);
                            }
                        }
                    }
                }

                #manual-address {
                    padding: 0;

                    .manual-address__row {
                        padding-right: 0;
                    }
                }
            }
        }
    }

    .address-widget__left {
        max-width: 100%;

        @media (max-width: 520px) {
            padding-bottom: 0;
        }
    }

    .address-widget__right {
        display: none;
    }

    .manual-address {
        padding-right: .635em;
    }
}

.show-manual-address .page-order-contact .address-widget,
.page-order-contact .address-widget {
    margin-bottom:    1.2em;
    background-color: transparent;

    .icon-arrow {
        color: $color-manatee;
    }
}

.error_with_call_me {
    padding-top: em(10px);
}

#creditcard-iframe {
    @media (min-width: 497px) {
        min-height: 550px;
    }

    @media (max-width: 496px) {
        min-height: 530px;
    }
}
