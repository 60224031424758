.carousel {
    position:                       relative;
    --carousel-width:               100vw;
    --carousel-height:              #{em(400px)};
    --carousel-item-letter-spacing: .02rem;
    --carousel-shout-font-size:     #{em(60px)};

    --line-display:                 inline;

    @media (min-width: 901px) {
        --line-display: block;
    }

    @media (max-width: 900px) {
        --carousel-height:              #{em(350px)};
        --carousel-item-letter-spacing: -.05rem;
        --carousel-shout-font-size:     #{em(50px)};;
    }

    @media (max-width: 400px) {
        --carousel-height:          #{em(300px)};
        --carousel-shout-font-size: #{em(40px)};
    }
}

.carousel-viewport {
    display:        flex;
    width:          100%;
    flex-direction: column;
    align-items:    center;
    margin:         0 auto;
}

.carousel-frame {
    width:  100vw;
    height: #{em(400px)};
    height: var(--carousel-height);
}

.carousel-scroll {
    display:                    flex;
    width:                      100%;
    height:                     100%;
    align-items:                center;
    margin:                     0;
    padding:                    0;
    overflow-x:                 auto;
    overflow-y:                 hidden;
    -ms-overflow-style:         none;
    list-style:                 none;
    -webkit-overflow-scrolling: touch;
    scrollbar-width:            none;

    &::-webkit-scrollbar {
        display: none;
    }
}

.carousel-item-outer {
    width:   100%;
    height:  100%;
    margin:  0;
    padding: 0;
}

.carousel-item {
    display:         flex;
    width:           100vw;
    height:          100%;
    align-items:     center;
    justify-content: center;
    color:           $hiper-red;

    line-height:     1;
    letter-spacing:  var(--carousel-item-letter-spacing);

    * {
        color:       inherit;
        line-height: inherit;
    }

    p {
        margin:  0;
        padding: 0;
    }

    .carousel-item__button {
        text-align: right;

        .button {
            color: white;

            .icon {
                display:     inline-block;
                width:       .55em;
                height:      .55em;
                line-height: .55em;
            }
        }
    }

    .line {
        display: var(--line-display);
    }

    .carousel-item__shout {
        font-size:      #{em(40px)};
        font-size:      var(--carousel-shout-font-size);
        font-weight:    500;
        text-transform: uppercase;

        .bluegray {
            color: $hiper-bluegray;
        }
    }

    //.carousel-item__shout-postscipt {
    //    font-size: em(20px);
    //}
}

// region: scroll snap

@supports (scroll-snap-align: start) {
    // Modern scroll snap points
    .carousel-scroll {
        scroll-snap-type: x mandatory;
    }

    .carousel-item-outer {
        scroll-snap-align: center;
    }
}

@supports not (scroll-snap-align: start) {
    // Old scroll snap points spec
    .carousel-scroll {
        -webkit-scroll-snap-type:        mandatory;
        scroll-snap-type:                mandatory;
        -webkit-scroll-snap-destination: 0% center;
        scroll-snap-destination:         0% center;
        -webkit-scroll-snap-points-x:    repeat(100%);
        scroll-snap-points-x:            repeat(100%);
    }

    .carousel-item-outer {
        scroll-snap-coordinate: 0 0;
    }
}

// endregion: scroll snap

// region: carousel controls

.carousel-controls {
    display:         flex;
    position:        absolute;
    bottom:          0;
    left:            0;
    justify-content: center;
    margin:          0;
    padding:         0 em(5px);
    list-style:      none;
}

.carousel-control {
    padding: 0;

    button {
        display:        flex;
        flex-direction: column;
        padding:        0;
        overflow:       hidden;
        border:         none;
        background:     none;
        color:          #444444;
        cursor:         pointer;

        .icon {
            max-width:  em(30px);
            max-height: em(30px);
            margin:     em(12px) em(15px) em(17px);
            font-size:  em(18px);
            color:      inherit;
            transition: .15s all $ease-in-out-quad;
        }

        .play,
        .circle {
            opacity: 0;
        }

        &[aria-pressed='true'] {
            .play,
            .circle {
                opacity: 1;
            }

            .pause {
                opacity: 0;
            }
        }

        &:hover {
            color: $hiper-red;

            .icon {
                transition-duration: .10s;
            }
        }

        &:active {
            color: $hiper-darkred;
        }

        &:focus {
            box-shadow: 0 0 0 3px $color-focus;
        }
    }
}

// endregion: carousel controls

// region: campaign-specific stuff, todo: move it out

.carousel-item__giga-249 {
    background: #d4eada;
}

.carousel-item__support {
    background: #b4dcea;
}

.carousel-item__win-tv {
    background:                 #b4dcea;

    --carousel-shout-font-size: #{em(40px)};

    .tv {
        display:     flex;
        align-items: center;
    }

    .tv-image {
        background-image: imgurl('carousel/tv.png');
        background-size:  cover;
        min-width:        15em;
        min-height:       15em;
        margin-right:     2em;
    }

    .tv-text p {
        font-size: 1.4em;
        color:     $hiper-blue;
    }

    .conditions {
        color:     $hiper-darkergray;
        max-width: 55em;
        font-size: .8em;
    }

    @media (max-width: 780px) {
        --carousel-shout-font-size: #{em(35px)};

        .tv-image {
            min-width:  10em;
            min-height: 10em;
        }
        .conditions {
            padding-top: 1em;
        }
    }

    @media (max-width: 550px) {
        --carousel-shout-font-size: #{em(28px)};

        .tv-image {
            min-width:    6em;
            min-height:   6em;
            margin-right: 1em;
        }
        .conditions {
            font-size: .9em;
        }
    }

    @media (max-width: 370px) {
        .tv-image {
            display: none;
        }
    }
}

.carousel-item__stream-big {
    background-color:           black;
    background-image:           imgurl('carousel/one-400.jpg');
    background-repeat:          no-repeat;
    background-position:        center;
    background-size:            cover;
    font-style:                 italic;
    color:                      white;

    --carousel-shout-font-size: #{em(140px)};

    @media (min-width: $desktop) {
        background-image: imgurl('carousel/one-600.jpg');
    }

    @media (min-width: 1200px) {
        background-image: imgurl('carousel/one-800.jpg');
    }

    @media (max-width: 900px) {
        --carousel-shout-font-size: #{em(100px)};
    }

    @media (max-width: 400px) {
        --carousel-shout-font-size: #{em(70px)};
    }
}

.carousel-item__stream-big-uke {
    background-color:           black;
    background-image:           imgurl('carousel/stream-big-uke-400.jpg');
    background-repeat:          no-repeat;
    background-position:        center;
    background-size:            cover;
    font-style:                 italic;
    color:                      white;

    --carousel-shout-font-size: #{em(140px)};

    @media (min-width: $desktop) {
        background-image: imgurl('carousel/stream-big-uke-600.jpg');
    }

    @media (min-width: 1200px) {
        background-image: imgurl('carousel/stream-big-uke-800.jpg');
    }

    @media (max-width: 900px) {
        --carousel-shout-font-size: #{em(100px)};
    }

    @media (max-width: 400px) {
        --carousel-shout-font-size: #{em(70px)};
    }
}

////////////////////////////

.page-home .carousel {
    --carousel-height: #{em(300px)};

    @media (min-width: 1500px) {
        --carousel-height: #{em(400px)};
    }

    @media (max-width: 900px) {
        --carousel-height: #{em(250px)};
    }

    @media (max-width: 400px) {
        //--carousel-height: #{em(200px)};
        --carousel-height: #{em(250px)};
    }
}

.carousel-item__new_homepage {
    background-color:    black;
    background-image:    imgurl('carousel/stream-big-uke-400.jpg');
    background-repeat:   no-repeat;
    background-position: center;
    background-size:     cover;
    color:               white;
    text-shadow:         0 .15em .5em rgba(0, 0, 0, .75);

    .carousel-item__shout {
        font-style:  italic;
        text-align:  left;
        text-shadow: 0 .1em .25em rgba(190, 0, 90, .75);
    }

    @media (min-width: 901px) {
        background-image: imgurl('carousel/stream-big-uke-600.jpg');

        .carousel-item__shout {
            min-width: 10em;
        }
    }

    @media (min-width: 1200px) {
        background-image: imgurl('carousel/stream-big-uke-800.jpg');

        .carousel-item__shout {
            min-width: 10em;
        }
    }
}

// endregion: campaign-specific stuff, move it out
