// scss-lint:disable all
@media print {
    html {
        font-size: 12pt;
    }

    *, *:before, *:after {
        background:  transparent !important;
        color:       black       !important;
        text-shadow: none        !important;
        filter:      none        !important;
        -ms-filter:  none        !important;
        box-shadow:  none        !important;
    }

    a, a:visited {
        text-decoration: underline;
    }

    a[href]:after {
        content: " (" attr(href) ")";
    }

    abbr[title]:after {
        content: " (" attr(title) ")";
    }

    a[href^="tel:"]:after,
    a[href^="javascript:"]:after {
        content: "";
    }

    pre, blockquote {
        border: 1px solid #999; page-break-inside: avoid;
    }

    thead {
        display: table-header-group;
    } /* h5bp.com/t */

    tr, img {
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
    }

    @page {
        margin: 0.5cm;
    }

    p, h2, h3, h4 {
        orphans: 3; widows: 3;
    }

    h2, h3, h4 {
        page-break-after: avoid;
    }
}
