[class^='page-help'] {
    .blurb {padding: 0;}
}

.page-help .header p {
    padding-top: 1em;
    line-height: 1.4;
    hyphens:     manual;
}

[class^='page-help-'] .header {
    padding:          0;
    width:            100%;
    background-color: $hiper-darkteal;

    .breadcrumb-navigation,
    h1, .summary {
        color: #ffffff;
    }

    // region: Breadcrumb Navigation
    .breadcrumb-navigation {
        display:        block;
        margin:         0;
        padding-bottom: .25em;
        font-family:    $font-cervo;
        text-transform: uppercase;
        font-size:      1.2em;
        font-weight:    300;
        letter-spacing: .025em;

        a, .breadcrumb-arrow {
            display:     inline-block;
            line-height: 1.2;
        }

        a {
            border-bottom-color: rgba(#ffffff, .5);

            &:last-child {display: none;}

            &:hover {
                color:               #ffffff;
                border-bottom-color: rgba(#ffffff, 1);
            }
        }

        .breadcrumb-arrow {
            position: relative;
            width:    .5em;
            height:   .5em;

            &::before, &::after {
                content:          '';
                position:         absolute;
                top:              0;
                left:             0;
                width:            8px;
                height:           1px;
                background-color: #ffffff;
            }

            &::before {transform: rotate(45deg);}
            &::after  {transform: translate(0, 5px) rotate(-45deg);}
        }
    }
    // endregion: Breadcrumb Navigation

    h1 {
        @include        font-weight-variable(600);
        font-family:    $font-montserrat;
        line-height:    1.2;
        text-transform: none;
        letter-spacing: normal;
        hyphens:        manual;
    }

    .summary {
        padding:     0;
        max-width:   28em;
        line-height: 1.4;
        hyphens:     manual;
    }
}

.help-search {
    margin:    1em auto 2em;
    max-width: 40em;
    width:     100%;

    .page-help-category & {
        margin-bottom: 0;
    }
}

.help-content {
    display:         flex;
    flex-direction:  column;
    justify-content: center;
    align-items:     center;

    // region: typography general
    h1, h2, h3, h4, h5, h6 {
        font-family:    $font-montserrat;
        @include        font-weight-variable(600);
        line-height:    1.2;
        text-align:     left;
        text-transform: none;
        letter-spacing: normal;
        hyphens:        manual;
    }

    h1         {font-size: 2em;}
    h2         {font-size: 1.4em;}
    h3         {font-size: 1.2em;}
    h4, h5, h6 {font-size: 1.1em;}

    h5 {color: lighten($hiper-blue, 20%);}
    h6 {color: lighten($hiper-blue, 30%);}
    // endregion: typography general
}

.help-menu {
    margin-bottom: 4em;

    &:last-child {margin-bottom: 0;}

    .item {
        margin:  0;
        padding: 0;
        width:   100%;

        a {
            width:            100%;
            height:           100%;
            background-color: #ffffff;
            border:           none;
            color:            $hiper-blue;
            line-height:      1.2;
        }
    }

    &.help-menu--category,
    &.help-menu--most-viewed {
        display:       grid;
        justify-items: center;
        align-items:   stretch;

        .item a {text-align: center;}
    }

    &.help-menu--category {
        max-width: 44em;

        .item {
            // "hard coded" orders
            &:nth-child(1) {order: 1;} // drift
            &:nth-child(2) {order: 5;} // bestilling
            &:nth-child(3) {order: 2;} // internet
            &:nth-child(4) {order: 3;} // abonnement
            &:nth-child(5) {order: 6;} // regning
            &:nth-child(6) {order: 4;} // udstyr

            h2, h3, a, .summary {
                text-align: center;
                color:      $hiper-blue;
            }

            a {
                display:         flex;
                flex-direction:  column;
                justify-content: flex-start;
                align-items:     center;
                padding:         1em .5em .75em;
                border-radius:   1em;
                box-shadow:      var(--box-shadow);
                transition:      box-shadow .15s $ease-in-out-cubic,
                                 color .15s $ease-in-out-cubic;

                .icon, h2 {transition: color .15s $ease-in-out-cubic;}

                .icon {
                    margin-bottom: .5rem;
                    width:         1em;
                    height:        1em;
                    color:         $hiper-blue;
                    fill:          #ffffff;
                }

                h2 {
                    padding:     0;
                    line-height: 1;
                    font-weight: 300;
                }

                .summary {
                    color:       rgba($hiper-blue, .8);
                    font-size:   .8em;
                    line-height: 1.4;
                }

                &:hover {
                    box-shadow: var(--box-shadow-hover);
                    color:      $hiper-red;

                    .icon, h2 {color: $hiper-red;}
                }
            }
        }
    }

    &.help-menu--article,
    &.help-menu--most-viewed,
    &.help-menu--related {
        .item a {display: block;}
    }

    &.help-menu--article {
        width: 100%;

        .item a {
            padding:       .75em .5em;
            border-bottom: 1px solid $hiper-gray;
            transition:    color .15s $ease-in-out-cubic;

            &:hover {color: $hiper-red;}
        }

        .item:first-child a {border-top: 1px solid $hiper-gray;}

        @media (min-width: 769px)   {max-width: 28em;}
        @media (max-width: $tablet) {max-width: 24em;}
    }

    &.help-menu--most-viewed {
        max-width: 44em;
    }

    &.help-menu--related {
        padding-top: .5em;

        .item {
            display:         flex;
            justify-content: flex-start;
            align-items:     flex-start;
            gap:             .5em;
            padding:         .5em 0;
            border-bottom:   1px solid $hiper-gray;
            color:           $hiper-blue;
            transition:      background-color .2s $ease-in-out-cubic;

            &:last-child {border-bottom: none;}

            &:hover {
                color:               rgba($hiper-blue, .75);
                border-bottom-color: $hiper-gray;

                .icon {
                    fill:      $hiper-red;
                    transform: translateX(.2em);
                }
            }

            .icon {
                flex-shrink: 0;
                display:     block;
                width:       1em;
                height:      1em;
                color:       transparent;
                fill:        $hiper-blue;
                transform:   translateX(0);
                transition:  fill .2s $ease-in-out-cubic,
                             transform .2s $ease-in-out-cubic;

                svg {
                    transform: scale(1.5);
                }
            }

            .text {font-size: .9em;}
        }
    }
}

.help-article {
    display:     grid;
    align-items: start;

    h1, h2, h3, h4, h5, h6 {
        justify-self: start;

        &.center {
            justify-self: center;
            text-align:   center;
        }

        b, strong {
            @include font-weight-variable(800);
        }
    }
}

.help-article-content {
    grid-area:       article;
    display:         flex;
    flex-direction:  column;
    justify-content: flex-start;
    align-items:     center;
    padding:         2em 0;

    &:first-child      {padding-top:   0;}
    &:not(:last-child) {margin-bottom: 1em;}

    // region: typography
    h2, h3, h4, h5, h6 {
        padding-top:    0;
        padding-bottom: .7rem;
    }

    .accordion h2,
    .accordion h3,
    .accordion h4 {padding-bottom: 0;}

    .accordion-content :first-child {
        h2:first-child,
        h3:first-child,
        h4:first-child {padding-top: .2em;}
    }

    p, li {margin: 0;}

    // Happy reading on large devices
    @media (min-width: 1024px) {
        .prices-table th, .prices-table td,
        p, li,
        .accordion .title--montserrat {
            font-size: .9rem;
        }

        p, li {line-height: 1.8;}
    }

    @media (min-width: 1401px) {
        p, li {line-height: 2;}
    }

    .paragraph {
        width: 100%;

        h1, h2, h3, h4, h5, h6 {
            padding-top: 1rem;

            &:first-child {padding-top: 0;}
        }
    }

    .paragraph-image {
        &:not(:first-child) {
            margin-top: .95rem;
        }

        &:not(:last-child) {
            margin-bottom: 2rem;
        }

        &.paragraph-image--connect:not(:last-child) {
            margin-bottom: .7rem;
        }
    }

    .button-container {
        display:         flex;
        flex-direction:  column;
        justify-content: center;
        align-items:     flex-start;
        gap:             .5em;
        padding-bottom:  .7em;
        width:           100%;

        &.pb-2 {padding-bottom: 1.4em;}

        &:last-child {padding-bottom: 0;}

        .normal-fw {
            @include font-weight-variable(400);
        }

        .icon-arrow-down {
            transform: rotate(90deg);
        }

        .button {
            justify-content: space-between;
            width:           100%;
            box-shadow:      var(--box-shadow-small);

            &:hover {
                box-shadow: var(--box-shadow-small-hover);
            }
        }
    }

    .list {
        counter-reset:     grid;
        display:           table;
        break-inside:      avoid-column;
        page-break-inside: avoid;
        padding-bottom:    .7em;

        &:last-child          {padding-bottom: 0;}
        p, h2, h3, h4, h5, h6 {text-align:     left;}
        p:not(:last-child)    {padding-bottom: .35em;}

        h2, h3, h4, h5, h6 {
            padding-top:    .35rem;
            padding-bottom: .2rem;

            &:first-child {padding-top: 0;}
        }

        li {
            position:       relative;
            padding-left:   1.6em;
            padding-bottom: .35em;

            &::before {
                position: absolute;
                left:     0;
                color:    $hiper-red;
            }

            &:last-child {padding-bottom: 0;}

            &.h2::before, &.h3::before, &.h4::before, &.h5::before, &.h6::before {
                line-height: 1.2;
            }

            &.h2::before {
                font-size: 1.4em;
            }

            &.h3::before {
                font-size: 1.2em;
            }

            &.h4::before, &.h5::before, &.h6::before {
                font-size: 1.1em;
            }
        }

        &.list--number li::before {
            @include           font-weight-variable(600);
            counter-increment: grid;
            content:           counter(grid) '.';
        }

        &.list--bullet li::before {
            @include font-weight-variable(900);
            content: '\2022';
        }

        &.list--image {
            display:         flex;
            flex-direction:  column;
            justify-content: flex-start;
            align-items:     center;

            li {
                width:          100%;
                padding-bottom: .7em;

                &:last-child {
                    padding-bottom: 0;

                    p:last-child   {padding-bottom: 0;}
                    img:last-child {margin-bottom:  0;}
                }
            }

            img {
                margin-bottom: 1.4em;
            }
        }

        .list--number li::before {
            @include font-weight-variable(500);
            content: counter(grid, lower-alpha) '.';
        }

        .list--bullet li::before {
            @include font-weight-variable(900);
        }

        &.list--bullet .list--bullet li::before {
            @include font-weight-variable(400);
            content: '\26AC';
        }

        &.list--compact li {padding-bottom: 0;}

        li.warning {
            list-style-type:   none;
            counter-increment: grid -1;

            &::before {
                @include          font-weight-variable(600);
                content:          '!';
                top:              .2em;
                left:             -.4em;
                width:            1.5em;
                height:           1.5em;
                border-radius:    50%;
                background-color: $hiper-red;
                color:            #ffffff;
                text-align:       center;
                line-height:      1.6;
            }
        }
    }

    .icon-inline {
        position: relative;
        display:  inline-block;
        width:    1.2em;
        height:   1em;
        color:    transparent;
        fill:     $hiper-blue;

        svg {
            position:  absolute;
            top:       50%;
            left:      50%;
            display:   block;
            width:     1.5em;
            height:    1.5em;
            transform: translate(-50%, -45%);
        }
    }

    code {
        font-family:    $font-mono;
        white-space:    pre-wrap;
        letter-spacing: .1em;
        word-break:     break-word;

        .nowrap {white-space: nowrap;}

        &.inline-code {
            display:          inline;
            border-radius:    .25em;
            padding:          .1em .25em;
            background-color: $hiper-lightgray;
            color:            $hiper-blue;
        }
    }

    p.center {
        text-align: center;
    }

    .prices-table {
        max-width: none;

        th, td {vertical-align: baseline;}

        td:last-child {width: 45%;}

        td:not(.prices-table-coax300):last-child:not(:only-child) {
            white-space: wrap;
        }
    }

    .comparing-chart {
        $shadowSize:         1em;
        $shadowSizeNegative: -1em;
        $shadowOpacity:      .3;

        position:            relative;
        max-width:           100%;
        overflow:            hidden;

        &::after {
            content:        '';
            display:        block;
            position:       absolute;
            top:            0;
            left:           0;
            width:          100%;
            height:         100%;
            box-shadow:     inset $shadowSizeNegative 0 0 $shadowSizeNegative rgba(0, 0, 0, $shadowOpacity),
                            inset $shadowSize 0 0 $shadowSizeNegative rgba(0, 0, 0, $shadowOpacity);
            transition:     box-shadow .1s $ease-in-out-cubic;
            pointer-events: none;
        }

        &.shadow-right::after {
            box-shadow: inset $shadowSizeNegative 0 $shadowSize $shadowSizeNegative rgba(0, 0, 0, $shadowOpacity),
                        inset $shadowSize 0 0 $shadowSizeNegative rgba(0, 0, 0, $shadowOpacity);
        }

        &.shadow-left::after {
            box-shadow: inset $shadowSizeNegative 0 0 $shadowSizeNegative rgba(0, 0, 0, $shadowOpacity),
                        inset $shadowSize 0 $shadowSize $shadowSizeNegative rgba(0, 0, 0, $shadowOpacity);
        }

        &.shadow-right.shadow-left::after {
            box-shadow: inset $shadowSizeNegative 0 $shadowSize $shadowSizeNegative rgba(0, 0, 0, $shadowOpacity),
                        inset $shadowSize 0 $shadowSize $shadowSizeNegative rgba(0, 0, 0, $shadowOpacity);
        }
    }

    .comparing-chart-table-container {
        display:    block;
        width:      100%;
        overflow-x: auto;
    }

    .comparing-chart-table {
        $border: 1px solid $hiper-gray;

        border-radius:   0;
        border-collapse: collapse;

        tr:first-child,
        tr:last-child {
            td, th {border-radius: 0;}
        }

        th, td {
            padding:        .5em;
            vertical-align: middle;
            border:         $border;

            p {line-height: 1.4;}
        }

        .icon {
            display:       block;
            margin-left:   auto;
            margin-right:  auto;
            width:         1.5em;
            height:        1.5em;
            margin-bottom: .35em;

            &:last-child {margin-bottom: 0;}

            &.icon-check {color: $hiper-green;}
            &.icon-cross {color: $hiper-red;}
        }

        thead {
            th:first-child {
                background-color: transparent;
                border:           none;
            }

            th:nth-child(1n+2) {
                background-color: $hiper-lightgray;
            }

            th {
                @include font-weight-variable(600);
                text-align: center;
            }
        }

        tbody {
            th {
                @include font-weight-variable(500);
                background-color: $hiper-lightgray;
                color:            $hiper-blue;
            }
        }
    }

    .comparing-chart--internet .comparing-chart-table {
        thead {
            $border-radius: .5em;

            th {border: none;}

            th:nth-child(2) {border-radius: $border-radius 0 0 0;}
            th:last-child   {border-radius: 0 $border-radius 0 0;}

            th:nth-child(2) {background-color: $hiper-fiber;}
            th:nth-child(3) {background-color: $hiper-mbb;}
            th:nth-child(4) {background-color: $hiper-coax;}
        }

        tbody {
            tr:first-child td {border-top: none;}
        }
    }

    .comparing-chart--ip-address .comparing-chart-table {
        th {
            background-color: $hiper-lightgray;
            color:            $hiper-blue;
        }
    }

    .text-box {
        margin-bottom:    1em;
        border-radius:    1em;
        box-shadow:       0 0 0 1px $hiper-gray;
        background-color: #ffffff;

        &:last-child {margin-bottom: 0;}

        &.text-box--match-height {align-self: stretch;}

        &.text-box--gray  {background-color: $hiper-lightgray;}
        &.text-box--teal  {background-color: $hiper-teal;}
        &.text-box--red   {background-color: $hiper-red;}
        &.text-box--green {background-color: $hiper-green;}

        &.text-box--teal,
        &.text-box--red,
        &.text-box--green {
            color:      #ffffff;
            box-shadow: none;

            .list li::before {color: #ffffff;}
        }

        img {
            display:      block;
            margin-left:  auto;
            margin-right: auto;
        }

        &.text-box--red a:not(.button) {
            border-bottom-color: rgba(#ffffff, .75);

            &:hover {
                border-bottom-color: rgba(#ffffff, 0);
                color:               rgba(#ffffff, .75);
            }
        }
    }

    .article > .text-box {margin-bottom: 0}

    // endregion: typography

    .id-anchor.id-anchor--help {
        transform: translateY(calc(-3.75em - 4em)); // Nav - Extra space
    }

    .address-widget-wrap {
        width: 100%;
    }

    @at-root .installation-procedure-container {
        display:         flex;
        flex-direction:  row;
        justify-content: center;
        align-items:     flex-start;
        flex-wrap:       wrap;
        gap:             1em 0;
        max-width:       38em;

        .installation-procedure {
            width:           6em;
            display:         flex;
            flex-direction:  column;
            justify-content: flex-start;
            align-items:     center;

            .icon, svg {
                width:  4em;
                height: 4em;
            }

            .icon {
                display:  block;
                position: relative;
                color:    $hiper-blue;
                fill:     #ffffff;

                svg {
                    position: relative;
                    z-index:  1;
                }

                &::before {
                    content:          '';
                    position:         absolute;
                    z-index:          0;
                    transform:        translate(-50%, -50%);
                    top:              50%;
                    left:             50%;
                    width:            6em;
                    height:           .5em;
                    background-color: darken($hiper-blue, 2);
                }
            }

            p {
                padding-top:    0.25em;
                font-family:    $font-cervo;
                font-weight:    300;
                text-transform: uppercase;
                text-align:     center;
                line-height:    1;
            }

            &.installation-procedure-start .icon::before {
                left:  100%;
                width: 2em;
            }

            &.installation-procedure-end .icon::before {
                left:  0%;
                width: 2em;
            }

            &.installation-procedure-current .icon {
                color: $hiper-red;
            }
        }
    }

    .big-steps {
        counter-reset: grid;

        li, p {line-height: 1.6;}

        & > li {
            position:      relative;
            padding:       1.5em 0 1.5em 3.75em;
            border-bottom: 1px solid $hiper-gray;

            &:last-child {border-bottom: none;}

            &::before {
                @include           font-weight-variable(600);
                counter-increment: grid;
                content:           counter(grid) '.';
                position:          absolute;
                top:               calc(-.35em / 6);
                left:              0;
                width:             1em;
                font-size:         3em;
                text-align:        right;
                color:             $hiper-red;
            }
        }
    }

    .choose-router-container {
        display: grid;
        gap:     1em;

        .choose-router,
        .image-container,
        .not-sure {
            display:         flex;
            justify-content: center;
            align-items:     center;
        }

        .choose-router,
        .not-sure{
            border:           none;
            border-radius:    .75em;
            background-color: $hiper-lightergray;
            box-shadow:       0 0 0 1px $hiper-gray;
            color:            $hiper-blue;
        }

        .choose-router {
            @include font-weight-variable(500);
            flex-direction: column;
            gap:            .5em;
            padding:        1em 1em .5em;

            .image-container {height: 100%;}

            .text {
                display:     block;
                line-height: 1.2;
                text-align:  center;
            }
        }

        .not-sure {
            grid-column-start: start;
            grid-column-end:   end;
            padding:           .5em;
        }
    }

    .contact-options {
        .grid--contact {
            margin-top:    0;
            margin-bottom: 1.5em;
        }

        .content {
            padding: 0;

            h3 {
                justify-self:   center;
                padding-bottom: 0;
            }
        }
    }

    // region: article columns
    /* Column image max-width in px
              [1]       : 1200 (Use 760 if image with text)
            [1] [2]     : 760 (666px for 100% on mobile/@media (max-width: 700px))
          [1] [2] [3]   : 666
        [1] [2] [3] [4] : 666
    */

    hr {
        max-width: none;
        width:     100%;
    }

    .article {
        display:               grid;
        grid-template-columns: 1fr;
        justify-items:         center;
        align-items:           start;
        width:                 100%;

        &:last-child {margin-bottom: 0;}

        img {
            border-radius: .5em;

            &.max-height {max-height:    50vh;}
            &.no-br      {border-radius: 0;}
            &.br1        {border-radius: 1em;}
            &.box-shadow {box-shadow:    var(--box-shadow);}
            &.mb0        {margin-bottom: 0;}
        }

        &.article--2 {
            gap: 1em;
        }
    }
    // endregion: article columns
}

.help-article-related {
    grid-area: sidebar;

    h3 {
        padding:   0;
        font-size: 1.4em;
    }
}

// Region: Mediaqueries

@media (min-width: 1161px) {
    .help-article {
        grid-template-areas:   '. article sidebar';
        grid-template-columns: 1.5fr 3fr 1.5fr;
        justify-items:         start;
        gap:                   2em;
        max-width:             80em;

        @media (max-width: 1620px) {
            grid-template-areas:   'article sidebar';
            grid-template-columns: 3fr 1.5fr;
            max-width:             60em;
        }
    }

    .help-article-related {
        position:     sticky;
        top:          calc(3.75em + 2em);
        padding-left: 2em;
        border-left:  1px solid $hiper-gray;
    }
}

@media (max-width: 1160px) {
    [class^='page-help-'] .header .content,
    .help-article {
        max-width: 40em;
    }

    .help-article {
        grid-template-areas:   "article"
                               "sidebar";
        grid-template-columns: 1fr;
        justify-items:         center;
    }
}

@media (min-width: 701px) {
    .help-search {
        padding: 1.5em 2.5em 2em;
    }

    .help-content {
        padding: 2em;
    }

    .help-menu {
        &.help-menu--category {
            grid-template-columns: 1fr 1fr 1fr;
            gap:                   1em;

            .item a {
                .icon {font-size: 4em;}

                h2 {
                    margin-bottom: .25rem;
                    font-size:     1.2em;
                }
            }
        }

        &.help-menu--most-viewed {
            grid-template-columns: 1fr 1fr;
            width:                 100%;
            gap:                   .5em;

            .item a {
                padding:       .75em .5em;
                border-radius: .5em;
                box-shadow:    var(--box-shadow);
                transition:    background-color .15s $ease-in-out-cubic,
                               box-shadow .15s $ease-in-out-cubic,
                               color .15s $ease-in-out-cubic;

                &:hover {
                    background-color: $hiper-red;
                    box-shadow:       var(--box-shadow-hover);
                    color:            #ffffff;
                }
            }
        }
    }

    .help-article-content {
        .accordion-container {
            min-width: 26em;
        }

        .comparing-chart--ip-address .comparing-chart-table {
            table-layout: fixed;
            width:        46em;
        }

        .text-box {
            padding: 1em;

            &.text-box--space-between {
                display:         flex;
                flex-direction:  column;
                justify-content: space-between;
            }
        }

        .choose-router-container {
            grid-template-columns: [start] 1fr 1fr 1fr [end];
        }

        hr {margin: 0 0 4em;}

        .article {
            margin-bottom: 4em;

            &.article--2 {
                grid-template-columns: 1fr 1fr;

                .paragraph {
                    h2, h3, h4, p, li {max-width: 24rem;}
                }

                .accordion-container {
                    min-width: 0;
                }
            }

            &.article--align-text {
                > div:nth-child(1),
                > div:nth-child(3) {justify-self: end;}
                > div:nth-child(2),
                > div:nth-child(4) {justify-self: start;}
            }

            img.small               {max-width:     22em;}
            &.article--connect      {margin-bottom: 2em;}
            &.article--x-height img {margin-top:    .65em;}
        }
    }
}

@media (max-width: 700px) {
    .help-search {
        padding:   .75em 1em 1.5em;
        max-width: calc(100% - 2em);

        .flex p {font-size: .9em;}
    }

    .help-content {
        padding-top:    2em;
        padding-bottom: 1em;

        @media (min-width: 521px) {
            padding-left:  1.5em;
            padding-right: 1.5em;
        }

        @media (max-width: $phone) {
            padding-left:   1em;
            padding-right:  1em;
            padding-bottom: 0;

            &.help-article {
                padding-left:  0;
                padding-right: 0;
            }

            .help-article-content,
            .help-article-related {
                padding-left:  1em;
                padding-right: 1em;
            }
        }

    }

    .help-menu {
        &.help-menu--category {
            grid-template-columns: 1fr 1fr;
            gap:                   .5em;

            .item a {
                .icon {font-size: 3em;}
                h2    {font-size: 1em;}
            }
        }

        &.help-menu--most-viewed {
            grid-template-columns: 1fr;

            .item a {
                padding:       .75em .5em;
                border-bottom: 1px solid $hiper-gray;
                transition:    color .15s $ease-in-out-cubic;

                &:hover {color: $hiper-red;}
            }

            .item:last-child a {border: 0;}
        }

        &.help-menu--related {
            width: 100%;
        }
    }

    .help-article-content {
        .comparing-chart--ip-address .comparing-chart-table {
            width: 36em;
        }

        .text-box {padding: 1em .75em;}

        .choose-router-container {
            grid-template-columns: [start] 1fr 1fr [end];
        }

        hr {margin: 0 0 2em;}

        .article {
            margin-bottom: 2em;

            img.small {
                max-width:  18em;
                max-height: 50vh;
            }

            &.article--connect {margin-bottom: 1em;}

            &.article--2 {
                grid-template-columns: 1fr;

                &.article--phone-reverse {
                    & > :nth-child(1) {grid-row: 2;}
                    & > :nth-child(2) {grid-row: 1;}
                }
            }
        }
    }
}

@media (min-width: 521px) {
    [class^='page-help-'] .header .content {
        h1 {font-size: 2em;}
    }

    .help-article-content {
        .hide-on-large-devices {display: none;}

        .choose-router-container {
            .choose-router {
                transition: transform .2s $ease-in-out-cubic;

                &:hover {transform: scale(1.1);}
            }

            .not-sure {
                transition: color .2s $ease-in-out-cubic;

                &:hover {color: rgba($hiper-blue, .75);}
            }
        }
    }
}

@media (max-width: $phone) {
    [class^='page-help-'] .header .content {
        padding-top:    .5em;
        padding-bottom: .5em;

        h1 {font-size: 1.6em;}
    }

    .help-article-content {
        .hide-on-small-devices {display: none;}

        .paragraph {
            h1 + h2,
            h2 + h3,
            h3 + h4, 
            h4 + h5,
            h5 + h6 {padding-top: .5rem;}
        }

        .comparing-chart-table {
            font-size: .9em;
        }
    }

    .help-article-related {
        padding:          1.5em 0 1em;
        width:            100%;
        background-color: $hiper-lightergray;
        border-top:       1px solid $hiper-gray;
    }
}
