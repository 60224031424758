.hero-container.personalize {
    background-color: $hiper-darkteal;
    background:       linear-gradient(0deg, $hiper-teal 0%, $hiper-darkteal 50%);

    .hero-background {
        max-height: 720px;

        img {max-height: 100%;}
    }

    @media (min-width: 1025px) {
        &.personalize--ef .hero-background {
            height:    calc(100% - 4em);
            top:       -1em;
            left:      50%;
            transform: translateX(-5%);
        }

        &.personalize--e .hero-background {
            height:    calc(100% - 4em);
            top:       -1em;
            left:      50%;
            transform: translateX(4em);
        }

        &.personalize--f .hero-background,
        &.personalize--tdc .hero-background {
            height:    calc(100% - 10em);
            top:       1em;
            left:      50%;
            transform: translateX(-2em);
        }

        &.personalize--ne .hero-background,
        &.personalize--tm .hero-background {
            top:       10em;
            left:      50%;
            transform: translateX(10%);
        }

        &.personalize--n .hero-background {
            height:    calc(100% - 10em);
            top:       2em;
            left:      50%;
            transform: translateX(4em);
        }

        @media (max-width: 1650px) {
            &.personalize--ne .hero-background,
            &.personalize--tm .hero-background {
                height: calc(100% - 4em);
                top:    8em;
            }
        }
    }

    @media (max-width: 1024px) and (min-width: 521px) {
        &.personalize--ef .hero-background,
        &.personalize--e .hero-background {
            height: calc(100% - 10em);
            top:    1em;
            right:  1em;
        }

        &.personalize--f .hero-background,
        &.personalize--tdc .hero-background {
            max-width: 22em;
            width:     60%;
            top:       3em;
            right:     1em;
        }

        &.personalize--ne .hero-background {
            height:    calc(100% - 6em);
            top:       9em;
            right:     25%;
            transform: translateX(25%);
        }

        &.personalize--n .hero-background {
            height: calc(100% - 12em);
            top:    2em;
            left:   55%;
        }

        &.personalize--tm .hero-background {
            height: calc(100% - 6em);
            top:    9em;
            left:   50%;
        }
    }

    @media (max-width: $phone) {
        &.personalize--ef .hero-background {
            width: 75%;
            top:   3em;
            right: 25%;
            transform: translateX(50%);
        }

        &.personalize--e .hero-background {
            width: 50%;
            top:   3em;
            right: .5em;
        }

        &.personalize--f .hero-background {
            width: 55%;
            top:   8em;
            right: 1em;
        }

        &.personalize--ne .hero-background {
            width:     55%;
            top:       11.5em;
            left:      50%;
            transform: translateX(-4em);
        }

        &.personalize--n .hero-background {
            width:     40%;
            top:       8.25em;
            left:      50%;
            transform: translateX(1.5em);
        }

        &.personalize--tdc .hero-background {
            width: 50%;
            top:   10em;
            right: .5em;
        }

        &.personalize--tm .hero-background {
            width:     50%;
            top:       8em;
            left:      50%;
            transform: translateX(1em);
        }
    }
}

.hero-container.hero-container--usp.hero-container--ida,
.hero-container.hero-container--usp.hero-container--stepler {
    h2 {
        margin:         0 auto;
        font-family:    $font-montserrat;
        font-size:      1.2em;
        text-transform: none;
        line-height:    1.6;
        text-align:     center;
        hyphens:        manual;
        color:          #ffffff;

        img {
            position: relative;
            display:  inline-block;
            height:   1.4em;
        }

        .discount-code {
            @include          font-weight-variable(500);
            padding:          .1em .5em;
            border-radius:    1em;
            background-color: $hiper-red;
        }
    }

    @media (min-width: 521px) {
        .hide-on-large-devices {display: none;}
    }

    @media (max-width: $phone) {
        .hide-on-small-devices {display: none;}
    }
}

.hero-container.hero-container--usp.hero-container--stepler h2 img {
    top:    .24em;
    filter: drop-shadow(0px 0px 4px rgba(#000000,.5));
}

.hero-container.hero-container--usp.hero-container--ida {
    @media (min-width: 521px) {
        h1 {padding-bottom: .1em;}
    }

    @media (max-width: $phone) {
        h1 {padding-bottom: .25em;}
    }

    h2 img {
        top:         .3em;
        margin-left: .15em;
        filter:      drop-shadow(0px 0px 4px rgba(#000000,.25));
    }
}
