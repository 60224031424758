.prices .content > section:not(:first-of-type) {
    padding-top: 2em;
}

// region: Tool-tip

.prices,
.adjustments {
    .tool-tip {
        display: inline-block;
        color:   $hiper-red;

        .tool-tip-text {
            visibility:       hidden;
            position:         absolute;
            z-index:          2;
            padding:          1em;
            border-radius:    .5em;
            text-align:       left;
            line-height:      1.6;
            color:            #ffffff;
            white-space:      normal;
            background-color: $hiper-blue;

            * {
                transition: all 0s;
            }

            strong {
                font-family:    $font-cervo;
                font-size:      1.4em;
                line-height:    1.1;
                text-transform: uppercase;
                font-weight:    300;
                letter-spacing: .025em;
            }
        }

        &:hover .tool-tip-text {
            visibility: visible;
        }
    }

    .prices-table .tool-tip .tool-tip-text {
        margin-top: 1.5em;
        width:      22em;
        transform:  translateX(-100%);
    }
}

// endregion: Tool-tip 

// region: Ellipse

.adjustments {
    .ellipse {
        position:      relative;
        top:           .05em;
        z-index:       1;
        display:       inline-block;
        width:         .8em;
        height:        .8em;
        border-radius: .4em;
        text-align:    right;

        &.ellipse-fiber {background-color: $hiper-fiber;}
        &.ellipse-mbb   {background-color: $hiper-mbb;}
        &.ellipse-coax  {background-color: $hiper-coax;}
        &.ellipse-dsl   {background-color: $hiper-dsl;}

        span {
            position:         absolute;
            z-index:          -1;
            top:              -.2rem;
            right:            0;
            font-size:        .9em;
            padding:          0 .4em;
            height:           1.2rem;
            border-radius:    .6rem;
            color:            #ffffff;
            background-color: inherit;
            white-space:      nowrap;
            display:          block;
            pointer-events:   none;
            opacity:          0;
            transition:       opacity .2s ease;

            @media (max-width: $phone) {line-height: 1.35;}
        }

        &:hover span {
            opacity: 1;
        }
    }

    .ellipse-explainer {
        display:         flex;
        justify-content: center;
        gap:             0 1.5em;
        margin-bottom:   .5em;

        li {
            margin:      0;
            padding:     0;
            line-height: 1.2;
        }

        .ellipse {
            top: .13em;
        }

        span {
            font-size: .8em;
        }
    }

    @media (min-width: 641px) {
        h3 {
            padding-bottom: .2rem;
        }

        .ellipse-explainer {
            flex-direction: row;
            align-items:    center;
        }
    }

    @media (max-width: 640px) {
        h3 {
            padding-bottom: 0;
        }

        .ellipse-explainer {
            flex-direction: column;
            align-items:    flex-start;
        }
    }
}

// endregion: Ellipse

.prices .blurb {
    max-width: none;
}

.prices-container {
    &.accordion-content,
    &.accordion-content.active {
        padding-left:  0;
        padding-right: 0;
    }
}

.price-divider {
    max-width: none;
    margin:    1em auto;
}

// region: Coverage map

.prices-container .coverage-map-wrapper {
    p {
        font-size:   .8em;
        line-height: 1.4;
    }

    &.coverage-map-wrapper--mbb iframe {
        display:       block;
        width:         100%;
        border-radius: 1em;
        box-shadow:    var(--box-shadow);
    }
}

// endregion: Coverage map

// region: Tables

.prices-table,
.adjustments-table {
    margin: 0;

    th, td {
        padding:        0;
        border:         0;
        vertical-align: middle;
    }

    td {
        line-height:    1.4;
    }
}

.prices-table {
    max-width: 22em;

    tr:not(:only-child):not(:first-child) td:not(.prices-table-coax300) {
        padding-top: 1em;
    }

    td:not(.prices-table-coax300) {
        &:first-child:not(:only-child) {
            padding-right: .5em;
        }

        &:last-child:not(:only-child) {
            text-align:  right;
            white-space: nowrap;
        }
    }

    .prices-table-address-button {
        text-align: center;

        p:first-child {
            text-align: left;
            font-size:  .9em;
        }

        a     {width:       100%;}
        a + p {padding-top: .25em;}
    }

    .prices-table-coax300 {
        padding:          .9em;
        border-radius:    .5em;
        background-color: $hiper-lightergray;
        text-align:       center;

        .heading {
            margin: 0;
            color:  inherit;
        }

        p {
            font-size: .7em;
        }
    }

    .prices-table-dsl {
        color: rgba($hiper-blue, .75);
    }
}

.adjustments-table {
    $border-radius: .5em;

    th {
        font-family:    $font-cervo;
        font-size:      1.2em;
        text-transform: uppercase;
        padding:        em(5px, 16px * em(18px)) em(18px, 16px * em(18px));
        background:     $hiper-teal;

        &:first-child {
            border-radius: $border-radius 0 0 0;
        }

        &:last-child {
            border-radius: 0 $border-radius 0 0;
        }
    }

    td {
        padding: .5em 1em;
    }

    thead tr {
        th:last-child {
            text-align: right;
        }
    }

    tbody tr {
        &:nth-child(odd) {
            background-color: rgba(#000000, .025);
        }

        &:nth-child(even) {
            background-color: rgba(#000000, .05);
        }

        td {
            &:first-child {
                padding-right: 0;
            }

            &:last-child {
                padding-left: .5em;
                text-align:   right;
                white-space:  nowrap;
            }
        }
    }
}

// endregion: Tables

// region: Media-query

@media (min-width: 769px) {
    .adjustments .adjustments-table .tool-tip {
        position: relative;

        &::before {
            display:      none;
            content:      "";
            position:     absolute;
            top:          1.05em;
            left:         50%;
            margin-left:  -.4em;
            border-width: .4em;
            border-style: solid;
            border-color: transparent transparent $hiper-blue;
        }

        &:hover::before {
            display: block;
        }

        .tool-tip-text {
            $width:      17em;

            margin-top:  .35em;
            margin-left: -($width / 2);
            width:       $width;
            top:         100%;
            left:        50%;
        }
    }

    .prices-container {
        display:         flex;
        flex-direction:  row;
        justify-content: space-between;
        align-items:     flex-start;
        gap:             1em 2em;

        .coverage-map-wrapper.coverage-map-wrapper--mbb {
            width: 50%;

            iframe {height: 26em;}
        }

        .coverage-map {
            max-width: 18em;
        }
    }
}

@media (max-width: $tablet) {
    @media (min-width: 521px) {
        .adjustments .adjustments-table .tool-tip .tool-tip-text {
            width:     24em;
            position:  fixed;
            top:       50%;
            left:      50%;
            transform: translate(-50%, -50%);
        }
    }

    .prices-container {
        .coverage-map-wrapper {
            p {
                text-align: center;
            }

            &.coverage-map-wrapper--mbb {
                width: 100%;

                iframe {
                    margin:     0 auto 1em;
                    max-width:  32em;
                    max-height: 60vh;
                    height:     32em;
                }
            }
        }

        .coverage-map {
            max-width: 10em;
            margin:    0 auto .5em;
            filter:    drop-shadow(.5px 1px 2px rgba(0, 0, 0, .3));
        }

        &.accordion-content .coverage-map {
            margin-top: 2px;
        }

        .prices-table {
            width:  100%;
            margin: 0 auto;
        }
    }
}

@media (min-width: 521px) {
    .prices-table {
        flex-shrink: 0;
    }
}

@media (max-width: $phone) {
    .prices .prices-table .tool-tip .tool-tip-text,
    .adjustments .prices-table .tool-tip .tool-tip-text {
        transform: translateX(-50%);
        left:      50%;
        padding:   .8em .5em;
        max-width: calc(100vw - 2em);
        width:     100%;
    }

    .adjustments .adjustments-table .tool-tip {
        &::before {
            display:      none;
            content:      "";
            position:     absolute;
            margin-top:   1.05em;
            margin-left:  0.1em;
            border-width: .4em;
            border-style: solid;
            border-color: transparent transparent $hiper-blue;
        }

        &:hover::before {
            display: block;
        }

        .tool-tip-text {
            margin-top: 1.75em;
            width:      100%;
            left:       50%;
            transform:  translateX(-50%);
        }
    }

    .adjustments-table {
        th, td {
            padding-left:  .5em;
            padding-right: .5em;
        }
    }
}

// endregion: Media-query
