// BEGIN: .wl-overlay {

.wl-overlay {
    display: none;
    z-index: $z-index-overlay;

    &.visible {
        display:  block;
        position: fixed;
        top:      0;
        right:    0;
        bottom:   0;
        left:     0;
    }

    .error {
        box-shadow: 0 0 0 3px #ffffff, 0 0 0 5px $hiper-red;
    }

    .form__sending-overlay {
        background: rgba(#ffffff, .9);
    }

    .form__sent-overlay {
        background: #ffffff;
    }

    .form__not-sent-overlay {
        background: #ffffff;
    }

    @at-root .wl-overlay__close {
        display:     block;
        position:    absolute;
        top:         0;
        right:       0;
        padding:     .1em .4em;
        color:       $hiper-red;
        font-size:   em(40px);
        line-height: 1em;
        cursor:      pointer;
    }

    @at-root .wl-overlay__window {
        position:           absolute;
        top:                $height-header + 2em;
        left:               50%;
        transform:          translateX(-50%);
        margin:             auto;
        max-height:         75vh;
        border-radius:      .5em;
        overflow-y:         auto;
        -ms-overflow-style: none;
        background:         #ffffff;
        text-align:         center;
        box-shadow:         var(--box-shadow);

        @media (min-width: 521px) {
            padding:   3em 1.5em 2em;
            max-width: 52em;
            min-width: 22em;
            width:     calc(100vw - 4em);
        }

        @media (max-width: $phone) {
            padding:   2.5em 1em 1.5em;
            max-width: 22em;
            width:     calc(100vw - 2em);
        }
    }

    form {
        padding: 2em 0 0 0;
    }
}

// } END: .wl-overlay


.price-calc {
    background-color: rgba(#000000, .25);
    backdrop-filter:  blur(4px);

    .heading {
        padding-bottom: .5rem;
    }

    .summary-table {
        max-width:     20rem;
        margin-bottom: 1em auto;

        thead th {padding-bottom: 0;}

        tbody td,
        tfoot td {font-size: .9em;}

        tbody tr:last-child td {
            border-radius: 0;
        }
    }

    .summary-table.single {
        tbody tr:last-child td {
            border-bottom: 1px solid $hiper-gray;
        }

        tbody td:last-child {
            &, .discount   {@include font-weight-variable(500);}
            .strikethrough {@include font-weight-variable(400);}
        }
    }

    .summary-table.monthly {
        tbody tr:only-child td {
            border-bottom: 1px solid $hiper-gray;

            &:last-child {@include font-weight-variable(500);}
        }
    }

    .summary-table + p {
        padding:   1rem 0 0;
        font-size: 1.1em;
    }

    .vat-notice {font-size: .8em;}
}
