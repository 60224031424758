.hero-container--tilbud-1,
.hero-container--tilbud-2,
.hero-container--tilbud-simple-1,
.hero-container--tilbud-simple-2,
.hero-container--tilbud-sign-1,
.hero-container--tilbud-sign-2 {
    .stamp {
        position:        relative;
        display:         flex;
        justify-content: center;
        align-items:     center;
        flex-shrink:     0;
        flex-grow:       0;
        max-width:       none;
        max-height:      none;
        border-radius:   50%;
        text-align:      center;
    }
}

.hero-container--tilbud-1,
.hero-container--tilbud-simple-1,
.hero-container--tilbud-sign-1 {
    .hero-background {
        max-height:          1080px;
        top:                 50%;
        left:                50%;
        background-repeat:   no-repeat;
        background-size:     cover;
        background-position: center center;

        @media (max-width: 470px) {
            max-width:           1080px;
            width:               100%;
            height:              100%;
            transform:           translate(-50%, -50%);
            background-position: center top;
            background-image:    imgurl('pages/home/blank-scene-blur-mobile.jpg');

            .desktop {display: none;}
        }

        @media (min-width: 471px) {
            aspect-ratio:     16 / 9;
            max-width:        1920px;
            height:           120%;
            transform:        translate(-50%, -50%);
            background-image: imgurl('pages/home/blank-scene-blur-desktop.jpg');

            .mobile {display: none;}
        }

        @media (min-width: 701px) {
            &::after {
                content:        '';
                position:       absolute;
                z-index:        1;
                top:            0;
                left:           -1px;
                right:          -1px;
                bottom:         0;
                width:          calc(100% + 2px);
                height:         100%;
                pointer-events: none;
                background:     linear-gradient(90deg,
                                rgba($hiper-teal, 1) 0%,
                                rgba($hiper-teal, 0) 10%,
                                rgba($hiper-teal, 0) 90%,
                                rgba($hiper-teal, 1) 100%);
            }
        }

        @media (min-width: 1201px) {
            max-height:            150%;
            width:                 100%;
            height:                auto;
            background-position-y: 25%;
        }
    }
}

.hero-container--tilbud-2,
.hero-container--tilbud-simple-2,
.hero-container--tilbud-sign-2 {
    background: linear-gradient(0deg, $hiper-lightgray, #ffffff);

    .total-discount-container,
    .stamp-container,
    .cta-container {
        display:         flex;
        justify-content: center;
        align-items:     center;
    }

    .total-discount-container {
        margin-bottom: 1em;

        .icon {
            display: block;
            color:   transparent;
            fill:    $hiper-blue;
        }
    }

    .total-discount {
        width: fit-content;

        table {
            td {
                padding: .2em .5em;

                &:first-child {padding-left: 0;}

                &:nth-child(2) {
                    padding-right: 0;
                    text-align:    right;
                    white-space:   nowrap;
                }
            }

            tbody td:nth-child(2) {@include font-weight-variable(500);}

            tfoot td {
                @include        font-weight-variable(600);
                padding-bottom: 0;
                vertical-align: middle;

                strong {font-size: 1.5em;}
            }

            tfoot td {
                border-top: 1px solid $hiper-gray;
            }

            tfoot tr:only-child td {border-radius: 0;}
        }
    }

    .stamp {
        background-color: $hiper-red;
        color:            #ffffff;

        p {
            font-size:   1.6em;
            line-height: 1.1;

            & > span,
            & > strong {display:   block;}
            & > strong {font-size: 1.75em;}
        }

        .comma-dash {
            letter-spacing: -.15em;
            margin-right:   0;
        }
    }

    .cta-container p {
        @include        font-weight-variable(300);
        font-family:    $font-cervo;
        text-transform: uppercase;
        letter-spacing: var(--letter-spacing-big);
        font-size:      2.8em;
        line-height:    1;

        strong {
            @include font-weight-variable(400);
            color:   $hiper-red;
        }

        a {
            border: 0;
            transition: opacity .15s $ease-in-out-cubic;

            &:hover {
                opacity: .75;
            }
        }

        span:not(:last-child) {
            margin-right: .125em;
        }
    }

    .conditions {
        @include     font-weight-variable(400);
        text-align:  center;
        color:       $hiper-blue;
        text-shadow: none;
    }
}

.hero-container--tilbud-2,
.hero-container--tilbud-simple-2 {
    .total-discount {
        padding:          1em 1.5em .75em;
        border-radius:    1em;
        box-shadow:       0 0 0 4px $hiper-red;
        background-color: #ffffff;

        h3 {padding: 0;}
    }
}

.hero-container--tilbud-1,
.hero-container--tilbud-2 {gap: 0;}

.hero-container--tilbud-1,
.hero-container--tilbud-sign-1 {
    padding-bottom: 1em;

    .hero-background-wrapper {pointer-events: none;}

    .hero-content {
        display:         flex;
        flex-direction:  row-reverse;
        justify-content: center;
        align-items:     center;
        margin-bottom:   0;
        width:           fit-content;

        h1 {
            padding:     0;
            color:       $hiper-blue;
            font-size:   1em;
            text-align:  center;
            line-height: 1;

            & > * {width: unset !important;}

            .pre-1 {font-size: .8em;}
            .pre-2 {font-size: .6em;}
            .price {font-size: 4.5em;}
            .post  {font-size: .6em;}

            .price {
                position:    relative;
                margin-left: .1em;
                font-family: $font-cervo;

                .md {
                    position:       absolute;
                    top:            calc(50% - .2em);
                    right:          -.3em;
                    transform:      translateY(-100%);
                    font-size:      .25em;
                    font-weight:    300;
                    text-transform: lowercase;
                }
            }
        }

        h1, h2 .header-sub {
            @include        font-weight-variable(400);
            font-family:    $font-montserrat;
            text-transform: none;
            letter-spacing: normal;
        }

        h2 {
            padding:    0;
            font-size:  3.6em;
            text-align: left;
            color:      #ffffff;

            .header-main, .header-sub {
                display: block;
            }

            .header-sub {
                font-size:   .4em;
                line-height: 1.4;

                .small {font-size: .75em;}
            }
        }
    }

    @media (min-width: 1201px) {
        .hero-content h2 .header-sub                          {font-size: .4em;}
        &.hero-container--aarhus .hero-content h2 .header-sub {font-size: .3em;}
    }
    @media (max-width: 1200px) {
        .hero-content                                         {gap:       .75em 2em;}
        .hero-content h2                                      {font-size: 2.6em;}
        .hero-content h2 .header-sub                          {font-size: .5em;}
        &.hero-container--aarhus .hero-content h2 .header-sub {font-size: .4em;}
    }

    @media (max-width: 900px) {
        .hero-content h2 {font-size: 2.2em;}
    }
}

.hero-container--tilbud-2,
.hero-container--tilbud-sign-2 {
    .total-discount-container {padding: 0 1em;}

    .total-discount-container,
    .stamp-container,
    .cta-container {
        gap: 1em 2em;
    }

    .equals {
        @include   font-weight-variable(450);
        font-size: 4em;
    }

    .total-discount-container .icon {
        margin: 0 calc(-.2em * 5);
        width:  5em;
        height: 5em;
    }

    .address-wrap {
        margin: 0 auto .5em;

        h2 {
            @include    font-weight-variable(400);
            text-align: center;
            color:      $hiper-blue;

            .scroll-down-arrow::before,
            .scroll-down-arrow::after {
                background-color: $hiper-blue;
            }
        }
    }
}

.hero-container--tilbud-1 {
    .hero-content {
        gap: 1em 4em;

        .stamp {
            box-shadow:       var(--box-shadow);
            background-color: #ffffff;
            font-size:        1.5em;

            svg {
                position:       absolute;
                top:            50%;
                left:           50%;
                transform:      translate(-50%, -50%);
                width:          100%;
                height:         100%;
                pointer-events: none;
            }
        }
    }
}

.hero-container--tilbud-2 {
    padding: 2em 0 .5em;
}

.hero-container--tilbud-sign-1 {
    .hero-content {
        .offer-container {
            position:        relative;
            display:         flex;
            justify-content: center;
            align-items:     center;
            flex-shrink:     0;
            width:           10em;
            height:          10em;
            filter:          drop-shadow(var(--box-shadow));

            img {
                position:  absolute;
                left:      50%;
                bottom:    0;
                z-index:   -1;
                transform: translateX(-50%);
                width:     100%;
            }

            h1 {
                font-size:  1.1em;
                color:      #ffffff;
                text-align: center;
                hyphens:    manual;

                & > span {
                    margin: 0 auto
                }

                strong {
                    @include font-weight-variable(600);
                }

                .post {margin-top: -.5em;}
            }

            div {
                position:        absolute;
                display:         flex;
                justify-content: center;
                align-items:     center;
                width:           10em;
                height:          10em;
                border-radius:   50%;
                background:      linear-gradient(
                                     0deg,
                                     darken(#ffffff, 20%) 0%,
                                     #ffffff 60%
                                 );
            }

            p {
                margin-top:  .5em;
                font-size:   1em;
                line-height: 1.1;
                color:       $hiper-bloodred;
                text-align:  center;

                & > span,
                & > strong {display:   block;}
                & > strong {font-size: 3.25em;}

                .hiper-text-stroke::after {
                    background: linear-gradient(77.5deg,rgba($hiper-bloodred,0) 0,$hiper-bloodred 80%);
                }
            }

            .comma-dash {
                letter-spacing: -.15em;
                margin-right:   0;
            }
        }

        @media (min-width: 801px) and (max-width: 1080px) {
            padding-right: 5em;
        }
    }
}

.hero-container--tilbud-sign-2 {
    .total-discount {
        h3 {
            padding-bottom: .25em;

            .icon {
                display:        inline-block;
                margin:         0 0 0 -.25em;
                width:          1em;
                height:         1em;
                transform:      rotate(90deg);
                vertical-align: top;
            }
        }

        table {
            padding:          .75em 1em .5em;
            border-radius:    1em;
            box-shadow:       0 0 0 4px $hiper-red;
            background-color: #ffffff;
        }
    }
}

.hero-container--tilbud-simple-1 {
    padding-bottom: 1em;
    gap:            1em;

    .hero-content {
        margin-bottom: -1em;

        h1 {
            font-family:    $font-montserrat;
            text-transform: none;
            line-height:    1.1;
            text-align:     center;
            hyphens:        manual;
            margin:         0 auto;

            span {display: inline-block;}

            .hiper-text-stroke::after {
                z-index: -1;
            }

            & > strong  {font-size: 1.2em;}
            span strong {font-size: 1.8em;}

            strong {
                @include font-weight-variable(700);
            }

            .price-after {
                padding-top: .5em;
                font-size:   .8em;
            }
        }
    }

    .address-wrap {
        h2 {
            font-family:    $font-cervo;
            font-weight:    300;
            text-transform: uppercase;
            font-size:      2em;
            text-align:     center;
            padding:        0;
        }
    }

    @media (min-width: 521px) {
        padding-top: 6em;

        .hero-content h1 {
            font-size: 2em;
        }

        .address-wrap {
            margin: 2.5em 0 -2.8em;

            h2 {
                margin-bottom: .25rem;

                .scroll-down-arrow {
                    top: .15em;
                }
            }
        }

        .stamp {
            font-size: .9em;
        }
    }

    @media (max-width: $phone) {
        padding-top: 4em;

        .hero-content h1 {
            font-size: 1.6em;
        }

        .address-wrap {
            margin: 1.5em 0 -2.8em;

            h2 .scroll-down-arrow {
                top: .15em;
            }
        }
    }
}

.hero-container--tilbud-simple-2 {
    gap:     0;
    width:   100%;
    padding: 3.5em 1em .5em;

    .total-discount-container {
        gap: 2em 0;

        .icon {
            width:  6em;
            height: 6em;
        }
    }

    .total-discount {
        box-shadow: inset 0 0 0 4px $hiper-red,
                    var(--box-shadow);
    }

    .stamp {
        box-shadow: var(--box-shadow);
        font-size:  1.2em;
    }
}

.page-campaign-aarhus,
.page-campaign-ewii,
.page-campaign-fibia,
.page-campaign-fibia-fiber,
.page-campaign-tilbud {
    .content--medium {
        padding-bottom: 2em;

        h3 strong {
            @include font-weight-variable(400);
            color:   $hiper-red;
        }

        li {padding: 0;}

        .content {
            padding-left:  0;
            padding-right: 0;

            &:first-child {padding-top: 0;}
        }
    }

    .section-includes {
        background-color: $hiper-lightergray;

        .grid {gap: 2em;}

        li {
            margin:  0;
            padding: 0;
        }

        img {
            display: block;
            margin:  0 auto;
            width:   16em;
        }

        ul:first-of-type {
            margin-bottom: 4em;

            img {
                margin-top:    -1.5em;
                margin-bottom: -1.5em;
            }
        }

        ul:nth-of-type(2) img {
            margin-top:    -.5em;
            margin-bottom: -.5em;
        }

        @media (max-width: 660px) {
            ul:first-of-type {
                margin-bottom: 4em;
            }
        }
    }
}

@media (min-width: 801px) {
    [class*="hero-container--tilbud"] {
        .hide-on-large-devices {display: none;}
    }

    .hero-container--tilbud-1 .hero-content .stamp {
        margin-bottom: -2rem;
    }

    .hero-container--tilbud-sign-1 .hero-content .offer-container div {
        top:       -1em;
        right:     -7em;
        transform: rotate(5deg);
        font-size: .5em;
    }

    .hero-container--tilbud-sign-2 {
        padding: 2em 0 .5em;
    }
}

@media (min-width: 1151px) {
    .hero-container--tilbud-simple-2 {
        .cta-container {margin-left: 2em;}
    }

    .hero-container--tilbud-sign-1 .hero-content {
        gap: 1em 2em;

        .offer-container {
            margin-bottom: -2em;
            font-size:     1.6em;
        }
    }
}

@media (max-width: 1150px) {
    .hero-container--tilbud-2 {
        .total-discount-container {margin-bottom: 0;}
    }

    .hero-container--tilbud-sign-1 .hero-content {
        gap: 1em;

        .offer-container {
            font-size: 1.5em;
        }
    }
}

@media (max-width: 1150px) and (min-width: 801px) {
    .hero-container--tilbud-2,
    .hero-container--tilbud-simple-2 {
        .total-discount-container {
            flex-wrap: wrap;
        }

        .cta-container {
            width: 100%;

            p {
                margin: 0;

                br {display: none;}
            }
        }
    }

    .hero-container--tilbud-sign-1 .hero-content .offer-container {
        margin-bottom: -1.25em;
    }
}

@media (max-width: 800px) {
    [class*="hero-container--tilbud"] {
        .hide-on-small-devices {display: none;}
    }

    .hero-container--tilbud-1 {
        padding-top: 7em;

        .hero-background-wrapper::after {
            content:        '';
            position:       absolute;
            z-index:        1;
            top:            -1px;
            left:           0;
            right:          0;
            bottom:         -1px;
            width:          100%;
            height:         calc(100% + 2px);
            pointer-events: none;
            background:     linear-gradient(180deg,
                            rgba($hiper-teal, 1) 10%,
                            rgba($hiper-teal, 0) 40%,
                            rgba($hiper-gray, 0) 90%,
                            rgba(#ffffff, 1)     100%);

            @media (max-width: 420px) {
                background: linear-gradient(180deg,
                            rgba($hiper-teal, 1)      10%,
                            rgba($hiper-teal, 0)      40%,
                            rgba($hiper-lightgray, 0) 80%,
                            rgba(#ffffff, 1)          100%);
            }
        }

        .hero-content {
            flex-direction: column-reverse;
            padding:        0;

            h2 {
                text-align: center;
                font-size:  2.8em;

                @media (max-width: $phone) {
                    font-size: 10vw;
                }

                .header-sub {font-size: .45em;}
            }

            .stamp {margin-bottom: -.5rem;}
        }
    }

    .hero-container--tilbud-sign-1 {
        .hero-background-wrapper::before {
            content:        '';
            position:       absolute;
            z-index:        1;
            top:            -1px;
            left:           0;
            right:          0;
            bottom:         -1px;
            width:          100%;
            height:         calc(100% + 2px);
            pointer-events: none;
            background:     linear-gradient(
                                0deg,
                                rgba($hiper-teal, .75) 0%,
                                rgba($hiper-teal, 0) 12em
                            );
        }

        .hero-content {
            flex-direction: column;

            .offer-container {
                font-size: 1.5em;

                div {
                    top:       25%;
                    right:     -6.5em;
                    transform: translateY(-50%) rotate(5deg);
                    font-size: .5em;
                }
            }
        }
    }

    .hero-container--tilbud-2,
    .hero-container--tilbud-simple-2 {
        .stamp-container,
        .total-discount-container .icon,
        .total-discount-container p br {display:        none;}
        .total-discount-container      {flex-direction: column;}
        .total-discount-container p    {text-align:     center;}
    }

    .hero-container--tilbud-2 {
        padding: .5em 0 .5em;

        .total-discount {display: none;}
    }

    .hero-container--tilbud-sign-2 {
        gap:     .5em;
        padding: 1em 0 .5em;
    }

    .hero-container--tilbud-simple-2{
        padding: 2.5em 1em .5em;

        .total-discount-container {margin-bottom: .5em;}

        .total-discount {
            padding: 1em 1em .5em;

            h3 {text-align: center;}
        }
    }
}

@media (max-width: $phone) {
    .hero-container--tilbud-sign-1 .hero-content .offer-container {
        margin-left: -2em;
    }
}
