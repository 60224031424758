.about-container {
    width:            100%;
    max-height:       960px;
    display:          flex;
    flex-direction:   row;
    justify-content:  center;
    align-items:      stretch;
    text-align:       center;
    background-color: $hiper-blue;
    margin:           0;
    overflow:         hidden;

    .flex-item {
        max-width: 960px;
    }

    .about-left {
        width:           50%;
        padding:         1em;
        display:         flex;
        flex-direction:  column;
        justify-content: center;
        align-items:     center;
	

        h1,
        h2,
        p {
            color: #fff;
        }
    
        .pre-about {
            font-size:      1.8em;
            text-transform: none;
            font-weight:    400;
            padding:        0;
        
            @media (max-width:1150px) {
                font-size: 1.5em;
            }
        }
    
        .about {
            font-size:   3em;
            line-height: 1;
            font-weight: 500;
            padding:     .25em 0;

            @media (max-width:1150px) {
                padding: .1em 0;
            }
        }

        .about-heading {
            line-height: 1;
            padding:     1em 0 .2em 0;
        }

        p {
            max-width: 24em;
        }
    }

    .about-right {
        width:             50%;
        min-height:        40vw;
        max-height:        960px;
        background:        url(../images/pages/home/about.jpg) center center;
        background-repeat: no-repeat;
        background-size:   cover;
    
        @media (max-width:450px) {
            display: none;
        }
    }

    @media (max-width: $tablet) {
        flex-direction: column-reverse;
        max-height:     none;

        .about-left,
        .about-right {
            width: 100%;
        }

        .about-left {
            min-height: 50vw;
            padding:    2em 1em;
        }

        .about-right {
            height: 60vw;
        }
    }
}
