.hiper-coop-logo {
    margin-bottom: 1em;
    max-width:     500px;

    @media (max-width: $tablet) {
        max-width: 350px;
    }

    @media (max-width: $phone) {
        max-width: 200px;
    }

    .white {fill: #FFFFFF;}
    .coop  {fill: #BE1F24;}
}

.page-campaign-coop,.page-campaign-tdcnet {
    .hero-container {
        .hero-fille-coop-h1 {
            font-size:   5.5em;
            font-weight: 300;
            line-height: 0.8;
            margin-left: -0.07em;

            @media (max-width: $tablet) {
                font-size: 5em;
            }

            @media (max-width: $phone) {
                font-size: 3.4em;
            }
        }

        .hero-pre-price {
            color: #FFFFFF;

            @media (max-width: $tablet) {
                font-size:   1.8em;
                padding-top: 0;
            }

            @media (max-width: $phone) {
                font-size: 1em;
            }
        }
    }

    .section-coop-card {
        background-color: $hiper-blue;

        .coop-instructions {
            background-color: $hiper-blue;
            display:          flex;
            flex-direction:   row;
            position:         relative;

            .text {
                margin-left: .3em;

                h2 {
                    color:      #FFFFFF;
                    text-align: left;
                }

                p {
                    color:       #FFFFFF;
                    max-width:   30em;
                    font-weight: 100;
                }
            }

            .coop-card {
                max-width: 280px;
            }

            @media (max-width: $phone) {
                flex-direction: column;
                text-align:     center;

                .text {
                    margin-left: 0;

                    h2 {
                        text-align: center;
                    }
                }
            }
        }
    }

    // campaign insert
    section.light-grey-campaign-section {
        background-color: #f7f7f7;
    }

    .campaign-section-coop {
        position: relative;
        padding:  1.5em 1em;

        .left {
            text-align: left;

            h2 {
                font-size: 1em;

                &, & > span {
                    display:    block;
                    text-align: left;
                }
            }

            .speed {
                font-size:   5em;
                font-weight: 400;
                line-height: 1;
                padding:     0;
                text-align:  left;
            }

            .price {
                width:       fit-content;
                font-size:   10em;
                text-align:  left;
                position:    relative;
                font-weight: 400;

                @media (max-width: $tablet) {
                    text-align: center;
                }

                .price-md {
                    top:   34%;

                    @media (max-width: $phone) {
                        top: 30%;
                    }
                }
            }

            .filler {
                font-size:   1.5em;
                line-height: .7em;
            }

            .filler,
            .price-disclaimer {
                padding-bottom: .7em;
                font-family:    $font-montserrat;
                text-transform: none;
            }

            .price-disclaimer {
                line-height: 1.6;
            }
        }

        .right {
            position: relative;

            .mvfp-splash {
                position: absolute;
                top:      -0.8em;
                left:     1.1em;

                .mvfp {
                    max-width: 150px;
                }

                @media (max-width: $tablet) {
                    left: -.5em;

                    .mvfp {
                        max-width: 125px;
                    } 
                }

                @media (max-width: $phone) {
                    left: -1em;

                    .mvfp {
                        max-width: 100px;
                    }  
                }
            }
        }

        @media (max-width: $tablet) {
            .speed,
            .left,
            .filler,
            .price-disclaimer {
                text-align: center;
            }
        }

        @media (max-width: 900px) {
            .router {
                max-width: 400px;
            }
        }

        @media (max-width: $phone) {
            .router {
                max-width: 300px;
            }
        }

        .conditions {
            font-size: .7em;
            max-width: var(--hero-max-width);
        }

        @media (max-width: $tablet) {
            .flex {
                flex-direction: column-reverse;
            }
        }
    }
}
