.page-home {
    .usp-section {
        padding:          2em 0;
        max-height:       none;
        background-color: $hiper-teal;

        @media (max-width: $phone) {
            max-height: 0;
            display:    none;
        }

        .trustpilot--link {
            border-bottom: 0;

            .text {
                border-bottom: em(2px) solid rgba($hiper-red, .75);
            }

            .trustpilot--logo {
                display:      inline-block;
                width:        100%;
                max-width:    4.75em;
                padding-left: .1em;
                fill:         currentColor;

                svg {
                    vertical-align: text-bottom;
                }
            }

            &:hover {
                .text {
                    border-bottom-color: $color-transparent-dark;
                }

                .trustpilot--logo {
                    fill: currentColor;
                }
            }
        }

        .usps {
            display:         flex;
            justify-content: center;
            margin:          0 0 em(20px) 0;
            padding:         em(20px) 0 0 0;
            transition:      all .18s $ease-in-out-cubic;
            list-style:      none;

            --item-padding:  #{em(20px)};
            --item-margin:   #{em(20px)};
            --background:    #{desaturate($hiper-blue, 5%)};
            --color-heading: white;
            --color-p:       #{rgba(white, .75)};

            li {
                display:         flex;
                width:           100%;
                max-width:       em(250px);
                flex:            1 1 100%;
                flex-flow:       column;
                justify-content: space-between;
                margin:          0 .2em;
                margin:          0 var(--item-margin);
                padding:         var(--item-padding);
                border-radius:   em(5px);
                background:      var(--background);

                & > :last-child {
                    padding-bottom: 0;
                }
            }

            .heading, p {
                width: 100%;
            }

            .heading {
                padding-bottom: .5em;
                font-size:      1.35em;
                font-weight:    300;
                color:          var(--color-heading);
                text-align:     left;
                letter-spacing: var(--letter-spacing-big);

                .icon {
                    display:      inline-block;
                    width:        .55em;
                    height:       .55em;
                    margin-right: .25em;
                    color:        $hiper-red;
                    line-height:  .55em;
                }
            }

            p {
                font-size: .92em;
                color:     var(--color-p);
            }

            @media (max-width: $tablet) {
                --item-padding:  0;
                --item-margin:   .2em;
                --background:    0;
                --color-p:       #{$hiper-blue};
                --color-heading: #{$hiper-blue};

                li {
                    display: block;
                }

                .heading {
                    font-size: 1em;
                }

                p {
                    font-size: 1em;
                }
            }
        }

        .usp-svg-image {
            $bg-size: 11em;

            width:    $bg-size;
            height:   $bg-size;
            position: relative;

            & > svg {
                width:  $bg-size;
                height: $bg-size;
            }

            &.customers {
                .trustpilot--logo,
                .trustpilot--stars,
                .trustpilot--link {
                    display:   block;
                    position:  absolute;
                    left:      50%;
                    transform: translateX(-50%);
                }

                .trustpilot--logo {
                    top:        2.5em;
                    width:      7em;
                    margin:     auto;
                    text-align: center;
                    display:    block;
                    fill:       #ffffff;
                }

                .trustpilot--stars {
                    top: 5em;
                }

                .trustpilot--link {
                    top:            7em;
                    width:          100%;
                    text-align:     center;
                    text-transform: uppercase;
                    font-weight:    500;
                    color:          #ffffff;
                }
            }

            &.support {
                $icon-size: 3.5em;

                .icon {
                    position: absolute;
                    top:      ($bg-size / 2) - ($icon-size / 2);
                    width:    $icon-size;
                    height:   $icon-size;
                    color:    rgba(53, 61, 96, .6);
                    fill:     rgba(255, 255, 255, .85);
                }

                .icon-fb {
                    left: (($bg-size / 2) / 2) - ($icon-size / 2) + .5em;
                }

                .icon-phone {
                    right: (($bg-size / 2) / 2) - ($icon-size / 2) + .5em;
                }
            }
        }
    }

    .section-with-image {
        padding-top:    2em;
        padding-bottom: 2em;

        .content {
            .image {
                float:        left;
                margin-right: 2em;

                img {
                    max-width:  24em;
                    box-shadow: smooth-box-shadow(black, .07, 75px, 50px);
                }
            }

            .heading {
                line-height: 1.1;
                text-align:  left;
            }

            @media (max-width: 890px) {
                .image {
                    display: none;
                }

                .heading {
                    text-align: center;
                }
            }
        }
    }
}

.page-home,
.page-products {
    .content__product-list {
        > .blurb,
        > .blurb > .heading {
            padding-bottom: 0;
        }
    }
}

.about-us {
    margin-top: 2em;

    h1, h2, h3 {
        padding-bottom: .7rem;
        line-height:    1.1;
    }

    h2 {
        padding-top: .7rem;
    }

    .loyalty-groups {
        margin-top: 2em;

        sup {
            font-size:      .4em;
            vertical-align: super;
        }

        .stamp {
            display:         flex;
            justify-content: center;
            align-items:     center;
            margin-bottom:   2em;

            img {
                $stamp-size:  10em;

                width:         $stamp-size;
                height:        $stamp-size;
                border-radius: 50%;
                box-shadow:    1px 3px 6px 3px rgba(#000, .3);
            }

            &.stamp--multiple img {
                &:nth-child(2) {
                    margin-left: -3em;
                }

                &:nth-child(3) {
                    margin-left: -3em;
                }

                &:nth-child(4) {
                    margin-left: -3em;
                }
            }

            @media (max-width: $phone) {
                img {
                    width:  7em;
                    height: 7em;
                }
            }
        }
    }
}

.page-press {
    .item {
        h2, h3, h4 {
            padding-bottom: 0;
        }

        h3 {
            padding-top: 1rem;
        }

        &:nth-last-child(1),
        &:nth-last-child(2) {
            .wrap-thing-bar {
                border-top: 2px solid $hiper-darkteal;
            }
        }
    }

    .blurb {
        max-width: none;

        p       {max-width:   52em;}
        .nowrap {white-space: nowrap;}
    }

    .content {
        img {
            width:     100%;
            max-width: 28em;
        }

        p {
            color: $hiper-blue;
        }
    }

    .grid {
        display:         flex;
        flex-flow:       row wrap;
        justify-content: center;
        align-items:     flex-start;
        gap:             2em 4em;
    }

    .item {
        max-width: 620px;
        width:     24em;
    }

    .wrap-thing {
        box-shadow: var(--box-shadow);
    }

    @media (max-width: 880px) {
        .wrap-thing {
            max-width:    em(400px);
            margin-right: auto;
            margin-left:  auto;
        }
    }

    @media (max-width: $phone) {
        .item {
            width: 100%;

            h3 {padding-top: .75rem;}
        }
    }
}

.tp-reviews {
    display:         flex;
    justify-content: center;
    align-items:     flex-start;
    padding:         2em 1em;

    @media (max-width: $phone) {
        flex-direction: column;
        align-items:    center;
    }
}

.tp-review {
    flex:      1;
    display:   block;
    border:    none;
    margin:    0 .5em;
    font-size: .95em;
    max-width: 18em;

    @media (max-width: 1500px) {
        &:nth-last-child(1) {
            display: none;
        }
    }

    @media (max-width: 1000px) {
        &:nth-last-child(2) {
            display: none;
        }
    }

    @media (max-width: 750px) {
        &:nth-last-child(3) {
            display: none;
        }
    }

    @media (max-width: $phone) {
        margin:    0;
        font-size: 1.2em;

        &:nth-child(1) {
            margin-bottom: 1.4em;
        }
    }
}

.tp-review-title {
    font-size:      1em;
    letter-spacing: 0;
    text-overflow:  ellipsis;
    overflow:       hidden;
    white-space:    nowrap;
    padding:        0 0 .5em 0;
}

.tp-review-stars {
    text-align:  center;
    padding:     0 0 .5em 0;
    line-height: 1;
}

.tp-review-text {
    letter-spacing: var(--letter-spacing-medium);
    padding-bottom: 0;
    text-align:     center;
}

.section-tp-header {
    background-color: $hiper-teal;
    color:            #ffffff;
    text-align:       center;

    .tp-text {
        max-width: 30em;
    }

    .blurb .content p:last-child {
        padding-bottom: 0;
    }

    .trustpilot--logo {
        top:        2.5em;
        width:      6em;
        margin:     auto;
        text-align: center;
        display:    block;
        fill:       #ffffff;
    }

    .arrow-wrap-tp {
        position:   relative;
        width:      100%;
        text-align: center;

        .arrow-down-tp {
            position:     absolute;
            transform:    translateX(-50%);
            left:         50%;
            width:        0;
            height:       0;
            border-left:  20px solid transparent;
            border-right: 20px solid transparent;
            border-top:   20px solid $hiper-teal;
        }
    }
}

.trustpilot-header {
    $background-color: $hiper-teal;

    position:          relative;
    padding:           2em 1em;
    background-color:  $background-color;

    .blurb {
        padding: 0;
    }

    p {
        color: #ffffff;

        &:nth-child(3) {
            line-height: 1.4;

            .star-count {
                padding:          .2em .5em;
                background-color: $hiper-darkteal;
                border-radius:    0.5em;
            }
        }
    }

    .arrow-container {
        width:      100%;
        text-align: center;
        position:   absolute;
        left:       0;
        bottom:     0;
        z-index:    1;

        .arrow-down {
            width:        0;
            height:       0;
            position:     absolute;
            transform:    translateX(-50%);
            left:         50%;
            top:          0;
            border-left:  20px solid transparent;
            border-right: 20px solid transparent;
            border-top:   20px solid $background-color;
        }
    }
}

.trustpilot-widget-wrap {
    background-color: #ffffff;

    .trustpilot-widget {
        padding: 2.5em 1em;
    }
}

.section-about-internet {
    padding-top: 2em;

    .blurb {
        padding: 1.25em 0 .5em;
    }

    .content:first-of-type {
        padding-top: 0;
    }
}

.content--narrow.content--trustpilot {
    padding-bottom: 0;

    img {
        display: block;
        margin:  0 auto;

        @media (min-width: 521px) {
            max-height: 40vh;
        }
    }
}

.content--wide.content--trustpilot {
    padding-bottom: 0;
}

.page-employee-index .content .button.red {
    .icon path {
        transition: transform .15s $ease-in-out-quad;
        transform:  translateX(0);
    }

    &:hover .icon path {
        &:first-of-type {transform: translateX(100px);}
        &:last-of-type  {transform: translateX(-50px);}
    }
}
