.reseller {
    .form-group {
        margin-bottom: 1em;
    }

    .link {
        margin-top: 1em;
        text-align: right;
    }

    .alert {
        text-align:    center;
        padding:       1em;
        margin-bottom: 1em;
        border-radius: .1875em;
    }

    .error {
        background: $hiper-darkred;
        color:      white;
    }

    .success {
        background: $hiper-darkgreen;
    }

}
