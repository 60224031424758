$box-shadow-focus:  0 0 0 2px $color-focus;
$box-shadow-error:  0 0 0 2px $color-error;
$box-shadow-border: inset 0 0 0 1px $color-neutral;

input, textarea, select {
    width:            100%;
    background-color: white;
    font-family:      $font-montserrat;
    font-size:        1em;
    letter-spacing:   var(--letter-spacing-big);
    border:           1px solid $color-neutral;
}

input {
    height:     2.5rem;
    margin:     0;
    padding:    0 em(10px);
    text-align: center;

    &.error {
        box-shadow:   $box-shadow-error;
        border-color: $color-error;

        &:focus {
            box-shadow:   $box-shadow-focus;
            border-color: $color-focus;
        }
    }

    &:focus {
        outline:      none;
        box-shadow:   $box-shadow-focus;
        border-color: $color-focus;
    }

    @at-root .checkbox-label::before,
    &[type=text],
    &[type=password],
    &[type=number],
    &[type=email],
    &[type=tel] {
        border-radius: .5rem;
    }

    &[type=number]::-webkit-inner-spin-button,
    &[type=number]::-webkit-outer-spin-button {
        margin:             0;
        -webkit-appearance: none;
    }

    &[type=password],
    &[type=number],
    &[type=email],
    &[type=tel] {
        -moz-appearance: textfield;
    }

    &[type=radio],
    &[type=checkbox] {
        width:    1px;
        height:   1px;
        position: absolute;
        margin:   -1px;
        padding:  0;
        overflow: hidden;
        border:   0;
        clip:     rect(0 0 0 0);
    }

    @at-root .checkbox-label {
        color:       $hiper-blue;
        cursor:      pointer;
        user-select: none;

        &:not(.checkbox-label--montserrat) {
            font-family:    $font-cervo;
            font-size:      1.25em;
            text-transform: uppercase;
            font-weight:    300;
            line-height:    1.2;
        }

        &.checkbox-label--montserrat strong {
            @include font-weight-variable(500);
        }

        &.checkbox-label--box {
            display:          block;
            min-height:       2.5rem;
            padding-top:      .45rem;
            padding-left:     .5rem;
            padding-right:    .5rem;
            border-radius:    .5rem;
            box-shadow:       $box-shadow-border;
            background-color: $hiper-lightergray;
            letter-spacing:   .02rem;
            transition:       all .12s $ease-in-out-quad;
        }

        i {
            font-family: inherit;
            font-weight: 300;
        }

        &::before {
            font-family:      $font-montserrat;
            @include          font-weight-variable(500);
            font-size:        1.6rem;
            width:            1.4rem;
            height:           1.4rem;
            margin-right:     .5rem;
            float:            left;
            background-color: #ffffff;
            color:            $hiper-teal;
            line-height:      .84;
            text-align:       center;
            content:          '';
            border:           1px solid $color-neutral;
        }

        a {
            color: $hiper-blue;

            &:hover {
                color:           $hiper-red;
                text-decoration: none;
            }
        }

        strong {
            font-weight: 400;
        }
    }

    @at-root p.with-checkbox {
        color:       $hiper-blue;
        user-select: none;
        position:    relative;

        .checkbox-label {
            cursor: pointer;

            &::before {
                float:       none;
                position:    absolute;
                left:        0;
                margin:      0;
                text-indent: 0;
            }
        }

        a {
            color: $hiper-blue;

            &:hover {
                color:           $hiper-red;
                text-decoration: none;
            }
        }

        .checkbox-label:not(.checkbox-label--montserrat) {
            & + a {
                font-family:    $font-cervo;
                font-size:      1.25em;
                font-weight:    300;
                text-transform: uppercase;
            }

            strong,
            & + strong,
            & + a strong {
                font-weight: 400;
            }
        }

        .checkbox-label.checkbox-label--montserrat {
            strong,
            & + strong,
            & + a strong {
                @include font-weight-variable(600);
            }
        }
    }

    &[type=radio]:not(:disabled) + .checkbox-label--box:hover {
        background-color: $hiper-lightgray;
    }

    &[type=radio] + .checkbox-label::before {
        border:        none;
        box-shadow:    $box-shadow-border;
        margin-top:    .2rem;
        width:         1.2rem;
        height:        1.2rem;
        border-radius: .6rem;
    }

    &[type=radio]:checked + .checkbox-label {
        &::before {
            background-color: $hiper-darkteal;
            box-shadow:       $box-shadow-border,
                              inset 0 0 0 4px #ffffff;
        }

        &.checkbox-label--box {
            background-color: $hiper-teal;
            color:            #ffffff;
            box-shadow:       none;

            &::before {
                box-shadow: inset 0 0 0 3px #ffffff;
            }
        }
    }

    &[type=radio]:checked:focus + .checkbox-label,
    &[type=radio].error:checked:focus + .checkbox-label {
        &::before {
            box-shadow: $box-shadow-focus,
                        $box-shadow-border,
                        inset 0 0 0 4px #ffffff;
        }

        &.checkbox-label--box {
            box-shadow: $box-shadow-focus;

            &::before {
                box-shadow: inset 0 0 0 3px #ffffff;
            }
        }
    }

    &[type=radio].error + .checkbox-label {
        &:not(.checkbox-label--box)::before {
            box-shadow: $box-shadow-error,
                        $box-shadow-border;
        }

        &.checkbox-label--box {
            box-shadow: $box-shadow-error;
        }
    }

    &[type=radio].error:checked + .checkbox-label {
        &:not(.checkbox-label--box)::before {
            box-shadow: $box-shadow-error,
                        $box-shadow-border,
                        inset 0 0 0 4px #ffffff;
        }

        &.checkbox-label--box {
            box-shadow: $box-shadow-error;
        }
    }

    &[type=checkbox]:checked + .checkbox-label::before {
        content: '\00d7';
    }

    &[type=checkbox].error + .checkbox-label::before,
    &[type=checkbox].error:checked + .checkbox-label::before {
        box-shadow: $box-shadow-error;
    }

    &[type=checkbox]:focus + .checkbox-label::before,
    &[type=checkbox].error:focus + .checkbox-label::before,
    &[type=checkbox].error:checked:focus + .checkbox-label::before {
        box-shadow: $box-shadow-focus;
    }

    &[type=radio]:disabled + .checkbox-label,
    &[type=checkbox]:disabled + .checkbox-label {
        opacity: .75;
        cursor:  default;
    }

    &[type=text].inline {
        width:       auto;
        padding:     0 em(5px);
        background:  transparent;
        font-size:   1em;
        font-weight: 500;
        line-height: 1em;
        text-align:  left;

        &:active, &:focus {
            background-color: white;
        }
    }
}

textarea {
    min-height:    em(150px, 16px * em(24px));
    margin:        0;
    padding:       em(10px) em(10px);
    border-radius: .5rem .5rem 0 .5rem;
    text-align:    left;

    &.error {
        box-shadow: $box-shadow-error;

        &:focus {
            box-shadow: $box-shadow-focus;
        }
    }

    &:focus {
        outline:    none;
        box-shadow: $box-shadow-focus;
    }

}

.continue-button-submit {
    width:    1px;
    height:   1px;
    position: absolute;
    margin:   -1px;
    padding:  0;
    overflow: hidden;
    border:   0;
    clip:     rect(0 0 0 0);

    &:focus + .button {
        box-shadow: $box-shadow-focus;
    }
}

.icon.continue-button {
    display: block;
    width:   em(50px);
    height:  em(50px);
    margin:  auto;
    padding: 0;
    cursor:  pointer;
}

.submit-button-block,
.submit-button-flex,
.ordertake-submit {
    padding:    1em 0;
    text-align: center;

    .button {
        padding: .5em 1.5em;

        .text {
            font-size:   1.6em;
            line-height: 1.1;
        }

        @media (max-width: $phone) {
            padding-bottom: .2em;
        }
    }
}

.submit-button-flex {
    display:         flex;
    flex-flow:       row wrap-reverse;
    justify-content: center;
    align-items:     center;
    gap:             .25rem;
}

@media (min-width: 521px) {
    p.with-checkbox {
        padding-left: 1.9em;
    }

    .permissions-disclaimer {
        padding-left: 1.9em;
    }
}

@media (max-width: $phone) {
    p.with-checkbox {
        text-indent: 1.9em;
    }
}

select {
    display:            inline-block;
    height:             2.5em;
    margin:             0;
    padding:            0 em(10px);
    border:             1px solid $color-neutral;
    border-radius:      .5rem;
    outline:            none;
    text-align:         center;
    text-align-last:    center;
    -webkit-appearance: none;
    -moz-appearance:    none;
    appearance:         none;
    cursor:             pointer;

    &:focus {
        outline:      none;
        box-shadow:   $box-shadow-focus;
        border-color: $color-focus;
    }

    &.error {
        box-shadow:   $box-shadow-error;
        border-color: $color-error;

        &:focus {
            box-shadow:   $box-shadow-focus;
            border-color: $color-focus;
        }
    }
}

.select-wrap {
    display:  block;
    position: relative;
    height:   2.5em;
    cursor:   pointer;

    &::after {
        display:          block;
        position:         absolute;
        top:              1px;
        right:            1px;
        bottom:           1px;
        padding:          .35em .75em 0 .75em;
        border-radius:    0 .5em .5em 0;
        background-color: $color-wild-sand;
        font-size:        1em;
        color:            $color-dusty-gray;
        line-height:      2em;
        content:          '\25bc';
        pointer-events:   none;
    }
}

.yes-no {
    display:         flex;
    align-items:     center;
    justify-content: flex-start;
    gap:             .35em 1.5em;
    padding:         0 0 1em;
}

.flatpickr-wrapper {
    display:     inline-block;
    position:    relative;
    user-select: none;
    font-family: $font-cervo;
    font-weight: 300;

    input {
        position: relative;
        z-index:  1;
        cursor:   pointer;
    }

    &.inline input[type=text] { // Oder so...
        display: none;
    }
}

.flatpickr-calendar {
    max-height:       0;
    position:         absolute;
    top:              100%;
    left:             0;
    z-index:          0;
    margin-top:       em(3px);
    padding:          em(8px);
    overflow:         hidden;
    border-radius:    .5rem;
    background-color: white;
    font-size:        em(20px);
    opacity:          0;

    table, td, th {
        padding:       0;
        border:        0;
        border-radius: 0;
        background:    transparent;
    }

    table {
        width:           100%;
        padding:         0;
        border-collapse: collapse;
    }

    th {
        padding:    em(5px);
        color:      black;
        text-align: center;
    }

    td, th {
        font-size: em(20px, 20px);
    }

    td:hover .flatpickr-day {
        background-color: $hiper-seablue;
    }

    .selected .flatpickr-day,
    td.selected:hover .flatpickr-day {
        background-color: $hiper-red;
        color:            white;
    }

    .slot {
        cursor: pointer;
    }

    .disabled,
    .disabled .flatpickr-day,
    .disabled:hover .flatpickr-day {
        border-color: transparent;
        background:   transparent;
        color:        $color-alto;
        cursor:       default;
    }

}

.flatpickr-months {
    color:          black;
    text-align:     center;
    text-transform: uppercase;
    font-weight:    400;
}

.flatpickr-prev-month,
.flatpickr-next-month {
    padding:         .2em .4em .2em .4em;
    font-size:       em(14px, 20px);
    text-decoration: none;
    cursor:          pointer;

    &:hover {
        color: $hiper-lightblue;
    }
}

.flatpickr-prev-month {
    float: left;
}

.flatpickr-next-month {
    float: right;
}

.open .flatpickr-calendar,
.inline .flatpickr-calendar {
    min-width:  em(280px);
    max-height: em(318px);
    z-index:    100;
    opacity:    1;
}

.inline .flatpickr-calendar {
    position: static;
}

.flatpickr-day {
    display:          block;
    width:            em(35px);
    height:           em(25px);
    margin:           2px;
    padding:          0;
    border:           1px solid transparent;
    border-radius:    em(2px);
    background-color: $color-gallery;
    line-height:      em(25px);
    text-align:       center;

    @media (max-width: $phone) {
        width: em(30px);
    }
}

.today .flatpickr-day {
    border-color: desaturate($hiper-red, 20%);
}

td.today:hover .flatpickr-day {
    border-color:     desaturate($hiper-red, 20%);
    background-color: desaturate($hiper-red, 20%);
    color:            white;
}

// region: placeholder design

::-webkit-input-placeholder {
    font-weight: 400;
    font-style:  italic;
    color:       $hiper-lightteal;
    text-align:  center;
}

:-moz-placeholder { /* Firefox 18- */
    font-weight: 400;
    font-style:  italic;
    color:       $hiper-lightteal;
    text-align:  center;
    opacity:     1;
}

::-moz-placeholder { /* Firefox 19+ */
    font-weight: 400;
    font-style:  italic;
    color:       $hiper-lightteal;
    text-align:  center;
    opacity:     1;
}

:-ms-input-placeholder {
    font-weight: 400;
    font-style:  italic;
    color:       $hiper-lightteal;
    text-align:  center;
}

::placeholder {
    font-weight: 400;
    font-style:  italic;
    color:       $hiper-lightteal;
    text-align:  center;
}

// endregion: placeholder design

.input-help-text {
    display:     block;
    padding:     .5em 0 .25em 0;
    font-size:   .9em;
    line-height: 1.4;
}

// M$ puts a "clear" button on som inputs, let's not
::-ms-clear {
    display: none;
    width:   0;
    height:  0;
}

.form-footer {
    justify-content: space-between;
    padding-top:     2em;
}

button.unstyled-button {
    all: unset;
    cursor: pointer;

    &:focus {
        outline: 2px solid $hiper-lightblue;
    }
}

@media (max-width: 870px) {
    .form-footer__right {
        padding-top: em(20px);
    }
}

@media (max-width: $phone) {
    .form-footer__left label {
        font-size: em(16px);
    }
}
