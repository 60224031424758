.page-order-products {
    background-color: $hiper-lightergray;
}

.product-list {
    /*
    centered x-scrolling with max-width container
    https://ryanmulligan.dev/blog/x-scrolling-centered-max-width-container/
    */

    --gap:           2em;
    --padding:       1em;
    --border-radius: 1em;

    display:         flex;
    flex-direction:  row-reverse;
    justify-content: center;
    align-items:     flex-end;
    flex-wrap:       wrap-reverse;
    gap:             var(--gap);
    padding:         var(--padding);

    @media (max-width: 750px) {
        flex-direction:      column-reverse;
        justify-content:     flex-start;
        align-items:         center;
        gap:                 1.5em;
    }

    .discount {color: $hiper-green;}
}

.product-list-item {
    margin:      0;
    padding:     0;
    width:       20em;
    flex-shrink: 0;
}

.product-list-dsl-speed {
    font-size: .5em;

    .loader-label {
        margin:         0;
        padding:        0;
        letter-spacing: 0;
    }

    .form__loader {
        display:   inline-block;
        margin:    0 0 0 2em;
        padding:   0;
        font-size: .5em;
    }
}

.product-list-pre-link-wrap {
    position:   relative;
    text-align: center;

    line-height: 1.2;

    @media (min-width: 751px) {
        bottom:     -1px;
        min-height: 1.6em;
    }

    .pre-product {
        display:          block;
        margin:           0 auto;
        padding:          .25em .8em;
        max-width:        12em;
        width:            fit-content;
        border-radius:    .5rem .5rem 0 0;
        background-color: $hiper-teal;
        font-size:        .9em;
        text-align:       center;
        color:            #ffffff;
    }
}

.product-list-pre-link {
    display:          block;
    width:            fit-content;
    width:            -moz-fit-content;
    margin:           0 auto;
    padding:          .4em .8em;
    border-radius:    .5em .5em 0 0;
    background-color: $hiper-blue;
    font-size:        .8em;
    text-align:       center;
    color:            #ffffff;
}

.product-list-post-link {
    margin-top:   1em;
    margin-right: calc(#{em(15px)} + var(--padding));
    text-align:   right;

    .button {
        font-size: 1em;
    }
}

.product-list-link {
    display:       block;
    border-bottom: 0;
    background:    #ffffff;
    box-shadow:    var(--box-shadow);
    border-radius: var(--border-radius);
    cursor:        pointer;

    &:hover {
        box-shadow: var(--box-shadow-hover-inverse);
        color:      inherit;
    }
}

.product-list-card {
    margin:   0;
    padding:  0;
    width:    100%;
    overflow: hidden;
}

.product-list-heading {
    padding:     1rem 0 0;
    font-weight: 300;
    font-size:   1.6em;
}

.product-list-middle {
    display:         flex;
    justify-content: flex-start;
    align-items:     center;
    gap:             1em;
    padding:         var(--padding);
    min-height:      8em;
    overflow:        hidden;
    color:           $hiper-blue;

    .product-list-price-container {
        z-index: 1;

        .pre-price,
        .post-price,
        .min-price {
            line-height: 1.25;
        }

        .pre-price,
        .post-price {
            font-size: .9em;
            padding:   0;
        }

        .post-price {padding-top: .5em;}
        .min-price  {font-size:   .75em}
    }

    .product-list-price {
        justify-content: flex-start;
        margin-bottom:   -.25em;
        text-align:      left;
    }

    .price {
        font-family:    $font-cervo;
        font-size:      5em;
        font-weight:    300;
        text-transform: uppercase;
        letter-spacing: -.075rem;

        @media (max-width: $tablet) {
            line-height: .8em;
        }

        &.discount {font-weight: 400;}
    }

    .md {
        margin-left: -.5rem;
        font-size:   .8em;
    }

    .product-list-image {
        position:  relative;
        flex-grow: 1;
        height:    8em;

        img {
            position:   absolute;
            top:        50%;
            left:       50%;
            transform:  translate(-50%, -50%);
            max-width:  unset;
            height:     100%;
        }

        .mbb-stamp {
            position:         absolute;
            top:              50%;
            right:            25%;
            transform:        translate(50%, -50%) rotate(7.5deg);
            display:          flex;
            justify-content:  center;
            align-items:      center;
            width:            2em;
            height:           2em;
            border-radius:    1em;
            font-size:        1.6em;
            background-color: $hiper-blue;
            box-shadow:       var(--box-shadow);

            span {
                @include font-weight-variable(600);
                color:   #ffffff;
            }
        }
    }

    .fiber & .product-list-image img {
        height: 8em;
    }

    .mbb & .product-list-image img,
    .coax & .product-list-image img {
        height: 10em;
    }

    .dsl & {
        justify-content: center;
    }
}

.product-list-card hr {
    margin:    0 auto;
    max-width: calc(100% - calc(var(--padding) * 2));
    width:     100%;
}

.product-list-tech {
    display:         flex;
    justify-content: space-between;
    align-items:     center;
    flex-wrap:       wrap;
    gap:             0 .5em;
    padding:         .25em var(--padding) .1em;
    width:           100%;

    .product-list-speed {
        display:         flex;
        justify-content: flex-start;
        align-items:     baseline;
        gap:             .1em;
        font-size:       1.5em;

        .speed {
            font-family: $font-cervo;
            font-weight: 300;
        }

        .mbit {font-size: .45em;}
    }

    .product-list-network {
        font-size: .7em;

        &.mbb {
            @include   font-weight-variable(500);
            font-size: 1em;
        }
    }
}

.product-list-speed,
.product-list-price,
.product-list-speed-range,
.product-list-price-range {
    line-height: 1;
}

.product-list-speed,
.product-list-price {
    display:         flex;
    justify-content: center;
    align-items:     baseline;

    & > span {line-height: 1;}
}

.product-list-speed-regulatory-range {
    font-size: 1em;
}

.product-list-speed,
.product-list-speed-regulatory-range,
.product-list-price {
    text-align: center;
}

.product-list-speed-range,
.product-list-price-range {
    display:         flex;
    align-items:     baseline;
    justify-content: space-between;

    & > span:first-of-type {
        font-size:    .8em;
        margin-right: .25rem;
    }
}

.product-list-footer {
    padding:    var(--padding);
    font-size:  .8em;
    text-align: left;

    p {
        padding-bottom: 0;
        line-height:    1.4;
    }

    strong,
    .discount {@include font-weight-variable(500);}
    small     {color:   $hiper-darkgray;}
}

.product-list-button {
    @include font-weight-variable(500);
    display:          flex;
    justify-content:  space-between;
    align-items:      center;
    gap:              .5em;
    padding:          .6em 1em;
    width:            100%;
    border-radius:    0 0 var(--border-radius) var(--border-radius);
    background-color: $hiper-teal;
    color:            #ffffff;
    font-family:      $font-montserrat;
    font-size:        1em;
    text-transform:   none;
    letter-spacing:   normal;
    text-align:       left;

    .product-list-link:hover & {
        background-color: $hiper-darkteal;
    }

    .icon {
        display: block;
        width:   1.5em;
        height:  1.5em;
        color:   transparent;
        fill:    #ffffff;
    }
}

.product-list-disclaimer {
    p {
        padding:     0;
        font-size:   .8em;
        line-height: 1.25;
        text-align:  center;

        &:first-child {padding-top:    .75em;}
        &.price-steps {text-transform: lowercase;}

        .discount {@include font-weight-variable(500);}
    }

    @media (min-width: 751px) {
        min-height: 1em;
    }
}

.product-list-splash {
    margin-top:    calc(-1 * var(--padding));
    padding:       calc(2.5 * var(--padding)) var(--padding) var(--padding);
    border-radius: 0 0 var(--border-radius) var(--border-radius);
    box-shadow:    inset 0 1.25em .5em -.5em rgba(#000000, .3);
    background:    $hiper-red;
    color:         #ffffff;
    text-align:    center;
    width:         17em;

    .heading {font-size: 1.2em;}

    p {
        @include     font-weight-variable(300);
        font-size:   .8em;
        line-height: 1.4;
    }
}

.product-list-badge {
    $fs:            12px;
    $sq:            56px;
    display:        block;
    width:          em($sq, $fs);
    height:         em($sq, $fs);
    position:       absolute;
    top:            em(-$sq / 2, $fs);
    right:          em(-$sq / 2, $fs);
    margin:         0;
    padding:        em(10px, $fs) 0 0 0;
    border-radius:  em($sq, $fs);
    background:     $hiper-slate;
    font-family:    $font-cervo;
    font-size:      em($fs);
    color:          #ffffff;
    line-height:    em($fs, $fs);
    text-align:     center;
    letter-spacing: .01rem;
    text-transform: uppercase;
    transform:      rotate(10deg);

    &.green {
        background: $hiper-green;
    }

    &.fiber-first {
        padding-top: 1.35em;
        background:  $hiper-red;
    }

    &.free-technician {
        padding-top: 1em;
        background:  $hiper-blue;
    }
}

.product-list-usp {
    display:         flex;
    flex-direction:  column;
    justify-content: center;
    align-items:     flex-start;
    gap:             .5em;
    margin:          0 auto;
    padding:         var(--padding);
    width:           fit-content;
}

.product-list-usp-item {
    display:         flex;
    justify-content: center;
    align-items:     center;
    gap:             .25em;
    margin:          0;
    padding:         0;

    .icon {
        display:     block;
        flex-shrink: 0;
        width:       1.5em;
        height:      1.5em;
        color:       transparent;
        fill:        $hiper-blue;
    }

    .text {font-size: .9em;}
}

.product-list-disclaimers {
    padding-top: 0;
    max-width:   56em;
    text-align:  center;

    .heading {
        font-size:      1.5em;
        font-weight:    300;
        letter-spacing: var(--letter-spacing-small);
    }

    p {font-size: .7em;}
}

.wl-middle {
    flex-direction: column;
    gap:            0;
    border-bottom:  none;
    background:     #ffffff;
    font-family:    $font-montserrat;

    .wl-middle-top {
        padding-bottom: 0;
        font-size:      1.2em;

        @media (min-width: 701px) {
            font-size: 1.4em;
        }
    }

    .wl-middle-bottom {
        font-size:  .9em;
        text-align: center;
    }
}

// Region: Regional Campaign
.product-list-item--regional {
    .product-list-pre-link-wrap .pre-product {
        @include font-weight-variable(600);
        background-color: $hiper-green;
        transition:       background-color .15s $ease-in-out-quad;
    }

    &:hover .product-list-pre-link-wrap .pre-product {
        background-color: $hiper-darkgreen;
    }

    .product-list-link {
        box-shadow: 0 0 0 4px $hiper-green,
                    var(--box-shadow);
        transition: box-shadow .15s $ease-in-out-quad;

        .product-list-button {
            background-color: $hiper-green;
        }

        &:hover {
            box-shadow: 0 0 0 4px $hiper-darkgreen,
                        var(--box-shadow-hover-inverse);

            .product-list-button {
                background-color: $hiper-darkgreen;
            }
        }
    }
}

// Region: AB Test
.ab-test-container-b {
    .product-list-card {
        display:        flex;
        flex-direction: column;

        .product-list-heading {order: 1;}
        .product-list-usp     {order: 2;}
        > hr:first-of-type    {order: 3;}
        .product-list-middle  {order: 4;}
        .product-list-button  {order: 5;}
    }

    .product-list-middle {
        justify-content: center;
        padding-top:     .5em;
        padding-bottom:  .5em;
    }

    .product-list-price-container {
        display:         flex;
        flex-wrap:       wrap;
        justify-content: center;
        align-items:     center;
        gap:             0 .25em;

        .price {font-size: 6em;}

        .product-list-price {
            order:           1;
            width:           100%;
            justify-content: center;
        }

        .pre-price,
        .post-price {
            margin:  0;
            padding: 0;
        }

        .pre-price  {order: 2;}
        .post-price {order: 3;}
    }

    .product-list-usp {gap: .25em}
}

// Region: Media queries
@media (min-width: 751px) {
    .ab-test-container-b {
        .product-list-middle {min-height: 8em;}
        .product-list-usp    {min-height: 7em;}
    }
}

@media (max-width: 750px) {
    .chosen-address {
        font-size:   .9em;
        line-height: 1.4;
    }

    .product-list {
        padding-left:  .5em;
        padding-right: .5em;
    }

    .product-list-item {
        width:     100%;
        max-width: 20em;
    }

    .product-list-card {
        width: 100%;
    }

    .product-list-middle .product-list-image .mbb-stamp {
        right:     -.5rem;
        transform: translateY(-50%) rotate(7.5deg);
        font-size: 2em;
    }

    .mbb .product-list-middle .product-list-image,
    .coax .product-list-middle .product-list-image {
        height: 10em;

        img {height: 11em;}
    }
}

.biper-visible.public-hidden {
    .product-list-pre-link-wrap,
    .product-list-heading,
    .product-list-button {opacity: .75;}

    .product-list-image {mix-blend-mode: multiply;}

    .product-list-link,
    .table--plain {
        background-image: repeating-linear-gradient(
                              -45deg,
                              #ffffff, #ffffff 1.5em,
                              $hiper-candyfloss 1.5em, $hiper-candyfloss 3em
                          );
    }
}

span.affiliate-coupon {
    padding:          0 .25em;
    border-radius:    em(2px);
    background-color: $hiper-red;
    color:            #ffffff;
    letter-spacing:   .07em;
    font-weight:      400;
    white-space:      nowrap;
}

.rentdesk-logo {
    width:         25em;
    margin-bottom: .2em;
}

.rentdesk-heading {
    font-family:    'Montserrat', sans-serif;
    font-weight:    300;
    font-size:      .5em;
    text-transform: none;
    margin-bottom:  1em;
    display:        block;
    line-height:    1.7em;
}

.affiliate-coupon-rentdesk {
    padding:          .3em;
    border-radius:    .5em;
    background-color: $hiper-red;
    color:            #ffffff;
    letter-spacing:   .07em;
    font-weight:      400;
}
