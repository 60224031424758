.site-is-updating {
    .logo {
        max-width: 250px;
    }

    .hiper-type {
        fill: $hiper-blue;
    }

    .heading {
        margin-bottom:  0;
        padding-bottom: 0;
    }
}

.maintenance-rocket-ftw {
    display:   block;
    max-width: 100px;
    position:  relative;
    bottom:    0;
    left:      50%;
    transform: translateX(-50%);
    animation: none;
    fill:      rgba($hiper-blue, .5);
}

@keyframes rocket {
    0% { transform: translate(-50%, 0) rotate(-1deg); }
    25% { transform: translate(-50%, 3%) rotate(1deg); }
    50% { transform: translate(-50%, 5%) rotate(-1deg); }
    100% { transform: translate(-50%, 3%) rotate(1deg); }
}


@media (min-width: 750px) and (min-height: 625px) {
    .site-is-updating__section {
        position:  absolute;
        top:       50%;
        left:      50%;
        transform: translate(-50%, -50%);
    }

    .maintenance-rocket-ftw {
        max-width: 200px;
        position:  fixed;
        animation: .5s linear 0s infinite alternate rocket;
    }
}
