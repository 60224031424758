.trustpilot--stars {
    display:     inline-block;
    width:       100%;
    height:      1.45em;
    max-width:   em(235px, 16px * em(32px));
    line-height: 1.45em;

    .fg {
        fill: white;
    }

    .bg-on {
        fill: $hiper-darkgreen;
    }

    .bg-off {
        fill: $color-gray-nickel;
    }
}
