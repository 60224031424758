.loyalty-group-hero,
.carousel-item.loyalty-group-hero {
    --not-blue-background-image: #{imgurl('campaigns/loyalty_group/hero-900.jpg')};

    --heading-font-size:         2.2em;
    --heading-prefix-font-size:  .4em;
    --conditions-font-size:      .7em;

    --badge-size:                6em;
    --badge-top:                 1em;
    --badge-right:               1em;

    --speed-price-margin:        .25em 0 0 0;
    --speed-price-font-size:     2.2em;
    --blue-price-font-size:      2em;
    --blue-price-display:        block;
    --blue-price-line-height:    1;

    @media (min-width: 450px) {
        --badge-size:            8em;
        --badge-top:             2em;
        --badge-right:           2em;

        --speed-price-margin:    0;
        --speed-price-font-size: 2.5em;
    }

    @media (min-width: 680px) {
        --badge-size: 10em;
    }
    @media (min-width: 820px) {
        --not-blue-background-image: #{imgurl('campaigns/loyalty_group/hero-2000.jpg')};
        --speed-price-font-size:     2.8em;
        --speed-price-margin:        .75 0 0 0;
        --badge-size:                13em;
    }
    @media (min-width: 3000px) {
        --not-blue-background-image: #{imgurl('campaigns/loyalty_group/hero.jpg')};
    }

    background-image:            var(--not-blue-background-image);
    background-size:             cover;
    background-position:         center;

    .content {
        position: relative;
        width:    100%;
        height:   100%;
    }

    .left {
        display:         flex;
        flex-flow:       column;
        height:          100%;
        justify-content: flex-end;
        text-shadow:     0 .025em .175em rgba(black, .5);
    }

    .left, .left * {
        text-align: left;
    }

    .heading {
        font-size: var(--heading-font-size);

        .line {
            display: block;
        }

        .prefix {
            font-size:      var(--heading-prefix-font-size);
            color:          white;
            padding-bottom: .2em;
        }

        .blue {
            color: #23d5d8;
        }

        .red {
            color: $hiper-red;
        }
    }

    .speed-price {
        font-size:      var(--speed-price-font-size);
        margin:         var(--speed-price-margin);
        text-transform: uppercase;
        font-weight:    500;
        color:          white;
    }

    .red-price {
        display:     var(--blue-price-display);
        color:       $hiper-red;

        line-height: var(--blue-price-line-height);

        .number {
            font-size: var(--blue-price-font-size);
        }
    }

    .conditions {
        width:     100%;
        font-size: var(--conditions-font-size);
        color:     rgba(white, .75);
        color:     rgba(white, .5);
    }

    .badge {
        display:       block;
        position:      absolute;
        top:           var(--badge-top);
        right:         var(--badge-right);
        width:         var(--badge-size);
        height:        var(--badge-size);
        border-radius: 50%;
        transform:     rotate(10deg);
        border-bottom: 0;
        padding:       0;
        margin:        0;

        &:hover {
            box-shadow: 0 .25em .5em rgba(190, 0, 90, .75);
        }

        svg {
            width: inherit;
        }
    }
}
