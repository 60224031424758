
.spies-banner-container {
    transition: margin-top $header-transition;
}

.spies-banner {
    z-index:          35;
    padding:          1.4em 0.8em;
    background-color: #303a4b;
    color:            white;
    width:            100%;
    text-align:       center;
    line-height:      3em;

    .banner-text {
        font-size: 1em;
       
        @media (max-width: $tablet) {
            font-size: .9em;
        }   

        @media (max-width: $phone-small) {
            font-size: .6em;
        }   
    }

    .spies-logo {
        max-width:    90px;
        margin-left:  .5em;
        margin-right: .5em;
        
        @media (max-width: $phone) {
            max-width:  140px;
            margin-top: 0.5em;
        }
    }

    .btn {
        padding:          .3em .8em .3em .8em;
        background-color: #FE8C00;
        color:            white;
        border-radius:    0.8em;

        @media (max-width: $phone) {
            display:    block;
            margin-top: 0.5em;
        }
    }
}

.page-campaign-spies {
    .hero-container {

        .spies-hero-image {
                max-width: 122em;

                @media (max-width: $tablet) {
                    transform: translateX(-14%) translateY(-40px);
                    max-width: 70em;
                }

                @media (max-width: $phone) {
                    max-width: 55em;
                    transform: translateX(-149px) translateY(59px);
                }
        }

        .hero-content {
            .hero-fille-spies-h1 {
                font-size:   5.5em;
                font-weight: 300;
                line-height: 0.9;
                margin-left: -0.07em;

                @media (max-width: $tablet) {
                    font-size: 5em;
                    filter:    drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.5));
                }

                @media (max-width: $phone) {
                    font-size: 3.4em;
                   
                }
            }

            .hero-pre-price {
                color:       #FFFFFF;
                padding-top: 0.5em;
                line-height: 1.05em;

                @media (max-width: $tablet) {
                    font-size:   1.3em;
                    padding-top: 0;
                    filter:      drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.5));
                }

                @media (max-width: $phone) {
                    font-size: 1em;
                }
            }
        }
    }

    .giftcard {
        display:          block;
        padding:          .8em;
        background-color: #FE8C00;
        color:            white;
        border-radius:    0.8em;
    }

    .splash-item {
        background-color: #FE8C00;
        transform:        rotate(15deg);
        top:              30%;
        left:             60%;
    }

    .conditions {
        font-size: .7em;
    }

      // campaign insert
    section.light-grey-campaign-section {
        background-color: #f7f7f7;
    }

    .campaign-section-coop {
        position: relative;
        padding:  1.5em 1em;

        .left {
            text-align: left;

            h2 {
                font-size: 1em;

                &, & > span {
                    display:    block;
                    text-align: left;
                }
            }

            .speed {
                font-size:   5em;
                font-weight: 400;
                line-height: 1;
                padding:     0;
                text-align:  left;
            }

            .price {
                font-size:   10em;
                text-align:  left;
                position:    relative;
                font-weight: 400;

                @media (max-width: $tablet) {
                    text-align: center;
                }

                .price-md {
                    right: 2.5em;
                    top:   34%;

                    @media (max-width: $tablet) {
                        right: .5em;
                    }

                    @media (max-width: $phone) {
                        top:   30%;
                        right: .4em;
                    }
                }
            }

            .filler {
                font-size:   1.5em;
                line-height: .7em;
            }

            .filler,
            .price-disclaimer {
                padding-bottom: .7em;
                font-family:    $font-montserrat;
                text-transform: none;
            }

            .price-disclaimer {
                line-height: 1.6;
            }
        }

        .right {
            position: relative;

            .mvfp-splash {
                position: absolute;
                top:      -0.8em;
                left:     1.1em;

                .mvfp {
                    max-width: 150px;
                }

                @media (max-width: $tablet) {
                    left: -.5em;

                    .mvfp {
                        max-width: 125px;
                    } 
                }

                @media (max-width: $phone) {
                    left: -1em;

                    .mvfp {
                        max-width: 100px;
                    }  
                }
            }
        }

        @media (max-width: $tablet) {
            .speed,
            .left,
            .filler,
            .price-disclaimer {
                text-align: center;
            }
        }

        @media (max-width: 900px) {
            .router {
                max-width: 400px;
            }
        }

        @media (max-width: $phone) {
            .router {
                max-width: 300px;
            }
        }

        .conditions {
            font-size: .7em;
            max-width: var(--hero-max-width);
        }

        @media (max-width: $tablet) {
            .flex {
                flex-direction: column-reverse;
            }
        }
    }

      //section USP

    .usp {
        padding: 1em 0;

        .text {
            width: 90%;
        }

        h4 {
            text-align:  left;
            padding-top: 0;
        }

        p {
            font-size: medium;
        }

        img {
            width: 90%;
        }

        @media (max-width: $tablet) {
            .flex {
                flex-direction: column;
            }

            img {
                width: 100%;
            }

            .text {
                width:         100%;
                margin-bottom: 1em;
            }

            h4 {
                padding-top: 1em;
            }
        }

        @media (max-width: $phone) {
            padding: 0 .5em 0 .5em;
        }
    }

    .footer {
        margin-top: 0;
    }
}
