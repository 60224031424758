.page-home,
.page-products,
.page-order-products {


    .splash--free-initiation,
    .splash--override-adjustment-price-for-product,
    .splash--override-tariff-for-product,
    .splash--override-tariff-for-product-and-override-adjustment-price-for-product,
    .splash--switcharoo {
        top:            -3.75em;
        right:          -4.5em;
        font-size:      1em;
        letter-spacing: 0;
        transition:     all .25s $ease-in-out-cubic;
        transform:      rotate(10deg);
        opacity:        1;

        @media (max-width: $tablet) {
            position:  absolute;
            font-size: .75em;
        }

        @media (max-width: 700px) {
            top:       -5.25em;
            right:     50%;
            font-size: .7em;
            transform: rotate(0deg) translate(50%);
        }

        @media (max-width: $phone) {
            top:       -6em;
            font-size: .65em;
        }

        @at-root .address-lookup-typing & {
            opacity: 0;
        }

        &.splash--switcharoo-double {
            &.splash--secondary {
                right:     auto;
                left:      -4.5em;
                transform: rotate(-10deg);
            }

            @media (max-width: $tablet) {
                position:  absolute;
                font-size: .75em;

                &.splash--secondary {
                    position:  absolute;
                    font-size: .75em;
                }
            }

            @media (max-width: 700px) {
                top:       -5.25em;
                right:     auto;
                left:      20%;
                z-index:   2;
                font-size: .7em;
                transform: rotate(-5deg) translate(0%);

                &.splash--secondary {
                    top:       -5.25em;
                    right:     20%;
                    left:      auto;
                    z-index:   1;
                    font-size: .7em;
                    transform: rotate(5deg) translate(0%);
                }
            }

            @media (max-width: 550px) {
                right:     auto;
                left:      7%;
                transform: rotate(-5deg) translate(0%);

                &.splash--secondary {
                    right:     7%;
                    left:      auto;
                    transform: rotate(5deg) translate(0%);
                }
            }

            @media (max-width: $phone) {
                top:       -6em;
                font-size: .65em;

                &.splash--secondary {
                    top:       -6em;
                    font-size: .65em;
                }
            }
        }

        .splash__heading {
            display:   block;
            margin:    0;
            padding:   .1em 0 .2em 0;
            font-size: 1.05em;
            color:     $hiper-red;
            hyphens:   none;
        }

        &.splash--blue .splash__heading {
            color: white;
        }

        .splash__message {
            display:   block;
            max-width: 8em;
            margin:    auto;
            padding:   0;
            font-size: .5em;
            color:     inherit;
        }

        .splash__message-secondary {
            display:     block;
            max-width:   8em;
            margin:      0 auto;
            padding:     0;
            font-size:   .6em;
            color:       $hiper-red;
            line-height: 1;
        }

        .splash__message-info {
            display:     block;
            max-width:   8em;
            margin:      0 auto;
            padding:     0;
            font-size:   .4em;
            line-height: .2;
        }
    }

    .splash--override-tariff-for-product-and-override-adjustment-price-for-product {
        .splash__heading {
            padding:     0;
            font-size:   .85em;
            line-height: .8em;
        }

        .splash__message-info {
            padding:     0;
            line-height: 1;
        }

        .splash__heading--secondary {
            margin:      0;
            padding:     0;
            font-size:   0.45em;
            line-height: 1;

            .price {
                font-size:      2.5em;
                letter-spacing: -.1rem;
            }
        }

        .splash__message {
            font-size:   .4em;
            line-height: 1;
        }
    }

    .splash--switcharoo {
        .splash__heading {
            padding:     0;
            padding-top: .2em;
            font-size:   .85em;
            line-height: .8em;
        }

        .splash__heading--secondary {
            margin:      0;
            padding:     0;
            font-size:   0.45em;
            line-height: .9;

            .price {
                font-size:      2.5em;
                letter-spacing: -.1rem;
            }
        }

        .splash__message-info {
            padding:        0;
            padding-bottom: .2em;
            font-size:      .55em;
            color:          $hiper-red;
            line-height:    .9;

            span {
                font-size: .8em;
            }
        }


        .splash__message {
            font-size:   .4em;
            line-height: 1;
        }
    }

    .splash--switcharoo-giga-249 {
        .splash__heading:not(.splash__heading--secondary) {
            padding-top: .45em;
        }
    }

    .splash--switcharoo-double.splash--white {
        .splash__heading:not(.splash__heading--secondary) {
            padding-top: .45em;
        }

        .splash__heading--secondary-product {
            padding-top:    .2em;
            padding-bottom: .1em;
            font-size:      .55em;
        }
    }

    .splash--switcharoo-double.splash--blue {
        .splash__heading--secondary {
            padding:   .2em 0 .2em 0;
            font-size: .6em;
        }
    }

    .splash--free-initiation {
        &.splash--free-initiation-surge {
            padding-top: .75em;

            .splash__heading {
                padding-bottom: 0;
                font-size:      1.1em;
            }
        }

        &.splash--free-initiation-lastcall {
            padding-top: .35em;

            .splash__heading {
                padding-bottom: 0;
                font-size:      1.2em;
                line-height:    .8em;
            }
        }
    }

    .splash--override-tariff-for-product,
    .splash--override-adjustment-price-for-product {
        &.splash--free-initiation-surge {
            padding-top: .75em;

            .splash__heading {
                padding-top:    .2em;
                padding-bottom: 0;
                font-size:      1.1em;
            }
        }

        &.splash--free-initiation-lastcall {
            padding-top: .35em;

            .splash__heading {
                max-width:      4em;
                margin:         auto;
                padding-top:    .4em;
                padding-bottom: 0;
                font-size:      1em;
                line-height:    .8em;
            }
        }
    }

    .splash--override-adjustment-price-for-product {
        .splash__message-info {
            font-size: .6em;
        }

        .splash__message {
            font-size: .4em;
        }
    }
}

.page-home,
.page-products,
.page-order-products {
    .splash--rebate {
        top:            -3.75em;
        right:          -4.5em;
        font-size:      1em;
        letter-spacing: 0;
        transition:     all .25s $ease-in-out-cubic;
        transform:      rotate(10deg);
        opacity:        1;

        @media (max-width: $tablet) {
            position:  absolute;
            font-size: .75em;
        }

        @media (max-width: 700px) {
            top:       -5.25em;
            right:     50%;
            font-size: .7em;
            transform: rotate(0deg) translate(50%);
        }

        @media (max-width: $phone) {
            top:       -6em;
            font-size: .65em;
        }

        @at-root .address-lookup-typing & {
            opacity: 0;
        }

        .splash__heading {
            display:   block;
            margin:    0;
            padding:   .5em 0 0 0;
            font-size: 1em;
            hyphens:   none;
        }

        .splash__heading-middle {
            display:   block;
            margin:    0;
            padding:   0;
            font-size: 1em;
            hyphens:   none;
        }

        .splash__message {
            display:   block;
            max-width: 8em;
            margin:    auto;
            padding:   0;
            font-size: .8em;
        }

        .splash__message-top {
            display:        block;
            max-width:      8em;
            margin:         auto;
            padding:        .5em 0 0 0;
            font-size:      .65em;
            letter-spacing: .04rem;
        }

        .splash__message-secondary {
            display:     block;
            max-width:   8em;
            margin:      0 auto;
            padding:     .35em 0 0 0;
            font-size:   .45em;
            line-height: 1;
        }

        &.splash--white {
            .splash__message-secondary {
                color: $hiper-blue;
            }

            .splash__heading,
            .splash__heading-middle,
            .splash__message,
            .splash__message-top {
                color: $hiper-red;
            }
        }

        &.black-friday {
            padding-top: .34em;

            .splash__message {
                padding-top: .0em;
                font-size:   .72em;
                line-height: .85em;
            }

            .splash__heading-middle {
                padding-top: .45em;
                font-size:   .5em;
                color:       $hiper-blue;
                line-height: 1.25em;

                span {
                    display:        inline-block;
                    padding-bottom: .1em;
                    font-size:      1.55em;
                    color:          $hiper-red;
                    vertical-align: middle;
                }
            }

            .splash__message-secondary {
                padding-top: .55em;
                font-size:   .4em;
            }
        }
    }
}

.content--campaign-address {
    display:         flex;
    position:        relative;
    flex-flow:       column;
    align-content:   center;
    justify-content: center;
    transition:      all .25s $ease-in-out-cubic;
    transform:       translateY(em(200px));
}

.address-lookup-typing .content--campaign-address {
    transform: translateY(em(50px));
}

.product-link__rebate {
    $fs: 18px;

    // Ribbon stuff stolen & reworked from:
    // https://codepen.io/nxworld/pen/oLdoWb
    // https://gist.github.com/CodeMyUI/73bf7d07b903424720173a24d7cec619

    /* common */
    &.ribbon {
        width:    em(100px, $fs);
        height:   em(100px, $fs);
        position: absolute;
        overflow: hidden;
    }

    &.ribbon::before,
    &.ribbon::after {
        display:  block;
        position: absolute;
        z-index:  -1;
        border:   5px solid darken($hiper-darkred, 10%);
        content:  '';
    }

    &.ribbon span {
        display:          block;
        width:            em(150px, $fs);
        position:         absolute;
        padding:          em(13px, $fs) 0 em(12px, $fs) em(20px, $fs);
        background-color: $hiper-darkred;
        box-shadow:       0 em(5px, $fs) em(10px, $fs) rgba(0, 0, 0, .1);
        font-size:        em($fs);
        color:            white;
        text-shadow:      0 1px 1px rgba(0, 0, 0, .2);
        line-height:      1;
        text-align:       center;
        text-transform:   uppercase;
    }

    /* top left*/
    &.ribbon-top-left {
        top:  em(-5px, $fs);
        left: em(-5px, $fs);
    }

    &.ribbon-top-left::before,
    &.ribbon-top-left::after {
        border-top-color:  transparent;
        border-left-color: transparent;
    }

    &.ribbon-top-left::before {
        top:   0;
        right: 0;
    }

    &.ribbon-top-left::after {
        bottom: 0;
        left:   0;
    }

    &.ribbon-top-left span {
        top:       em(15px, $fs);
        right:     em(-10px, $fs);
        transform: rotate(-45deg);
    }

    ///* top right*/
    //&.ribbon-top-right {
    //    top:   -10px;
    //    right: -10px;
    //}
    //&.ribbon-top-right::before,
    //&.ribbon-top-right::after {
    //    border-top-color:   transparent;
    //    border-right-color: transparent;
    //}
    //&.ribbon-top-right::before {
    //    top:  0;
    //    left: 0;
    //}
    //&.ribbon-top-right::after {
    //    bottom: 0;
    //    right:  0;
    //}
    //&.ribbon-top-right span {
    //    left:      -25px;
    //    top:       30px;
    //    transform: rotate(45deg);
    //}
    //
    ///* bottom left*/
    //&.ribbon-bottom-left {
    //    bottom: -10px;
    //    left:   -10px;
    //}
    //&.ribbon-bottom-left::before,
    //&.ribbon-bottom-left::after {
    //    border-bottom-color: transparent;
    //    border-left-color:   transparent;
    //}
    //&.ribbon-bottom-left::before {
    //    bottom: 0;
    //    right:  0;
    //}
    //&.ribbon-bottom-left::after {
    //    top:  0;
    //    left: 0;
    //}
    //&.ribbon-bottom-left span {
    //    right:     -25px;
    //    bottom:    30px;
    //    transform: rotate(225deg);
    //}
    //
    ///* bottom right*/
    //&.ribbon-bottom-right {
    //    bottom: -10px;
    //    right:  -10px;
    //}
    //&.ribbon-bottom-right::before,
    //&.ribbon-bottom-right::after {
    //    border-bottom-color: transparent;
    //    border-right-color:  transparent;
    //}
    //&.ribbon-bottom-right::before {
    //    bottom: 0;
    //    left:   0;
    //}
    //&.ribbon-bottom-right::after {
    //    top:   0;
    //    right: 0;
    //}
    //&.ribbon-bottom-right span {
    //    left:      -25px;
    //    bottom:    30px;
    //    transform: rotate(-225deg);
    //}
}

.product-list-top-campaign-banner {
    font-size:      .95em;
    max-width:      20em;
    margin:         .5em auto .75em auto;
    background:     $hiper-blue;
    padding:        .55em .75em;
    border-radius:  .25em;
    text-transform: uppercase;
    line-height:    .95em;

    .line {
        display: block;
    }

    .price {
        line-height:    2em;
        text-transform: uppercase;
        font-weight:    500;
        padding-top:    .3em;

        .number {
            font-size: 2em;
        }
    }

    .white {
        color: white;
    }

    .red {
        color: $hiper-red;
    }
}

section.blue-campaign-section {
    background:                 $hiper-blue;

    --heading-font-size:        2.2em;
    --heading-prefix-font-size: .4em;
    --conditions-font-size:     .7em;

    --badge-size:               6em;
    --badge-top:                1em;
    --badge-right:              1em;

    --speed-price-padding:      .4em 0 0 0;
    --speed-price-font-size:    2.2em;
    --blue-price-font-size:     2em;
    --blue-price-display:       block;
    --blue-price-line-height:   1;

    .left, .left * {
        text-align: left;
    }

    .heading {
        font-size: var(--heading-font-size);

        .line {
            display: block;
        }

        .prefix {
            font-size:      var(--heading-prefix-font-size);
            color:          white;
            padding-bottom: .2em;
        }

        .blue {
            color: $hiper-pastel-lightblue;
        }

        .red {
            color: $hiper-red;
        }
    }

    .speed-price {
        font-size:      var(--speed-price-font-size);
        padding:        var(--speed-price-padding);
        text-transform: uppercase;
        font-weight:    500;
        color:          white;
    }

    .red-price {
        display:     var(--blue-price-display);
        color:       $hiper-red;

        line-height: var(--blue-price-line-height);

        .number {
            font-size: var(--blue-price-font-size);
        }
    }

    .conditions {
        width:     100%;
        font-size: var(--conditions-font-size);
        color:     rgba(white, .75);
        color:     rgba(white, .5);
    }

    &.inverse-color {
        background: $hiper-pastel-lightblue;

        .speed-price,
        .blue {
            color: $hiper-blue;
        }
    }
}

.red-price {
    .number {
        position: relative;

        .flag {
            position:       absolute;
            top:            .5em;
            right:          0;
            font-size:      .2em;
            display:        block;
            background:     white;
            padding:        .25em .5em .1em;
            border-radius:  .2em;
            transform:      translateX(70%);
            letter-spacing: var(--letter-spacing-small);
        }
    }
}

section.green-campaign-section,
section.teal-campaign-section {
    background: $hiper-darkteal;
    text-align: center;
    padding:    1em 0;

    .heading {
        font-size:   2.2em;
        font-weight: 400;
        line-height: .9;
        padding:     0;
    }

    .speed {
        color: #ffffff;

        .sub {
            font-weight: 400;
        }
    }

    .price {
        line-height:    .9;
        padding-bottom: .1em;
        position:       relative;

        .md {
            font-size: 0.5em;
        }
    }

    .conditions {
        width:       100%;
        font-family: $font-cervo;
        font-weight: 300;
        font-size:   .9em;
        color:       rgba(white, .9);
        padding:     1em 0 0 0;
    }

    .insert-cta {
        font-size:  1.5em;
        margin-top: 1.5rem;
    }

    &.campaign-section--fibernet {
        .speed {
            font-weight: 500;
            line-height: .6;

            &.speed-500 .sub {
                font-size: .69em;
            }

            &.speed-1000 .sub {
                font-size: .71em;
            }
        }

        @media (min-width: 521px) {
            .speed {
                font-size: 5.2em;
                padding:   .13em .5rem 0 0;
            }

            .price {
                font-size: 8em;
            }

            .flex + .heading {
                padding-top: .35em;
            }
        }

        @media (max-width: $phone) {
            .heading {
                padding-bottom: 1rem;
            }

            .speed {
                font-size:   4.5em;
                padding:     0;
            }

            .price {
                font-size: 12em;
            }

            .conditions {
                padding-top: 0;
            }

            .flex + .heading {
                margin-top: -.35em;
            }
        }
    }


}
