.page-campaign-tdcnet {
    .hero-container,
    .hero-container--products,
    .hero-container--products-fiber {
        .hero-background-wrapper .hero-background {
            @media (max-width: $phone) {
                bottom: 10em;
            }
        }

        .hero-content .sub-tdcnet {
                max-width: 19em;
        }

        .conditions {color: $hiper-blue;}
    }
}

.page-campaign-tdcnet-migration {
    .hero-container--tilbud-1 {
        .hero-content {
            padding:   0 1em;
            max-width: 48em;
            gap:       1em 2em;

            .stamp {position: relative;}

            .splash {
                position:        absolute;
                right:           -6em;
                transform:       rotate(5deg);
                display:         flex;
                justify-content: center;
                align-items:     center;
                width:           10em;
                height:          10em;
                border-radius:   50%;
                font-size:       .5em;
                box-shadow:      var(--box-shadow);
                background:      linear-gradient(
                                     0deg,
                                     $hiper-darkred 0%,
                                     $hiper-red 60%
                                 );

                p {
                    margin-top:  -.5em;
                    font-size:   1em;
                    line-height: 1.1;
                    color:       #ffffff;
                    text-align:  center;

                    & > span,
                    & > strong {display: block;}

                    & > strong {
                        font-size:   3.25em;
                        line-height: 1;
                    }

                    .comma-dash {margin-right: 0;}
                }
            }
        }
    }

    .hero-container--tilbud-2 {
        gap:     1em;
        padding: 1em .5em;

        .call-me-inline {
            background:    $hiper-red;
            border-radius: 2em;
            box-shadow:    var(--box-shadow);

            form {margin: .5em 0 0;}

            .icon {
                color: #ffffff;
                fill:  $hiper-red;
            }
        }

        .conditions {padding: 0;}
    }

    @media (min-width: 801px) {
        .hero-container--tilbud-1 .hero-content {
            .stamp  {margin-bottom: -1.5rem;}
            .splash {top: -1em;}
        }

        .hero-container--tilbud-2 .call-me-inline {
            .content {
                padding:   1em 1.25em 1.25em;
                max-width: 36em;
            }

            h3 strong {
                font-size: 1.2em;
            }
        }
    }

    @media (max-width: 1150px) {
        .hero-container--tilbud-1 .hero-content {
            gap:       1em;
            max-width: 38em;
        }
    }

    @media (max-width: 800px) {
        .hero-container--tilbud-1 {
            padding-top: 6.5em;

            .hero-content {
                .stamp  {margin-bottom: -1rem;}
                .splash {top: 0;}
            }
        }

        .hero-container--tilbud-2 .call-me-inline {
            .content {
                padding:   .5em 1em 1em;
                max-width: 32em;
            }

            h3 {
                font-size: 1em;

                strong {font-size: 1.4em;}
            }
        }
    }

    @media (max-width: $phone) {
        .hero-container--tilbud-1 .hero-content .stamp {
            margin-left: -2em;
        }
    }
}
