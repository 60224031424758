.page-campaign-fibernet {

    // region fibia (delete this)

    --line-display:            inline;
    --heading-font-size:       #{em(45px)};
    --content-justify-content: space-between;
    --splash-bottom:           1.4em;
    --splash-right:            auto;
    --splash-left:             auto;
    --splash-font-size:        #{em(35px)};

    @media (min-width: 450px) {
        --heading-font-size: #{em(50px)};
        --splash-bottom:     1.3em;
        --splash-font-size:  #{em(40px)};
    }
    @media (min-width: 620px) {
        --line-display:            block;
        --heading-font-size:       #{em(60px)};
        --content-justify-content: flex-end;
        --splash-bottom:           .65em;
        --splash-right:            .65em;
        --splash-left:             auto;
    }
    @media (min-width: 700px) {
        --heading-font-size: #{em(65px)};
    }

    .fiber-network-hero {
        background-image:    imgurl('campaigns/fibernet/fibernet-hero.jpg');
        background-size:     cover;
        background-position: center;
        min-height:          em(400px);
        color:               white;

        .content {
            position:        relative;
            display:         flex;
            flex-flow:       column;
            justify-content: var(--content-justify-content);
            min-height:      em(400px);
        }

        .line {
            display: var(--line-display);
        }

        .heading {
            color:          white;
            font-size:      var(--heading-font-size);
            text-align:     left;
            line-height:    1;
            font-style:     italic;
            color:          white;
            text-shadow:    0 .1em .25em rgba(190, 0, 90, .75);
            letter-spacing: var(--letter-spacing-big);
        }

        .conditions {
            width:       100%;
            font-size:   .8em;
            text-shadow: 0 .15em .5em rgba(0, 0, 0, .75);
        }

        .splash-fiber-network-before-now {
            position:         absolute;
            bottom:           var(--splash-bottom);
            right:            var(--splash-right);
            left:             var(--splash-left);
            background-color: $hiper-red;
            border-radius:    em(3px);
            font-size:        var(--splash-font-size);
            font-style:       italic;
            font-weight:      300;
            line-height:      1.2em;
            padding:          .3em .8em .1em .8em;
            text-align:       center;
            letter-spacing:   var(--letter-spacing-big);

            .strikethrough-price {
                &:before {
                    border-bottom-color: $hiper-blue;
                    border-bottom-width: .1em;
                    width:               110%;
                    right:               -5%;
                    transform:           skewY(-10deg) translate(0%, -50%);
                }
            }
        }
    }

    // endregion fibia (delete this)

    .section-reasons {
        padding-bottom: em(30px);
    }

    .section-white {
        padding-bottom: em(30px);
    }

    .below-address-lookup {
    }
}

.section-spies-beach {
    display:             flex;

    background-image:    imgurl('campaigns/fibernet/palms.jpg');
    background-size:     cover;
    background-position: center;
    min-height:          em(450px);

    color:               white;

    .content {
        margin-bottom: 1em;
        background:    rgba($hiper-red, .8);
        padding:       .5em 1em;
    }

    .heading {
        color:   inherit;
        padding: 0;
    }

    p {
        margin:    0;
        padding:   0;
        font-size: 1.2em;
    }
}

.carousel-item .spies-splash,
.spies-splash {
    --spies-splash-position:  absolute;
    --spies-splash-width:     55%;
    --spies-splash-font-size: .7em;
    --spies-splash-top:       .5em;
    --spies-splash-right:     .5em;
    --spies-line-display:     inline;
    --spies-palms-display:    none;
    --spies-logo:             4em;

    @media (min-width: 620px) {
        --spies-splash-position:  absolute;
        --spies-splash-width:     16em;
        --spies-splash-font-size: .9em;
        --spies-splash-top:       1em;
        --spies-splash-right:     1em;
        --spies-line-display:     block;
        --spies-logo:             5.25em;
    }
    @media (min-width: 700px) {
        --spies-palms-display: block;
        --spies-splash-width:  22.75em;
    }
    @media (min-width: 1441px) {
        --spies-splash-font-size: 1.05em;
        --spies-splash-top:       2em;
        --spies-splash-right:     2em;
    }


    position:                 var(--spies-splash-position);
    width:                    var(--spies-splash-width);
    top:                      var(--spies-splash-top);
    right:                    var(--spies-splash-right);
    border-radius:            .3em;
    background:               $hiper-blue;
    color:                    white;
    display:                  flex;
    font-size:                var(--spies-splash-font-size);
    border-bottom:            0;


    .line {
        display: var(--spies-line-display);
    }

    .palms {
        display:                   var(--spies-palms-display);
        width:                     6em;
        background-image:          imgurl('campaigns/fibernet/palms.jpg');
        background-size:           cover;
        background-position:       left;
        background-clip:           padding-box;
        border-top-left-radius:    inherit;
        border-bottom-left-radius: inherit;
    }

    .not-palms {
        font-style: italic;
        padding:    .75em 1em;

        h3 {
            margin:         0;
            padding:        0 0 .5em 0;
            font-size:      1.4em;
            text-transform: none;
            text-align:     left;
            font-weight:    200;
        }

        p {
            padding:   0;
            margin:    0;
            font-size: .8em;
        }

        .spies-logo {
            max-width: var(--spies-logo);
            display:   block;
            position:  absolute;
            right:     1em;
            bottom:    .5em;
        }
    }
}

.fibernet-insert {
    background-image:    imgurl('campaigns/fibernet/fibernet-hero.jpg');
    background-size:     cover;
    background-position: center;

    color:               white;
    padding-top:         8em;
    line-height:         1;
    text-shadow:         0 .15em .5em rgba(0, 0, 0, .75);

    .content {
        text-align: center;
    }

    .heading {
        color:   inherit;
        padding: 0;
        margin:  0;
    }

    p {
        font-size: 1.4em;
        padding:   0;
        margin:    0;
    }

    .button {
        text-shadow: none;
        margin:      .5em 0 0 0;
    }
}


.fibernet-hero,
.carousel-item.fibernet-hero {
    --hero-background:          #{$color-ming};

    --heading-font-size:        2.2em;
    --heading-padding-bottom:   0;
    --heading-prefix-font-size: .4em;
    --conditions-font-size:     .7em;

    --badge-size:               6em;
    --badge-top:                1em;
    --badge-right:              1em;

    --speed-price-margin:       0;
    --speed-price-font-size:    2.2em;
    --blue-price-font-size:     2em;
    --blue-price-display:       block;
    --blue-price-line-height:   1;

    @media (min-width: 450px) {
        --hero-background:       #{imgurl('campaigns/loyalty_group/hero-900.jpg')};
        --badge-size:            8em;
        --badge-top:             2em;
        --badge-right:           2em;

        --speed-price-margin:    0;
        --speed-price-font-size: 2.5em;
    }

    @media (min-width: 680px) {
        --badge-size:             10em;
        --heading-padding-bottom: .3125em;
    }
    @media (min-width: 820px) {
        --hero-background:       #{imgurl('campaigns/loyalty_group/hero-2000.jpg')};
        --speed-price-font-size: 2.8em;
        --speed-price-margin:    .75 0 0 0;
        --badge-size:            13em;
    }
    @media (min-width: 3000px) {
        --hero-background: #{imgurl('campaigns/loyalty_group/hero.jpg')};
    }

    background:                 var(--hero-background);
    background-size:            cover;
    background-position:        center;

    .content {
        position: relative;
        width:    100%;
        height:   100%;
    }

    .left {
        display:         flex;
        flex-flow:       column;
        height:          100%;
        justify-content: flex-end;
        text-shadow:     0 .025em .175em rgba(black, .5);
    }

    .left, .left * {
        text-align: left;
    }

    .heading {
        font-size:      var(--heading-font-size);
        padding-bottom: var(--heading-padding-bottom);

        .line {
            display: block;
        }

        .prefix {
            font-size:      var(--heading-prefix-font-size);
            color:          white;
            padding-bottom: .2em;
        }

        .blue {
            color: #23d5d8;
        }

        .red {
            color: $hiper-red;
        }

        .white {
            color: white;
        }
    }

    .speed-price {
        font-size:      var(--speed-price-font-size);
        margin:         var(--speed-price-margin);
        text-transform: uppercase;
        font-weight:    500;
        color:          desaturate($hiper-red, 20%);
        color:          $hiper-red;
    }

    .red-price {
        display:     var(--blue-price-display);
        color:       inherit;

        line-height: var(--blue-price-line-height);

        .number {
            font-size: var(--blue-price-font-size);

            .flag {
                text-shadow: none;
                box-shadow:  0 .025em .175em rgba(black, .5);
            }
        }
    }

    .conditions {
        width:     100%;
        font-size: var(--conditions-font-size);
        color:     rgba(white, .75);
        color:     rgba(white, .5);
    }

    .badge {
        display:       block;
        position:      absolute;
        top:           var(--badge-top);
        right:         var(--badge-right);
        width:         var(--badge-size);
        height:        var(--badge-size);
        border-radius: 50%;
        transform:     rotate(10deg);
        border-bottom: 0;
        padding:       0;
        margin:        0;

        &:hover {
            box-shadow: 0 .25em .5em rgba(190, 0, 90, .75);
        }

        svg {
            width: inherit;
        }
    }
}
