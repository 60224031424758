.heading--faq {
    max-width: em(650px, 16px * em(44px));
    margin:    auto;
}

.faq-nowrap {
    white-space: nowrap;
}

.faq-question-list {
    font-size: em(18px);
}

.faq-answer {
    margin-bottom:    2em;
    padding:          .75em 1em;
    border-radius:    em(5px);
    background-color: white;

    p:last-child {
        padding-bottom: 0;
    }

    table:last-child {
        margin-bottom: 0;
    }
}


.page-faq-payment-how-to .content {
    .icon {
        width:  em(100px);
        height: em(100px);
    }
}

.faq-cards-grid {
    flex-flow: row wrap;
    gap:       1em;

    .icon {
        width:  em(100px);
        height: em(100px);
    }
}
