// region: Cookie button
#Coi-Renew {
    display:       none;
    box-sizing:    border-box;
    position:      fixed;
    left:          20px;
    bottom:        15px;
    width:         42px;
    height:        42px;
    background:    $hiper-blue;
    z-index:       99999999999;
    padding:       7px;
    cursor:        pointer;
    border:        0 !important;
    border-radius: 999rem;

    svg {
        width: 100%;
    }

    .renew_path {
        fill: white !important;
    }
}
// end region: Cookie button

// region: Cookie banner
/**
Template name: Standard
URI: https://cookieinformation.com
Version 1.0.0
**/

#coiConsentBanner {
    box-shadow:       smooth-box-shadow-reverse();
    position:         fixed;
    width:            100%;
    max-height:       100%;
    z-index:          10000;
    font-size:        14px;
    line-height:      16px;
    background-color: $hiper-lightteal;
    box-sizing:       border-box;
    overflow-y:       auto;

    * {
        text-align:  initial;
        white-space: normal;
        font-family: $font-system;

        .coi-consent-banner__agree-button,
        .coi-consent-banner__decline-button,
        .summary-texts__show-details,
        .summary-texts__hide-details,
        .summary-texts__title,
        .coi-consent-banner__indicator,
        .coi-consent-banner__category-name--slider,
        .coi-consent-banner__why-cookies h2 {
            font-family:    $font-cervo;
            text-transform: uppercase;
            letter-spacing: var(--letter-spacing-big);
        }
    }

    &.BannerLeft {
        left: 0;
    }
    
    &.BannerRight {
        right: 0;
    }
    
    &.BannerBottom {
        bottom: 0;
    }

    // COI Base
    .coi-consent-banner__base {
        display: flex;
        width:   100%;

        // Consent Area
        .coi-consent-banner__consent-area {
            background-color: lighten($hiper-lightteal, 10%);
            display:          flex;
            justify-content:  flex-start;
            align-items:      center;
            box-sizing:       border-box;
            color:            #ffffff;

            .coi-button-group {
                display:       flex;
                margin-bottom: 1em;
                width:         100%;
                flex-wrap:     wrap;

                .coi-consent-banner__agree-button,
                .coi-consent-banner__decline-button {
                    color:          white;
                    min-width:      100px;
                    border-radius:  3px;
                    border:         none;
                    cursor:         pointer;
                    font-size:      1.5em;
                    line-height:    1;
                    font-weight:    400;
                    padding:        .5em;
                    text-align:     center !important;
                    flex:           1;
                }

                .coi-consent-banner__agree-button {
                    background: #3b8b3b;

                    &:hover {
                        background: darken(#3b8b3b, 5%);
                    }
                }

                .coi-consent-banner__decline-button {
                    background: $hiper-blue;

                    &:hover {
                        background: darken($hiper-blue, 10%);
                    }
                }

                @media (max-width: 1200px) {
                    flex-direction: column;

                    .coi-consent-banner__agree-button {
                        margin-bottom: .5em;
                    }
                }

                @media (min-width: 1201px) {
                    flex-direction: row;

                    .coi-consent-banner__agree-button {
                        margin-right: .5em;
                    }
                }

                #updateButton {
                    display: none;
                }
            }

            .coi-consent-banner__cookie-categories {
                display:         flex;
                flex-direction:  row;
                flex-wrap:       wrap;
                justify-content: flex-start;
                align-items:     flex-start;
                width:           100%;

                .coi-consent-banner__category-name {
                    position:        relative;
                    padding:         0 1em .5em 0;
                    cursor:          pointer;
                    font-weight:     400;
                    display:         flex;
                    flex-direction:  row-reverse;
                    align-items:     center;
                    justify-content: flex-end;
                    flex:            1 0 25%;

                    .coi-consent-banner__category-name:hover
                    .coi-consent-banner__category-checkbox
                    ~ .coi-consent-banner__checkmark {
                        border: 2px solid #ccc;
                    }

                    .coi-consent-banner__category-checkbox:checked
                    ~ .coi-consent-banner__checkmark {
                        border:           2px solid $hiper-blue;
                        background-color: $hiper-blue;
                    }

                    .coi-consent-banner__category-name:hover
                    .coi-consent-banner__category-checkbox:checked
                    ~ .coi-consent-banner__checkmark {
                        background-color: darken($hiper-blue, 10%);
                        border:           2px solid darken($hiper-blue, 10%);
                    }

                    .coi-consent-banner__category-checkbox:checked ~ .disabled {
                        background-color: $hiper-blue;
                    }

                    .coi-consent-banner__category-name:hover
                    .coi-consent-banner__category-checkbox:checked
                    ~ .disabled {
                        background-color: darken($hiper-blue, 10%);
                    }

                    .coi-consent-banner__category-checkbox {
                        position: absolute;
                        display:  none;
                        opacity:  0;
                        cursor:   pointer;
                    }

                    .coi-consent-banner__category-checkbox:checked
                    ~ .coi-consent-banner__checkmark:after {
                        display: block;
                    }

                    .coi-consent-banner__checkmark {
                        position:         relative;
                        height:           18px;
                        width:            18px;
                        border-radius:    50%;
                        background-color: #eeeeee;
                        margin-right:     .25em;
                    }

                    .coi-consent-banner__checkmark:after {
                        position:          absolute;
                        display:           none;
                        content:           '';
                        left:              5px;
                        top:               1px;
                        width:             5px;
                        height:            10px;
                        border:            solid white;
                        border-width:      0 2px 2px 0;
                        -webkit-transform: rotate(45deg);
                        -ms-transform:     rotate(45deg);
                        transform:         rotate(45deg);
                    }

                    .cookie_cat_necessary {
                        cursor: default;
                    }
                }
                
            }

            .coi-consent-banner__category-name {
                color: #000;
            }

            .summary-texts__show-details,
            .summary-texts__hide-details {
                text-decoration:  none;
                text-align:       center;
                font-size:        1.2em;
                line-height:      .8;
                font-weight:      300;
                color:            #fff;
                background-color: $hiper-teal;
                border-radius:    3px;
                padding:          .5em;
                cursor:           pointer;
                border:           none;

                &:hover {
                    color:            #ffffff;
                    background-color: lighten($hiper-teal, 10%);
                }
            }

            .summary-texts__hide-details {
                display:         none;
            }
        }
        // Consent Area End

        // Summary Area
        .coi-consent-banner__summary {
            .summary-texts__title {
                color: $hiper-blue;
            }

            .coi-consent-banner__summary-texts {
                color: #000;
            }

            .coi-consent-banner__summary-texts {
                display:         flex;
                flex-direction:  column;
                justify-content: center;
            }

            .summary-texts__title {
                font-size:       1.8em;
                font-weight:     500;
                line-height:     1;
            }

            p,
            ul {
                padding: 0;
                font-size: 1em;
                margin:    0 0 1em 0;
            }

            p:first-of-type,
            p:last-child {
                margin-bottom: 0;
            }
        }
        // Summary Area End
    }
    // COI Base End

    // COI Bottom
    .coi-consent-banner__bottom {
        width: 100%;
        max-height: 0;
        transition: max-height .2s;
        overflow:   auto;
        background: #fff;

        &::-webkit-scrollbar {
            width: 0;
        }

        .coi-consent-banner__indicators-container {
            display:       flex;
            height:        auto;

            .coi-consent-banner__indicator {
                text-align:       center !important;
                padding:          1em;
                font-size:        1.4em;
                font-weight:      500;
                line-height:      .8;
                color:            $hiper-blue;
                background-color: #f1f1f1;
                flex:             1;
                display:          flex;
                align-items:      center;
                justify-content:  center;
                border:           0;
                cursor:           pointer;
            
                &:focus {
                    outline:       initial;
                    box-shadow:    inset 0 0 0 2px $hiper-seablue;
                    border-radius: 3px;
                }
            }
        
            .coi-consent-banner__indicator.active {
                background: $hiper-red;
            }
            
            .coi-consent-banner__indicator.active {
                color: #fff;
            }
        }

        .coi-consent-banner__bottom-columns {
            width: 100%;

            .coi-consent-banner__categories-wrapper {
                position:   relative;

                .coi-consent-banner__category-container {
                    border-top: 1px solid #ccc;

                    .coi-consent-banner__category-expander:checked ~ .coi-consent-banner__name-container,
                    .coi-consent-banner__name-container:hover {
                        color: #fff;
                        background-color: $hiper-blue;
                    }

                    .coi-consent-banner__category-expander {
                        position: absolute !important;
                        opacity:  0;
                    }

                    .coi-consent-banner__category-expander:focus + .coi-consent-banner__name-container {
                        outline: 1px solid #6495ed;
                    }

                    .coi-consent-banner__name-container {
                        font-size:        1.2em;
                        line-height:      .8;
                        font-weight:      400;
                        color:            $hiper-blue;
                        background-color: #f1f1f1;
                        cursor:           pointer;

                        &:last-child {
                            border-bottom: none;
                        }

                        label {
                            padding:     1em;
                            display:     flex;
                            align-items: center;
                            cursor:      pointer;
                        }
                    }

                    .coi-consent-banner__description-container {
                        overflow: auto;

                        @media screen and (min-width: 761px) and (max-width: 1300px) {
                            top: -94px;
                        }

                        @media screen and (min-width: 1301px) {
                            top: -47px;
                        }

                        .coi-consent-banner__cookie-details {
                            border-top:       1px solid #eee;
                            color:            $hiper-blue;
                            display:          flex;
                            box-sizing:       border-box;
                            background-color: #fcfcfc;

                            .cookie-details__detail-container {
                                font-size:  1em;
                                padding:    0 .5em;
                                flex:       1;
                                word-break: break-word;

                                .cookie-details__detail-title {
                                    font-weight: 700;
                                    color:       $hiper-blue;
                                }
                            }

                            a {
                                border-bottom: 1px solid $hiper-red;
                            }
                        }
                    }
                }
            }

            .coi-consent-banner__about-owner-column {
                display:     none;

                .coi-consent-banner__left-column {
                    border-top:       1px solid #ccc;
                    display:          flex;
                    flex-direction:   column;
                    background-color: #f1f1f1;

                    .left-column__about-owner {
                        font-size: 1em;
                    }

                    .left-column__website_domains {
                        word-break: break-word;
                        display:    block;
                    }

                    .left-column__last-scan {
                        margin-top: 1em;
                    }

                    .left-column__bold-text {
                        font-weight: 700;
                    }
                }

                .coi-consent-banner__right-column {
                    height: auto;
                    
                    
                    @media screen and (min-width: 761px) and (max-width: 1300px) {
                        margin-top: -94px;
                    }

                    @media screen and (min-width: 1301px) {
                        margin-top: -47px;
                    }

                    .coi-consent-banner__why-cookies {
                        display:        flex;
                        flex-direction: column;
                        color:          #000;

                        h2 {
                            padding:     0;
                            font-size:   1.8em;
                            font-weight: 500;
                            margin-top:  .5em;
                            line-height: 1;
                            color:       $hiper-blue;

                            &:first-of-type {
                                margin-top: 0;
                            }
                        }

                        p {
                            text-align: left;
                            padding: 0;
                            font-size: 1em;
                            margin:    0 0 .5em 0;
                        }

                        a {
                            font-size: 1em;
                            border-bottom: 1px solid $hiper-red;
                        }

                        .coi-renew-button {
                            border: none;
                            background: $hiper-red;
                            color: #fff;
                            display: block;
                            width: fit-content;
                            padding: 0.5em;
                            border-radius: 3px;
                            margin-bottom: 0.5em;
                            line-height: 1;
                        }
                    }
                }
            }
        }

        .coi-consent-banner__bottom-bar {
            display:          flex;
            justify-content:  flex-start;
            align-items:      center;
            width:            100%;
            padding:          .5em;
            background-color: $hiper-teal;
        
            .coi-brand-logo {
                font-size:       9px;
                line-height:     11px;
                color:           #f1f1f1;
                text-decoration: none;
                border:          none;
            
                &:hover {
                    color: #f1f1f1;
                }
            
                span {
                    display: block;
                    color:   #fff;
                }
            }
        }
    }
    // COI Bottom End

    // Mediaquery
    @media screen and (max-width: 760px) {
        overflow-y: auto !important;

        .coi-consent-banner__base {
            flex-direction:         column-reverse;

            .coi-consent-banner__consent-area,
            .coi-consent-banner__summary {
                width:              100%;
            }

            .coi-consent-banner__consent-area {
                padding:            2em 1em;
                flex-direction:     column;

                .coi-button-group {
                    flex-direction: column;
                }

                .coi-consent-banner__cookie-categories {
                    margin-bottom:  .25em;
                }
            }

            .coi-consent-banner__summary {
                padding:            2em 1em;

                .summary-texts__title {
                    text-align:     center !important;
                }
            }
        }

        .coi-consent-banner__bottom {
            flex-direction: column;

            .coi-consent-banner__indicators-container {
                width: 100%;
            }

            .coi-consent-banner__bottom-columns {
                max-height: none;
            }

            .coi-consent-banner__description-container {
                height:     0;
                width:      100%;
                transition: height .9s;

                .coi-consent-banner__category-description {
                    padding: 2em 1em;
                }
        
                .coi-consent-banner__cookie-details {
                    flex-direction:     column;
                    padding:          1em .5em;
                
                    .cookie-details__detail-container {
                        line-height: 1.5;
                    }
                }
            }
            .coi-consent-banner__category-expander:checked ~ .coi-consent-banner__description-container {
                height: auto;
            }

            .coi-consent-banner__about-owner-column {
                flex-wrap: wrap-reverse;

                .coi-consent-banner__left-column,
                .coi-consent-banner__right-column {
                    width: 100%;
                    padding: 2em 1em;
                }

                .coi-consent-banner__left-column {
                    padding: 0 1em 2em 1em;
                }

                .coi-consent-banner__right-column {
                    margin-top: 0;
                    padding: 2em 1em 1em 1em;
                }
            }

            .coi-consent-banner__bottom-bar {
                width:        100% !important;
                border-right: none !important;

                .coi-brand-logo {
                    position: initial;
                }
            }
        }
    }

    @media screen and (min-width: 761px) {
        .coi-consent-banner__base,
        .coi-consent-banner__bottom {
            flex-direction: row;
        }

        .coi-consent-banner__consent-area {
            flex-direction: column;
            width:          25%;
            padding:        1em 2em;
        }

        .coi-consent-banner__cookie-categories {
            margin-bottom: 1em;
        }

        .coi-consent-banner__category-name:last-child {
            padding-right: 0;
        }

        .coi-consent-banner__checkmark {
            transition: background-color .2s;
        }

        .coi-consent-banner__category-name:hover
        .coi-consent-banner__category-checkbox ~ .coi-consent-banner__checkmark {
            background-color: $hiper-red;
        }

        .coi-consent-banner__summary {
            flex: 3 0 0;
            padding: 1em 2em;
        }

        .coi-consent-banner__bottom {
            .coi-consent-banner__indicators-container {
                width: 25%;
            }

            .coi-consent-banner__bottom-columns {
                max-height: 340px;
            }

            .coi-consent-banner__name-container {
                width: 25%;
            }

            .coi-consent-banner__description-container {
                height:     auto;
                bottom:     0;
                right:      0;
                position:   absolute;
                width:      75%;
                background: #fff;
                z-index:    1;

                .coi-consent-banner__category-description {
                    padding: 1em 2em;
                }

                .coi-consent-banner__cookie-details {
                    flex-direction: row;
                    padding:        1em 1.5em;

                    .cookie-details__detail-container.cookie-details__detail-container-provider {
                        text-overflow: ellipsis;
                        white-space:   nowrap;
                        overflow:      hidden;
                        width:         25%;
                    }

                    .cookie-details__detail-container.cookie-details__detail-container-purpose {
                        width: 50%;
                    }

                    .cookie-details__detail-container.cookie-details__detail-container-expiry {
                        width: 10%;
                    }

                    .cookie-details__detail-title {
                        margin-right: .25em;
                    }
                }
            }

            .coi-consent-banner__category-container:nth-child(1) .coi-consent-banner__description-container {
                z-index: 2;
            }

            .coi-consent-banner__category-expander:checked ~ .coi-consent-banner__description-container {
                z-index: 100;
            }

            .coi-consent-banner__left-column,
            .coi-consent-banner__right-column {
                padding: 1em 2em;
            }

            .coi-consent-banner__left-column {
                width: 25%;
            }

            .coi-consent-banner__right-column {
                flex: 4 0 0;
            }
        }
    }

    @media screen and (max-width: 1300px) {
        .coi-consent-banner__indicators-container {
            flex-direction: column;
        }
    }

    @media screen and (min-width: 1301px) {
        .coi-consent-banner__indicators-container {
            flex-direction: row;
        }
    }

    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
        .coi-consent-banner__base {
            flex-direction: row !important;
        }

        .coi-consent-banner__consent-area {
            width:        25%;
            flex:         none;
        }

        .coi-consent-banner__summary {
            text-align: left !important;
            width:      75%;
            display:    block;
        }

        @media screen and (max-width: 700px) {
            .coi-consent-banner__base {
                display: block !important;
            }
        }

        .coi-consent-banner__indicator {
            display:   block;
            max-width: 50%;
        }

        .coi-consent-banner__left-column {
            width: 25%;
            flex:  none;
        }
    }
    // Mediaquery End
}
// end region: Cookie banner

// region: Cookie overlay
/**
Template name: Overlay v2
URI: https://cookieinformation.com
Version 1.1.0
**/

.noScroll,
.noScroll body {
    overflow: hidden !important;
    position: relative;
    height:   100%;
}

#coiOverlay {
    background:      rgba(0, 0, 0, .7);
    display:         none;
    position:        fixed;
    top:             0;
    left:            0;
    width:           100%;
    height:          100%;
    justify-content: center;
    align-items:     center;
    z-index:         99999999999;
    padding:         1em;
    backdrop-filter: blur(4px);

    // region: Overlay click animation
    @-webkit-keyframes ci-bounce {
        0%   {transform: translateY(0);}
        10%  {transform: translateY(2px);}
        25%  {transform: translateY(-4px);}
        50%  {transform: translateY(4px);}
        75%  {transform: translateY(-2px);}
        100% {transform: translateY(0);}
    }

    @keyframes ci-bounce {
        0%   {transform: translateY(0);}
        10%  {transform: translateY(2px);}
        25%  {transform: translateY(-4px);}
        50%  {transform: translateY(4px);}
        75%  {transform: translateY(-2px);}
        100% {transform: translateY(0);}
    }

    .ci-bounce {
        -webkit-animation-duration: .5s;
        animation-duration:         .5s;
        -webkit-animation-name:     bounce;
        animation-name:             ci-bounce;
    }
    // end region: Overlay click animation

    .coi-banner__wrapper {
        $background-color: $hiper-darkteal;
        $text-color:       #ffffff;
        $heading-color:    $text-color;

        width:            100%;
        max-width:        600px;
        max-height:       100%;
        background-color: $background-color;
        color:            $text-color;
        overflow-x:       hidden;
        border-radius:    em(5px);

        // region: Scrollbar
        &::-webkit-scrollbar-thumb {
            background-color: rgba(#ffffff, .2);
            border-radius:    5px;
            z-index:          11000;
        }
        
        &::-webkit-scrollbar-track {
            background-color: none;
        }
        
        &::-webkit-scrollbar {
            width:            6px;
            background-color: rgba(#000000, .15);
            border-radius:    2px;
        }

        #coiConsentBannerCategoriesWrapper::-webkit-scrollbar-thumb,
        .cookiedeclaration_wrapper::-webkit-scrollbar-thumb {
            background-color: rgba(#ffffff, .2);
            border-radius:    5px;
        }

        #coiConsentBannerCategoriesWrapper::-webkit-scrollbar-track,
        .cookiedeclaration_wrapper::-webkit-scrollbar-track {
            background-color: none;
        }

        #coiConsentBannerCategoriesWrapper::-webkit-scrollbar,
        .cookiedeclaration_wrapper::-webkit-scrollbar {
            width:            6px;
            background-color: rgba(#000000, .15);
            border-radius:    2px;
        }

        .coi-consent-banner__description-container::-webkit-scrollbar-thumb {
            background-color: rgba(#ffffff, .2);
            border-radius:    5px;
        }
        
        .coi-consent-banner__description-container::-webkit-scrollbar-track {
            background-color: none;
        }
        
        .coi-consent-banner__description-container::-webkit-scrollbar {
            width:            6px;
            background-color: $background-color;
            border-radius:    2px;
            display:          none;
        }
        // end region: Scrollbar

        // region: General
        * {
            white-space: normal;
            text-align:  left;
            line-height: 1.5;
            font-family: $font-system;
            font-size:   16px;
        }

        h2, h3 {
            color:          $heading-color;
            font-family:    $font-cervo;
            letter-spacing: .025em;
            font-weight:    400;
            text-transform: uppercase;
            line-height:    1.1;
        }

        p, a {
            color:          $text-color;
            font-size:      1em;
            font-weight:    400;
            letter-spacing: normal;
        }

        a {
            border:                none;
            text-decoration:       underline 1px $hiper-red;
            text-underline-offset: 0.1em;
            transition:            text-decoration-color .2s ease;

            &:hover {
                color:                 $text-color;
                text-decoration-color: $text-color;
            }
        }

        ul, ol {
            padding:             0;
            list-style-position: inside;
        }

        table td,
        table th {
            padding:        0;
            border:         none;
            text-transform: none;
        }

        .coi-purpose-list {
            display:    inline;
            list-style: none;
            padding:    0;

            li {
                display: inline;

                &::after {
                    content: ", ";
                }

                &:last-child::after {
                    content:     "\a";
                    white-space: pre;
                }
            }
        }

        .coi-banner__page {
            flex:           1;
            flex-direction: column;
            align-items:    flex-end;
            display:        block;
            height:         100%;
        }

        .coi-banner__page,
        .coi-banner__summary,
        .coi-banner__cookiedeclaration,
        .cookiedeclaration_wrapper,
        .coi-consent-banner__categories-wrapper {
            width: 100%;
        }

        #hide_details,
        #updateButton {
            display: none;
        }
        // end region: General

        // region: Header
        .coi-banner__summary,
        .coi-banner__cookiedeclaration {
            background-color: $hiper-teal;
        }

        .coi-banner__headline {
            display:     block;
            font-size:   2.4em;
            text-align:  center;
            font-weight: 500;
            padding:     0;
        }

        .coi-banner__maintext a.coi-banner__policy,
        .coi-toggle-group button {
            text-align:  center;
            font-weight: 500;
            color:       $text-color;
        }

        .coi-banner__maintext {
            padding-top: .7em;

            .top-column__website-domains {
                display:     block;
                font-weight: 400;
            }

            .top-column__bold-text {
                font-weight: 700;
                display:     block;

                &:not(:first-child) {
                    margin-top: .5em;
                }
            }

            :last-child {
                margin-bottom: 0;
            }

            a.coi-banner__policy {
                display: block;
                width:   fit-content;
                margin:  0 auto;
            }
        }

        .coi-toggle-group {
            display:         flex;
            justify-content: center;
            margin-top:      .5em;

            button {
                border:                0;
                padding:               0;
                background:            none;
                text-decoration:       underline 1px $hiper-red;
                text-underline-offset: 0.1em;
                cursor:                pointer;
                transition:            text-decoration-color .2s ease;

                &:hover {
                    text-decoration-color: $text-color;
                }
            }
        }
        // end region: Header

        // region: Content
        // Cookie information
        .cookiedeclaration_wrapper {
            overflow-x: hidden;

            h2 {
                font-size: 1.8em;

                &:not(:first-child) {
                    padding-top: .7rem;
                }
            }

            ul {
                &:not(:last-child) {
                    padding-bottom: .7em;
                }

                li:last-child {
                    padding-bottom: 0;
                }
            }
        }

        // Dropdowns
        .coi-consent-banner__categories-wrapper {
            display:    none;
            transition: all .2s ease-in-out;
            overflow-x: hidden;
            position:   relative;

            .coi-consent-banner__category-container {
                display:        flex;
                flex-direction: column;
                margin-bottom:  2em;

                &:last-child {
                    margin-bottom: 0;
                }

                .coi-consent-banner__category-controls {
                    display:        flex;
                    flex-direction: column;
                    align-items:    flex-start;
                    flex-wrap:      wrap;

                    .coi-consent-banner__category-name {
                        cursor:           pointer;
                        display:          flex;
                        align-items:      baseline;
                        border:           none;
                        padding:          0;
                        margin-bottom:    .35em;
                        background-color: transparent;

                        .ci-arrow {
                            border:            solid $heading-color;
                            border-width:      0 .2em .2em 0;
                            display:           inline-block;
                            padding:           .25em;
                            transition:        all .2s ease;
                            margin:            0 .6em .5em -.1em;
                            width:             .5em;
                            height:            .5em;
                            top:               -.225em;
                            transform:         rotate(-45deg);
                            -webkit-transform: rotate(-45deg);
                            position:          relative;
                        }

                        &.ci-btn-tab-active .ci-arrow {
                            transform:         rotate(45deg);
                            -webkit-transform: rotate(45deg);
                            top:               -.375em;
                        }

                        h3 {
                            font-size:  1.5em;
                            word-break: keep-all;
                            padding:    0;
                            margin:     0;
                        }
                    }

                    .coi-consent-banner__category-description {
                        padding:     0 0 0 1.2em;
                        font-weight: 400;
                    }
                }

                .coi-consent-banner__description-container {
                    display:    none;
                    width:      100%;
                    align-self: flex-end;

                    &.tab-panel-active {
                        display: block;
                    }

                    .coi-consent-banner__found-cookies {
                        display:        flex;
                        flex-direction: column;
                        padding-left:   1.2em;

                        .coi-consent-banner__cookie-details {
                            flex-direction: column;
                            padding:        1em 0;

                            .cookie-details__detail-container,
                            .cookie-details__detail-container a {
                                flex:       1;
                                word-break: break-word;
                                display:    flex;
                            }

                            .cookie-details__detail-container {
                                font-weight: 400;

                                &.cookie-details__detail-container-data-processor-name {
                                    font-weight: 500;
                                }

                                .cookie-details__detail-title {
                                    font-weight:   700;
                                    flex:          2;
                                    padding-right: .5em;
                                }

                                .cookie-details__detail-content {
                                    flex: 4;
                                }
                            }
                        }
                    }
                }
            }
        }
        // end region: Content

        // region: Consent options
        .coi-button-group button span,
        .coi-banner-consent-group .coi-banner-consent-field div .coi-checkboxes .checkbox-text {
            font-family:    $font-cervo;
            text-transform: uppercase;
            letter-spacing: .025em;
            text-align:     center;
            color:          $text-color;
            line-height:    0.8;
        }

        .coi-button-group button span,
        .coi-banner-consent-group .coi-banner-consent-field div .coi-checkboxes span {
            pointer-events: none; // Google Analytics doesn't detect button click, if span is clicked
        }

        // Buttons
        .coi-banner__page-footer {
            $consent-background: $hiper-lightblue;

            background: $consent-background;
            width:      100%;
            position:   sticky;
            position:   -webkit-sticky;
            top:        0;
            bottom:     3em;
            z-index:    5000;
        }

        .coi-button-group {
            display:         flex;
            justify-content: center;
            gap:             .5em 1em;
            width:           100%;

            button {
                padding:          .5em;
                align-items:      center;
                justify-content:  center;
                border-radius:    em(5px);
                width:            calc(50% - .5em);
                height:           auto;
                min-height:       4em;
                border:           0;
                background:       none;
                transition:       all .2s ease;
                background-color: $hiper-teal;

                &:hover {
                    background-color: darken($background-color, 2%);
                }

                span {
                    font-size:   1.5em;
                    font-weight: 500;
                }

                &.coi-banner__accept {
                    background-color: #3b8b3b;

                    &:hover {
                        background-color: darken(#3b8b3b, 2%);
                    }
                }
            }

            .coi-banner__accept:not(:disabled),
            .coi-banner__decline:not(:disabled),
            .coi-banner__lastpage:not(:disabled),
            .coi-banner__nextpage:not(:disabled) {
                cursor:  pointer;
                display: flex;
            }
        }

        // Switches
        .coi-banner-consent-group {
            $border-color: darken(desaturate($hiper-lightblue, 25%), 10%);

            display:          flex;
            flex-flow:        row wrap;
            justify-content:  center;
            background-color: $hiper-lightblue;
            border-top:       1px solid $border-color;

            .coi-banner-consent-field {
                display:         flex;
                flex-direction:  column;
                justify-content: center;
                padding:         1em .5em;
                border-right:    solid 1px $border-color;

                &:last-child {
                    border-right: 0;
                }

                @media (min-width: 576px) {
                    width: 25%;
                }

                @media (min-width: 301px) and (max-width: 577px) {
                    width: 50%;

                    &:first-child,
                    &:nth-child(2) {
                        border-bottom: solid 1px $border-color;
                    }

                    &:nth-child(2) {
                        border-right: 0;
                    }
                }

                @media (max-width: 300px) {
                    width:        100%;
                    border-right: 0;

                    &:not(:last-child) {
                        border-bottom: solid 1px $border-color;
                    }
                }

                div {
                    display:         flex;
                    flex:            1;
                    justify-content: center;

                    .coi-checkboxes {
                        position:        relative;
                        display:         flex;
                        flex-direction:  column-reverse;
                        justify-content: center;
                        align-items:     center;

                        input {
                            opacity:          0;
                            position:         absolute;
                            z-index:          1;
                            width:            100%;
                            height:           100%;
                            cursor:           pointer;
                            clip:             initial;
                            left:             0;
                            top:              0;
                            display:          block;
                            border:           none;
                            background-color: transparent;
                        }

                        .checkbox-toggle {
                            background:       $border-color;
                            width:            3.5em;
                            height:           2em;
                            border-radius:    1em;
                            position:         relative;
                            cursor:           pointer;
                            transition:       .2s ease;
                            margin-top:       .5em;
                            transform-origin: center;

                            &::before {
                                transition:    .2s ease;
                                content:       '';
                                width:         1.5em;
                                height:        1.5em;
                                position:      absolute;
                                background:    #ffffff;
                                left:          .25em;
                                top:           .25em;
                                border-radius: 50%;
                            }
                        }

                        input[type=checkbox]:disabled,
                        input[type=checkbox]:disabled + .checkbox-toggle {
                            cursor: default;
                        }

                        input[type=checkbox]:disabled + .checkbox-toggle {
                            opacity: .5;
                        }

                        input[type=checkbox]:checked + .checkbox-toggle {
                            background-color: $hiper-blue;
                        }

                        input[type=checkbox]:checked + .checkbox-toggle::before {
                            left: 1.75em;
                        }

                        .checkbox-text {
                            font-size:   1.25em;
                            font-weight: 400;
                        }
                    }
                }
            }
        }
        // end region: Consent options

        // region: Footer with logo
        .coi-banner__footer {
            display:          flex;
            justify-content:  space-between;
            align-items:      center;
            flex-direction:   row;
            width:            100%;
            height:           3em;
            position:         sticky;
            position:         -webkit-sticky;
            top:              0;
            bottom:           0;
            background-color: darken($background-color, 5%);

            svg {
                max-height: 100%;

                .logo-text {fill: $text-color;}
                .logo-stroke {fill: url(#logo-gradient);}
            }

            .coi-banner__branding {
                font-size:   0.6em;
                line-height: .8;
                color:       rgba($text-color, .8);
                text-align:  right;

                a {
                    display:         block;
                    text-decoration: none;
                    color:           $text-color;
                    font-size:       1.2em;
                    font-weight:     bold;
                }
            }
        }
        // end region: Footer with logo

        // region: Mediaqueries
        @media (min-width: 521px) {
            .coi-banner__cookiedeclaration {
                padding: 2em;
            }

            .coi-banner__summary,
            .cookiedeclaration_wrapper {
                padding: 2em;
            }

            .coi-consent-banner__categories-wrapper {
                padding: 2em;
            }

            .coi-banner__page-footer {
                padding: 1em 2em;
            }

            .coi-banner__footer {
                padding: .5em 2em;
            }
        }

        @media (max-width: $phone) {
            .coi-banner__cookiedeclaration {
                padding: 2em 1em;
            }

            .coi-banner__summary,
            .cookiedeclaration_wrapper {
                padding: 2em 1em;
            }

            .coi-consent-banner__categories-wrapper {
                padding: 2em 1em;

                .coi-consent-banner__category-container
                .coi-consent-banner__description-container
                .coi-consent-banner__found-cookies {
                    padding-left: .7em;

                    .cookie-details__detail-container {
                        flex-direction: column;
                        padding:        .25em .5em;
                        border-radius:  em(3px);

                        &:nth-child(even) {
                            background-color: darken($background-color, 5%);
                        }

                        td {
                            margin:  0;
                            padding: 0;
                        }
                    }
                }
            }

            .coi-banner__page-footer {
                padding: 1em;
            }

            .coi-button-group {
                button {
                    margin: 0;
                }
            }

            .coi-banner__footer {
                padding: .5em 1em;
            }
        }

        @media (max-width: 400px) {
            .coi-consent-banner__categories-wrapper
            .coi-consent-banner__category-container {
                .coi-consent-banner__category-controls
                .coi-consent-banner__category-description,
                .coi-consent-banner__description-container
                .coi-consent-banner__found-cookies {
                    padding-left: 0;
                }

                .coi-consent-banner__description-container
                .coi-consent-banner__found-cookies {
                    width:        calc(100% + 1em);
                    margin-left:  -.5em;
                }
            }

            .coi-button-group {
                flex-direction: column;

                button {
                    width: 100%;
                }
            }
        }
        // end region: Mediaqueries
    }

    #coi-banner-categories {
        display: none;
    }
}

/* Focus Ring Style START */
#coi-banner-wrapper *:focus-visible,
.coi-checkboxes input:focus-visible + .checkbox-toggle,
#Coi-Renew:focus-visible {
    outline-offset: 2px;
    outline:        2px solid #303a4b;
    border-radius:  1px;
}

#coiConsentBannerCategoriesWrapper:focus-visible {
    outline-offset: -2px !important;
}

.coi-banner__wrapper:focus {
    outline: none !important;
}
/* Focus Ring Style END*/

// these two are nowhere to be found, but I've included them just in case
.coi-consent-banner__no-cookies {
    margin:    0;
    font-size: 1em;
}

.coi-consent-banner__category-expander {
    position: absolute;
    opacity:  0;
    z-index:  -1;
}

// end region: Cookie overlay
