.page-sbbu h1 + p {
    padding-bottom: 1em;
    text-align:     center;
}

.page-sbbu .blurb + p,
.selfcare-page-sbbu .selfcare-page-summary {
    padding-bottom: 2em;
}

.sbbu-form {
    position:   relative;
    text-align: left;

    fieldset,
    legend     {width: 100%;}
    legend + * {clear: both;}

    p, label, input {
        line-height: 1.4;
    }

    p {
        text-align: left;
        margin:     0;

        &.align-center {
            text-align: center;
        }

        &.small {
            font-size: .8em;
        }

        &.smaller {
            font-size:   .7em;
            line-height: 1.2;
        }

        &.no-space {
            padding-bottom: 0;
        }
    }

    input + p.small {
        padding-top: .25em;
    }

    label {
        display: inline-block;
    }

    input + label:not(.checkbox-label) {
        padding-top: .25em;
    }

    input, input::placeholder {
        text-align: left;
    }

    input {
        box-sizing: border-box;
        padding:    0 .4em;
    }

    hr {
        max-width: 100%;
        width:     100%;

        @media (min-width: 521px)  {margin: 2em auto;}
        @media (max-width: $phone) {margin: 1em auto;}
    }

    details {
        border-top:    1px solid $hiper-gray;
        border-bottom: 1px solid $hiper-gray;
    }

    summary {
        display:               grid;
        grid-template-columns: auto 1.5em;
        grid-template-areas:   "text icon";
        align-items:           center;
        gap:                   .5em;
        padding:               .75em 0;
        width:                 100%;
        color:                 $hiper-blue;

        .title {
            grid-area:   text;
            line-height: 1.4;
        }

        i {
            grid-area:      icon;
            position:       relative;
            display:        block;
            width:          1em;
            height:         .6em;
            font-size:      1.5em;
            transition:     transform .2s $ease-in-out-cubic;

            &::before, &::after {
                content:          '';
                display:          block;
                width:            .6em;
                height:           1px;
                position:         absolute;
                top:              50%;
                left:             0;
                background-color: $hiper-blue;
                transition:       transform .2s $ease-in-out-cubic;
            }

            &::before {transform: translate(0, -50%)   rotate(45deg);}
            &::after  {transform: translate(.4em, -50%) rotate(-45deg);}
        }

        &:hover {
            color: rgba($hiper-blue, .75);

            i::before, i::after {background-color: rgba($hiper-blue, .75);}
        }
    }

    summary + div {
        padding: 0 0 1em;
    }

    details[open] > summary > i {
        &::before {transform: translate(.4em, -50%) rotate(45deg);}
        &::after  {transform: translate(0, -50%)    rotate(-45deg);}
    }

    .select-container {
        position: relative;

        &:before {
            content:         '\25BC';
            position:        absolute;
            top:             0;
            right:           0;
            display:         flex;
            justify-content: center;
            align-items:     center;
            height:          100%;
            width:           1.5em;
            color:           $color-glacier;
            pointer-events:  none;
        }

        &:hover {
            &:before {
                color: darken($color-glacier, 10%);
            }
        }
    }

    select {
        display:         flex;
        overflow:        hidden;
        text-align-last: unset;
        padding:         0 .4em;
        text-transform:  none;

        &::-ms-expand {
            display: none;
        }

        &, option {
            text-align: left;
        }
    }

    .address {
        display:   flex;
        flex-flow: row wrap;
        gap:       .5em;
    }

    .installation-address {
        border: none;

        .accordion,
        summary {
            padding:       .5em .5em .5em .4em;
            border:        none;
            border-radius: .5em;
            color:         #ffffff;

            &:not(.accordion--footer):hover:not(.active) .symbol span,
            .symbol span,
            i::before,
            i::after {background-color: #ffffff;}
        }

        .accordion,
        summary {
            background-color: $hiper-teal;
        }

        .accordion:hover,
        summary:hover {
            background-color: $hiper-darkteal;
        }

        .accordion:hover:not(.active) .title {
            color: #ffffff;
        }

        .accordion-content,
        summary + div {padding: .5em 0 0 0;}
    }

    .input-container {
        margin-bottom: 1em;

        &:last-child       {margin-bottom:  0;}
        p:not(:last-child) {padding-bottom: .35em;}
    }

    .flatpickr-wrapper {
        @media (max-width: 400px) {
            .flatpickr-calendar {min-width: 0;}
        }

        @media (max-width: $phone-small) {
                .flatpickr-calendar {
                    margin-top: .5em;
                    padding:    0;

                    .flatpickr-day {width: calc(calc(100vw - 1em) / 7 - 4px );} // Adaptive width
                }
        }
    }

    .submit-button-flex {
        padding:        0 0 .5em 0;
        flex-direction: column;
    }
    .submit-button-flex + p {text-align: center;}

    @media (min-width: 521px) {
        .address input {
            &:nth-child(1) {
                flex: calc(50% - .25em) 1 1;
            }

            &:nth-child(2), &:nth-child(3), &:nth-child(4) {
                flex: calc(calc(50% - calc(.5em * 2.5)) / 3) 1 1;
            }

            &:nth-child(5), &:nth-child(6) {
                flex: calc(50% - .25em) 1 1;
            }
        }
    }

    @media (max-width: $phone) {
        input::placeholder {
            font-weight: 300;
            font-size:   .9em;
        }

        .address input {
            &:nth-child(1) {
                width: 100%;
            }

            &:nth-child(2), &:nth-child(3), &:nth-child(4) {
                flex: calc(calc(100% - calc(.5em * 2)) / 3) 1 1;
            }

            &:nth-child(5), &:nth-child(6) {
                flex: calc(50% - .25em) 1 1;
            }
        }

        p.with-checkbox {
            text-indent: 0;

            label {text-indent: 1.9em;}
        }
    }
}

.sbbu-form:not(.sbbu-form-1-2-3) {
    .hide-on-old {display: none;}

    h3:not(.title) {
        padding:   0 0 .5rem 0;
        font-size: 2em;
    }

    h3 .number {display: none;}

    fieldset > .accordion-container {
        border: 0;

        & > .accordion {
            justify-content: center;
            margin:          0;
            padding:         0;
            border:          0;
            pointer-events:  none;

            .title {
                margin:    0;
                padding:   0 0 .5rem 0;
                font-size: 2em;
            }

            .symbol {display: none;}
        }

        & > .accordion-content-container {
            overflow:       visible;
            opacity:        1;
            pointer-events: all;
            max-height:     none;

            & > .accordion-content {
                padding: 0 0 1em;
            }
        }
    }

    .sbbu-consent p.with-checkbox {
        padding-bottom: 0;
    }
}

.sbbu-form-1-2-3 {
    .hide-on-new,
    hr {display: none;}

    legend {
        float: left;
        width: 100%;

        .title {
            @include        font-weight-variable(500);
            display:        block;
            padding-bottom: .1em;
            font-size:      1.2em;
        }
    }

    h3 {
        @include        font-weight-variable(600);
        font-family:    $font-montserrat;
        text-align:     left;
        text-transform: none;
        line-height:    1.2;
        letter-spacing: normal;

        .number {
            display: inline;
            color:   $hiper-red;
        }
    }

    label .title {
        display: block;
    }

    fieldset {
        margin-bottom: 1em;

        &:last-child {margin-bottom: 0;}
    }

    fieldset > .accordion-container > .accordion-content-container > .accordion-content {
        padding: 0 0 1em;
    }

    .personal-information  > .accordion-container > .accordion .title {
        @include        font-weight-variable(400);
        padding-bottom: 0;
        font-family:    $font-montserrat;
        font-size:      1em;
        line-height:    1.4;
        text-transform: none;
    }

    .sbbu-consent p.with-checkbox {
        padding-bottom: 1em;
    }

    @media (min-width: 521px) {
        label .title {
            padding-bottom: .1em;
            font-size:      1.2em;
        }

        .sbbu-competitor > h3,
        .sbbu-competitor > legend h3,
        .sbbu-date > h3,
        .sbbu-date > legend h3,
        .sbbu-consent > h3,
        .sbbu-consent > legend h3 {
            padding:   0 0 1rem;
            font-size: 1.5em;
        }

        h3 .number {font-size: 1.8em;}

        hr {display: none;}

        fieldset {
            margin-bottom: 1em;

            &.personal-information {
                margin-bottom: 2em;
            }

            &:not(.personal-information) {
                padding:       .5em 1em 1em;
                border-radius: 1em;
                box-shadow:    var(--box-shadow);
            }
        }

        .personal-information > .accordion-container > .accordion,
        .personal-information > details > summary {
            padding: .5em 0;
        }
    }

    @media (max-width: $phone) {
        hr {margin: 2em auto 1.5em;}

        .sbbu-competitor + hr,
        .sbbu-date + hr {
            display: block;
        }

        label .title {
            padding-bottom: .25em;
            font-size:      1.1em;
        }

        .sbbu-competitor > h3,
        .sbbu-competitor > legend h3,
        .sbbu-date > h3,
        .sbbu-date > legend h3,
        .sbbu-consent > h3,
        .sbbu-consent > legend h3 {
            padding:   0 0 .5em;
            font-size: 1.25em;
        }

        h3 .number {font-size: 2em;}

        fieldset {
            margin-bottom: .5em;

            &.personal-information {
                margin-bottom: 1.5em;
            }
        }

        .personal-information > .accordion-container > .accordion,
        .personal-information > details > summary {
            padding: .25em 0;

            .title {font-size: .9em;}
        }

        .submit-button-flex {
            font-size: 1.2em;

            .button {padding: .5em 1em;}
        }
    }
}

.sbbu-custom-provider-hidden {
    display: none;
}
