.competition {
    display:         flex;
    margin:          0;
    width:           100%;
    justify-content: center;
    text-align:      center;

    &.competition--summer {
        max-height:       600px;
        flex-direction:   row-reverse;
        align-items:      stretch;
        background-color: $hiper-coax;
        overflow:         hidden;

        .competition-image {
            width:             50%;
            min-height:        40vw;
            max-height:        960px;
            background:        url(../images/pages/home/gavekort.jpg) center center;
            background-repeat: no-repeat;
            background-size:   cover;
        }

        .competition-text,
        .competition-image {
            max-width: 1920px;
        }

        .competition-text {
            width:           50%;
            padding:         1em;
            display:         flex;
            flex-direction:  column;
            justify-content: center;
            align-items:     center;

            h1,
            h2,
            h3,
            p {
                color: #fff;
            }

            .pre-title,
            .after-title {
                font-size:   1.8em;
                font-weight: 400;
                line-height: .95;
            }

            .title {
                font-size:      3em;
                font-weight:    500;
                padding-bottom: .22em;
                color:          $hiper-red;
                line-height:    0.9;
            }

            .small-title {
                display:          inline-block;
                background-color: $hiper-red;
                padding:          0.2em 0.75em;
                border-radius:    0.1875em;
                font-weight:      400;
                font-style:       italic;
                color:            #fff;
                text-transform:   uppercase;
                line-height:      0.95;
            }

            .competition-disclaimer {
                font-size:   .9em;
                max-width:   28em;
                padding-top: .75em;
            }

            @media (max-width: $phone) {
                .pre-title {
                    padding-bottom: .25em;
                }

                .title {
                    padding-bottom: .15em;
                }

                .competition-disclaimer {
                    padding-top: .5em;
                }
            }

            @media (min-width: 901px) {
                min-height: 460px;

                .right {
                    min-width:   24em;
                    padding-top: 1em;
                    margin-left: 1em;
                }
            }
        }

        @media (max-width: $tablet) {
            flex-direction: column-reverse;
            max-height:     none;

            .competition-text,
            .competition-image {
                width: 100%;
            }

            .competition-content {
                min-height: 50vw;
                padding:    2em 1em;
            }

            .competition-image {
                height: 70vw;
            }
        }
    }

    &.competition--winter {
        flex-direction:   column;
        align-items:      center;
        background-color: $hiper-coax;
        padding:          2em 1em;

        h2, p, span {
            color: #ffffff;
        }

        h2 {
            font-size:   3em;
            line-height: 1;

            > span {
                font-size:   .8em;
                font-weight: 400;
            }
        }

        section {
            display:         flex;
            justify-content: center;
            align-items:     center;
            gap:             0 1em;
        }

        .competition-image {
            position:  relative;
            margin:    1em 0;

            .splash {
                width:            8em;
                height:           8em;
                border-radius:    4em;
                background-color: $hiper-red;
                display:          flex;
                justify-content:  center;
                align-items:      center;
                position:         absolute;
                top:              50%;
                left:             0;
                transform:        translate(-75%, -55%)
                                  rotate(-15deg);

                p {
                    font-size:      1.4em;
                    font-weight:    400;
                    text-transform: uppercase;
                    line-height:    0.9;

                    span {
                        font-size:   1.6em;
                        margin-left: 0.075em;
                    }
                }
            }
        }

        .competition-image + p {
            
            background-color: $hiper-red;
            padding:          1em;
            border-radius:    em(5px);
            

            strong {
                text-transform: uppercase;
                font-size:      1.6em;
                font-weight:    400;
            }
        }

        @media (min-width: 901px) {
            section {
                transform: translateX(3em);
            }

            .competition-image + p {
                text-align: left;
                max-width:  20em;
            }
        }

        @media (max-width: 900px) {
            section {
                flex-direction: column;
            }

            .competition-image {
                transform: translateX(3em);
            }

            .competition-image + p {
                max-width:     25em;
                margin-bottom: 1.5em;
            }
        }

        @media (max-width: $phone) {
            .competition-image {
                max-width: 65vw;
                transform: translateX(2.25em);

                .splash {
                    width:         6em;
                    height:        6em;
                    border-radius: 3em;

                    p {font-size: 1em;}
                }
            }
        }
    }

    &.competition--receipt {
        width:         fit-content;
        padding:       1em;
        margin:        0 auto 2em auto;
        border-radius: em(5px);

        h2, p {
            padding-bottom: 0;
        }

        h2 {font-size: 2em;}

        .competition-image {
            margin-bottom: 0;

            img {max-height: 7em;}

            .splash {
                width:         6em;
                height:        6em;
                border-radius: 3em;

                p {font-size: 1em;}
            }

            @media (min-width: 521px) {transform: translateX(3em);}
        }
    }
}

.gavekort-lille {
    max-width: 150px;
    position:  absolute;
    top:       0px;

    @media (max-width: $phone) {
        display: none;
    }
}

.hero-ribbon--competition {
    @media (max-width: 416px) {h3 .hide-on-small-devices {display: none;}}

    @media (max-width: 372px) {img {height: 60px;}}

    @media (max-width: 345px) {img {display: none;}}

    @media (min-width: 346px) {
        padding: .25em .5em;
    }
}
