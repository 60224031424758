[class^='page-contact'] {
    .header {
        padding-bottom: 1em;

        p {
            padding-top: .5em;
            line-height: 1.4;
            hyphens:     manual;
        }
    }
}

.grid--contact {
    justify-content: center;
    margin-left:     auto;
    margin-right:    auto;
    color:           $hiper-blue;
    letter-spacing:  var(--letter-spacing-big);

    .grid-item {
        margin:           0;
        padding:          0;
        background-color: transparent;
        border:           none;
        font:             inherit;
        color:            inherit;
        cursor:           pointer;

        &:hover,
        &.active {
            color: $hiper-red;

            .icon {fill: $hiper-red;}
        }

        p     {transition: color .15s $ease-in-out-cubic;}
        .icon {transition: fill .15s $ease-in-out-cubic;}
    }

    .nowrap {
        white-space: nowrap; 
    }

    .text p:first-of-type {
        @include   font-weight-variable(600);
        font-size: .9em;
    }

    p, a {
        display:     block;
        line-height: 1.4;
    }

    p {
        margin:         0;
        padding-bottom: 0;
        font-size:      .8em;

        strong {@include font-weight-variable(500);}
    }

    a {
        border: none;

        &, * {transition: none;}
    }

    .phone-number span:not(:last-child) {
        margin-right: .125em;
    }

    .icon {
        display: block;
        width:   3em;
        height:  3em;
        color:   transparent;
        fill:    $hiper-blue;
    }
}

.contact-form {
    position:   relative;
    transition: all .2s $ease-in-out-quad;
    text-align: center;
    max-width:  42em;
    margin:     0 auto;

    .contact-form__address p {
        padding-bottom: 0;
    }

    input[type=submit] {
        display:          inline-block;
        width:            auto;
        height:           auto;
        padding:          .8rem 1.6rem;
        text-align:       center;
        font-size:        1.6em;
        line-height:      .8;
        color:            #ffffff;
        cursor:           pointer;
        appearance:       none;
        background-color: $hiper-teal;

            &:hover {
                background-color: $hiper-darkteal;
            }
    }
}

.contact-form__grid {
    display:   grid;
    max-width: 100%;
    gap:       .5em;

    &.contact-form__grid-2 {
        grid-template-columns: 1fr 1fr;
    }

    &.contact-form__grid-3 {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

.contact-form__categories {
    text-align: left;
}

.contact-form__address {
    max-height: 0;
    margin:     0;
    padding:    0;
    overflow:   hidden;
    opacity:    0;
    transition: .2s opacity $ease-in-out-quad,
                .2s max-height $ease-in-out-quad;

    @at-root .contact-form__address--visible {
        max-height: 100em;
        margin:     0;
        padding:    0;
        opacity:    1;
    }

    p {
        padding-bottom: 0;
    }
}

.contact-form__grid-address {
    display:          flex;
    flex-flow:        row wrap;
    align-items:      center;
    justify-content:  center;
    gap:              .5em;
    margin-bottom:    1em;
    padding:          .5em;
    border-radius:    .75em;
    background-color: $hiper-lightgray;
}

.contact-form__grid-address-item {
    display: block;
    margin:  0;
}

.contact-menu {
    display:         flex;
    justify-content: center;
    align-items:     stretch;
    margin:          1em auto;

    div {text-align: left;}

    h2.heading--montserrat {
        @include font-weight-variable(500);
        hyphens: none;
    }

    ul {
        margin-top: .5em;

        li {
            line-height: 1.4;

            &:last-child {padding-bottom: 0;}
        }
    }

    .order-link {
        display:          flex;
        justify-content:  center;
        align-items:      center;
        margin-top:       .75em;
        border:           none;
        background-color: $hiper-teal;
        box-shadow:       var(--box-shadow);
        line-height:      1.4;
        text-align:       center;
        color:            #ffffff;

        &:hover {
            background-color: $hiper-darkteal;
            box-shadow:       var(--box-shadow-hover);
        }

        .text {font-size: 1.1em;}
    }

    @media (min-width: 1001px) {
        div {
            padding:      0 2em;
            border-right: 1px solid $hiper-gray;

            &:first-child {padding-left: 0;}
            &:last-child {
                padding-right: 0;
                border-right:  0;
            }
        }

        h2 {
            font-size:  1.2em;
            text-align: left;
        }

        hr {display: none;}

        .order-link {
            padding:       1em;
            max-width:     14em;
            border-radius: 1em;
        }
    }

    @media (max-width: 1000px) {
        max-width:      26em;
        width:          100%;
        flex-direction: column;
        gap:            1.5em;

        h2 br {display:        none;}
        ul li {padding-bottom: .25em;}

        a {
            display:          block;
            width:            100%;
            padding:          .4em .6em;
            background-color: $hiper-lightgray;
            border-radius:    .5em;
            border:           none;
        }

        hr {
            max-width:        none;
            width:            100%;
            border:           0;
            margin:           0;
        }

        .order-link {
            padding:       .75em .5em;
            width:         100%;
            border-radius: .5em;
        }
    }
}

.contact-options-container {
    padding-bottom: 1em;

    .contact-options-button {
        font:             inherit;
        color:            inherit;
        @include          font-weight-variable(500);
        display:          block;
        margin:           0 auto 1em;
        padding:          .5em 1.5em;
        width:            fit-content;
        border-radius:    .5em;
        cursor:           pointer;
        background-color: #ffffff;
        border:           none;
        box-shadow:       var(--box-shadow);
        transition:       box-shadow .2s $ease-in-out-quad,
                          background-color .2s $ease-in-out-quad,
                          color .2s $ease-in-out-quad;

        &:hover {
            box-shadow: var(--box-shadow-hover);
        }

        &.active, &.active:hover {
            background-color: $hiper-red;
            color:            #ffffff
        }
    }

    .contact-options {
        @include    hidden;
        max-height: 0;
        transition: all .2s $ease-in-out-quad;

        &.visible {
            @include    visible;
            max-height: 100em;
        }
    }
}

.contact-content {
    margin:    0 auto 2em;
    max-width: 30em;

    p, .button {
        &:first-child {margin-top: 0;}
    }

    p {
        margin-top:     1em;
        padding-bottom: 0;
    }

    ul li {
        position:       relative;
        padding-left:   1em;
        padding-bottom: 0;

        &::before {
            @include  font-weight-variable(900);
            content:  '\2022';
            color:    $hiper-red;
            position: absolute;
            left:     0;
        }
    }

    .button {
        display:    block;
        margin-top: .5em;

        &:last-child {margin-bottom: 0;}
    }

    .address {
        @include          font-weight-variable(450);
        display:          block;
        width:            fit-content;
        padding:          .2em .6em;
        border-radius:    .5em;
        background-color: $hiper-lightgray;
    }

    &.contact-order {
        p, .call-button {
            margin-top: .7em;

            &:first-child {margin-top: 0;}
        }
    }
}

.contact-sub {
    margin-bottom: 1em;

    & > p {
        line-height: 1.4;
    }

    @media (min-width: 701px) {
        &.phone .grid-item--phone {display: block;}
        &.chat .grid-item--chat   {display: block;}
        &.form .grid-item--form   {display: block;}
    }
    @media (max-width: 700px) {
        &.phone .grid-item--phone {display: flex;}
        &.chat .grid-item--chat   {display: flex;}
        &.form .grid-item--form   {display: flex;}
    }

    &.contact-order {
        padding:       0 .5em;
        margin-bottom: 2em;

        p {
            padding:    0;
            text-align: center;
        }
    }
}

.contact .closed {
    text-align: center;
}

.page-contact-contact-form {
    .content {
        padding-left:  .5em;
        padding-right: .5em;
    }

    .blurb p {
        margin:    0 auto;
        max-width: 30em;
        font-size: .9em;
    }

    .contact-form {padding: 0;}
}

.contact-phone-graph {
    display:               grid;
    grid-template-columns: repeat(6, 1fr);
    gap:                   1em;

    .contact-phone-graph-item {
        display:     flex;
        align-items: center;

        p {
            @include font-weight-variable(500);
            margin:      0;
            padding:     0;
            line-height: 1.2;
        }

        span {
            display:       block;
            width:         100%;
            height:        1.5em;
            border-radius: .75em;
        }

        &.most-busy span  {background-color: $hiper-red;}
        &.busy span       {background-color: #ffd400;}
        &.least-busy span {background-color: $hiper-green;}
    }

    @media (max-width: 600px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: 401px) {
        margin-top: .5em;

        .contact-phone-graph-item {
            flex-direction:  column;
            justify-content: center;
            gap:             .25em;

            p {text-align: center;}
        }
    }

    @media (max-width: 400px) {
        grid-template-columns: 1fr;
        gap:                   1.5em;
        margin-top:            1em;

        .contact-phone-graph-item {
            justify-content: space-between;
            gap:             .5em;

            p {
                flex-shrink: 0;
                width:       4.5em;
            }
        }
    }
}

@media (min-width: 701px) {
    .grid--contact {
        flex-flow:     row wrap;
        align-items:   flex-start;
        gap:           2em;
        margin-top:    2em;
        margin-bottom: 1em;
        padding:       0 1em;

        .grid-item {
            text-align: center;
            transition: box-shadow .15s $ease-in-out-quad;

            &.hide-on-large-devices {display: none;}
        }

        .icon {margin: 0 auto;}
    }

    .contact-form {
        padding: 0 1em;

        &.visible {padding: 0 1em;}

        p,
        .contact-form__grid {
            padding-bottom: 1em;
        }

        .contact-form--address-container p {padding-bottom: .25em;}
    }

    .contact-content {
        padding:       1em;
        border-radius: 1em;
        box-shadow:    var(--box-shadow);
    }

    .contact-sub {
        .hide-on-large-devices {display: none;}

        & > p {
            max-width:  30em;
            text-align: center;
        }

        .grid--contact {
            margin-top: 0;
        }
    }

    .contact-search {
        padding: 1.5em 2.5em 2em;
    }
}

@media (max-width: 700px) {
    .grid--contact {
        flex-flow:     column nowrap;
        align-items:   center;
        gap:           .5em;
        margin-bottom: 1em;
        padding:       0 1em;
        width:         fit-content;

        .grid-item, a {
            width: 100%;
        }

        .grid-item {
            display:         flex;
            justify-content: flex-start;
            align-items:     center;
            gap:             .25em;
            text-align:      left;

            &.hide-on-small-devices {display: none;}
        }

        .text {font-size: 1.1em;}
    }

    .contact-form {
        padding: 0 .5em;

        &.visible {padding: 0 .5em;}

        p,
        .contact-form__grid {
            padding-bottom: .5em;
        }

        .contact-form--address-container p {padding-bottom: 0;}
    }

    .contact-content {
        padding: 0 .5em;

        &.contact-order {
            padding:       1em .5em;
            max-width:     calc(100% - 1em);
            border-radius: 1em;
            box-shadow:    var(--box-shadow);
        }

        .rto p {font-size: 1em}
    }

    .contact-content + hr {
        margin: 2em auto;
    }

    .contact-sub {
        hr {
            margin: 0 auto 2em;
        }

        & > p {
            padding-left:  .5em;
            padding-right: .5em;
        }
    }

    .contact-search {
        padding:   .75em 1em 1.5em;
        max-width: calc(100% - 1em);

        p {font-size: .9em;}
    }
}

@media (min-width: 601px) {
    .contact-form__grid-address-item {
        &:nth-child(1) {
            width: calc(calc(100% / 3 * 2) - .25em);
        }

        &:nth-child(n+2) {
            width: calc(calc(100% - 1em) / 3);
        }
    }
}

@media (max-width: 600px) {
    .contact-form__grid.contact-form__grid-3 {
        grid-template-columns: 1fr 1fr;
    }

    .contact-form__grid-address {
        gap: .5em;
    }

    .contact-form__grid-address-item {
        &:nth-child(1) {
            width: 100%;
        }

        &:nth-child(n+2) {
            width: calc(calc(100% - .5em) / 2);
        }
    }
}

@media (max-width: 400px) {
    .contact-form__categories .contact-form__grid-item {
        .checkbox-label {
            &:not(.checkbox-label--montserrat) {
                font-size:   1em;
                padding-top: .65rem;

                &::before {
                    margin-top: 0;
                }
            }

            &.checkbox-label--montserrat {
                font-size: .8em;
            }
        }
    }
}

.contact--simple {
    & > p {
        max-width:  40em;
        text-align: center;
    }
}

.contact--simple,
.contact--order {
    padding: 1em 1em 2em;

    .grid--contact {width: fit-content;}
}

.contact-search {
    margin: 0 auto 2em;
}

.page-contact-permissions {

    .list {
        counter-reset:     grid;
        display:           table;
        break-inside:      avoid-column;
        page-break-inside: avoid;
        padding-bottom:    .7em;

        &:last-child          {padding-bottom: 0;}
        p, h2, h3, h4, h5, h6 {text-align:     left;}
        p:not(:last-child)    {padding-bottom: .35em;}

        h2, h3, h4, h5, h6 {
            padding-top:    .35rem;
            padding-bottom: .2rem;

            &:first-child {padding-top: 0;}
        }

        li {
            position:       relative;
            padding-left:   1.6em;
            padding-bottom: .35em;

            &::before {
                position: absolute;
                left:     0;
                color:    $hiper-red;
            }

            &:last-child {padding-bottom: 0;}
        }

        &.list--number li::before {
            @include           font-weight-variable(600);
            counter-increment: grid;
            content:           counter(grid) '.';
        }

        &.list--bullet li::before {
            @include font-weight-variable(900);
            content: '\2022';
        }

        &.list--image {
            display:         flex;
            flex-direction:  column;
            justify-content: flex-start;
            align-items:     center;

            li {
                width:          100%;
                padding-bottom: .7em;

                &:last-child {
                    padding-bottom: 0;

                    p:last-child   {padding-bottom: 0;}
                    img:last-child {margin-bottom:  0;}
                }
            }
        }

        .list--number li::before {
            @include font-weight-variable(500);
            content: counter(grid, lower-alpha) '.';
        }

        .list--bullet li::before {
            @include font-weight-variable(900);
        }

        &.list--bullet .list--bullet li::before {
            @include font-weight-variable(400);
            content: '\26AC';
        }

        &.list--compact li {padding-bottom: 0;}

        li.warning {
            list-style-type:   none;
            counter-increment: grid -1;

            &::before {
                @include          font-weight-variable(600);
                content:          '!';
                top:              .2em;
                left:             -.4em;
                width:            1.5em;
                height:           1.5em;
                border-radius:    50%;
                background-color: $hiper-red;
                color:            #ffffff;
                text-align:       center;
                line-height:      1.6;
            }
        }
    }     
}