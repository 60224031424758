.icon {
    color: $hiper-blue;
    fill:  white;

    svg {
        display:  block;
        width:    100%;
        height:   100%;
        overflow: visible;
    }
}

/*
Size information for optical sizes, if ellipse is transparent.

Portrait:
    Ellipse is 1.333333 times taller than icon
    Icon is 0.75 times as tall as ellipse

Square:
    Ellipse is 1.6 times taller than icon
    Icon is 0.625 times as tall as ellipse

Landscape:
    Ellipse is 2 times taller than icon
    Icon is 0.5 times as tall as ellipse
*/

.icon-square-fill-fiber-fbsa,
.icon-square-fill-fiber,
.icon-fiber-fbsa,
.icon-fiber {
    color: $hiper-fiber;
    fill:  white;
}

.icon-square-fill-coax,
.icon-coax {
    color: $hiper-coax;
    fill:  white;
}

.icon-square-fill-dsl,
.icon-dsl {
    color: $hiper-dsl;
    fill:  white;
}

.icon-arrow {
    color: $hiper-red;
    fill:  white;
}

.icon-edit-pen {
    fill: $hiper-blue;
}

// <span class="icon icon-exclamation-mark"><span>!</span></span>
.icon-exclamation-mark {
    display:          flex;
    justify-content:  center;
    align-items:      center;
    width:            1em;
    height:           1em;
    border-radius:    .5em;
    color:            #ffffff;
    background-color: $hiper-red;

    span {
        @include     font-weight-variable(600);
        font-family: $font-montserrat;
        font-size:   .75em;
    }
}

.icon-wrench {
    color: $hiper-blue;
}

.icon-people {
    color: $color-pewter;
}

.icon-fb {
    color: $color-facebook;
}

.icon-dankort,
.icon-visa,
.icon-visa-electron,
.icon-mastercard {
    color: $hiper-teal;
}

.icon-rocket-color {
    .rocket-body {
        fill: #ffffff;
    }

    .rocket-wings {
        fill: #cccccc;
    }

    .rocket-window {
        fill: #303a4b;
    }

    .rocket-reflection {
        fill:              none;
        stroke:            #ffffff;
        stroke-linecap:    round;
        stroke-miterlimit: 4;
        stroke-width:      .125em;
    }

    .rocket-flame-1 {
        fill: #eaaf1f;
    }

    .rocket-flame-2 {
        fill: #e9314a;
    }
}
