@function imgurl($file) {
    @return url('../images/#{$file}');
}

// https://github.com/thoughtbot/bourbon/blob/v4-stable/app/assets/stylesheets/functions/_strip-units.scss
@function strip-units($value) {
    @return ($value / ($value * 0 + 1));
}

$em-base: 16px !default;

// https://github.com/thoughtbot/bourbon/blob/v4-stable/app/assets/stylesheets/functions/_px-to-em.scss
@function em($pxval, $base: $em-base) {
    @if not unitless($pxval) {
        $pxval: strip-units($pxval);
    }
    @if not unitless($base) {
        $base: strip-units($base);
    }
    @return ($pxval / $base) * 1em;
}

// https://raw.githubusercontent.com/thoughtbot/bourbon/v4-stable/app/assets/stylesheets/functions/_shade.scss
@function shade($color, $percent) {
    @return mix(black, $color, $percent);
}

// https://hugogiraudel.com/2013/08/08/advanced-sass-list-functions/
@function reverse($list, $recursive: false) {
    $result: ();

    @for $i from length($list) * -1 through -1 {
        @if type-of(nth($list, abs($i))) == list and $recursive {
            $result: append($result, reverse(nth($list, abs($i)), $recursive));
        } @else {
            $result: append($result, nth($list, abs($i)));
        }
    }

    @return $result;
}

// https://hugogiraudel.com/2013/08/08/advanced-sass-list-functions/
@function implode($list, $glue: '', $is-nested: false) {
    $result: null;

    @for $i from 1 through length($list) {
        $e: nth($list, $i);

        @if type-of($e) == list {
            $result: $result#{implode($e, $glue, true)};
        } @else {
            $result: if(
                    $i != length($list) or $is-nested,
                    $result #{$e}#{$glue},
                    $result#{$e}
            );
        }
    }

    @return $result;
}

// So, this could be way smarter, but this works and looks good.
// Based on:
//  - https://tobiasahlin.com/blog/layered-smooth-box-shadows/
//  - https://brumm.af/shadows
//
// Additional reading:
//  - https://github.com/twbs/bootstrap/issues/29463#issuecomment-561026411
//  - https://codepen.io/emdeoh/pen/zYYGZGx
@function smooth-box-shadow($color:black, $alpha:.07, $length:100px, $blur:80px) {
    @return 0 em($length * .03375) em($blur * .03375) rgba($color, $alpha * .314285714),
    0 em($length * .08625) em($blur * .08625) rgba($color, $alpha * .442857143),
    0 em($length * .1775) em($blur * .1775) rgba($color, $alpha * .557142857),
    0 em($length * .365) em($blur * .365) rgba($color, $alpha * .685714286),
    0 em($length) em($blur) rgba($color, $alpha);
}

@function smooth-box-shadow-reverse($color:black, $alpha:.07, $length:100px, $blur:80px) {
    @return 0 em(-$length * .03375) em($blur * .03375) rgba($color, $alpha * .314285714),
    0 em(-$length * .08625) em($blur * .08625) rgba($color, $alpha * .442857143),
    0 em(-$length * .1775) em($blur * .1775) rgba($color, $alpha * .557142857),
    0 em(-$length * .365) em($blur * .365) rgba($color, $alpha * .685714286),
    0 em(-$length) em($blur) rgba($color, $alpha);
}

@mixin hidden {
    opacity:    0;
    overflow:   hidden;
    visibility: hidden;
}

@mixin visible {
    opacity:    1;
    overflow:   visible;
    visibility: visible;
}

@mixin hiper-text-stroke {
    position: relative;

    &::after {
        content:   '';
        position:  absolute;
        font-size: 1em;
        min-height: 2px;
        height:    .07em;
        right:     .1em;
        top:       calc(100% - .15em);
        left:      0;
        transform: skewX(307.5deg);

        .heading--montserrat & {height: .1em;}
    }
}

@mixin font-weight-variable($width) {
    font-weight:             $width;
    font-variation-settings: 'wght' $width;
}
