// region: Blur out under the open widget

// commented out. no back button on show-manual, user forced to reload page
/*
.page-home .usp-section .usps,
.page-home .usp-section,
.page-products .products .content {

    transition: blur, opacity .18s $ease-in-out-cubic;

    @at-root {
        .address-lookup-typing &,
        .show-manual-address & {
            filter:  blur(.075em);
            opacity: .35;
        }
    }
}

.below-address-lookup {

    transition: blur, opacity .18s $ease-in-out-cubic;

    @at-root {
        .address-lookup-typing & {
            filter:   blur(.075em);
            opacity:  .35;
            position: relative;
            z-index:  -1;
        }
    }
}
*/

// endregion: Blur out under the open widget

#address-overlay {
    background-color: rgba(#000000, .5);
    backdrop-filter:  blur(2px);
    position:         fixed;
    width:            100vw;
    height:           100vh;
    top:              0;
    right:            0;
    bottom:           0;
    left:             0;
    z-index:          5;
    pointer-events:   none;
    opacity:          0;

    transition: opacity .3s $ease-in-out-cubic;

    &.active {
        opacity:        1;
        pointer-events: all;
    }
}

.address-widget-wrap {
    position: relative;
    height:   3.5em;

    .header-height {
        @include hidden;
        position:       absolute;
        width:          0;
        height:         $height-header;
        pointer-events: none;
    }
}

.address-widget {
    max-width:        var(--hero-max-width);
    width:            100%;
    min-height:       1em;
    position:         absolute;
    right:            0;
    left:             0;
    z-index:          $z-index-address-picker;
    margin:           auto;
    padding:          .4em;
    border-radius:    1.65em;
    background-color: $hiper-red;
    transition:       background-color $address-transition,
                      padding $address-transition,
                      border-radius $address-transition,
                      top $address-transition,
                      width .1s $ease-in-out-cubic,
                      box-shadow $address-transition;

    .show-manual-address & {
        background-color: $hiper-blue;
    }

    .address-lookup-typing &,
    .show-manual-address &,
    &.typing {
        box-shadow: 0 0 .25em rgba(#000000, .5),
                    0 1.5em 4em  rgba(#000000, .3);
    }

    li,
    input {text-align: center;}

    input {border: none;}

    .error {
        box-shadow: inset 0 0 0 1px $hiper-red,
                    0 0 0 2px $hiper-red;
    }

    .dawa-address,
    .manual-address {
        display:    flex;
        transition: all $address-transition;
        position:   relative;
    }

    .address-widget__left {
        width: 100%;

        #address-field {
            width:            calc(100% - 2.9rem);
            border:           none;
            border-radius:    1.25rem .5rem .5rem 1.25rem;
            background-color: rgba(#000000, .1);
            transition:       background-color $address-transition,
                              color            $address-transition,
                              width            $address-transition,
                              height           $address-transition;

            &:focus,
            &:not(:placeholder-shown) {
                background-color: #ffffff;
                color:            $hiper-blue;

                &::placeholder {color: $color-glacier;}
            }

            &::placeholder {
                color:      #ffffff;
                transition: color $address-transition;
            }
        }

        .dawa-address__propositions-wrap.visible {
            height:     auto;
            max-height: 13.75em;
        }
    }

    .address-widget__right {
        position:         absolute;
        top:              0;
        right:            0;
        background-color: transparent;
        transition:       background-color $address-transition;

        .icon {
            display:    block;
            width:      2.5em;
            height:     2.5em;
            margin:     0;
            color:      transparent;
            cursor:     pointer;
            transition: width $address-transition,
                        height $address-transition,
                        fill $address-transition;
        }

        .loader-spinner {
            padding: .25em;
            width:   2.5em;
            height:  2.5em;

            .path {stroke: #ffffff;}
        }
    }

    .state-search {
        .state-search {
            display: block;
        }

        .state-go {
            display: none;
        }
    }

    .state-go {
        .state-go {
            display:   block;
            animation: 2s $ease-out-cubic button-state-go infinite;

            @keyframes button-state-go {
                10% { transform: translateX(0); }
                20% { transform: translateX(3px); }
                30% { transform: translateX(0); }
                40% { transform: translateX(3px); }
                50% { transform: translateX(0); }
            }
        }

        .state-search {
            display: none;
        }
    }

    .search-failed {
        .dawa-address__address-list {
            display:          none;
            background-image: none;
        }

        .dawa-address__bottom-bar {
            display: none;
        }

        .dawa-address__search-error {
            display: block;
        }
    }

    .address-widget-shimmer {
        position:       absolute;
        top:            0;
        bottom:         0;
        left:           0;
        right:          0;
        width:          100%;
        height:         100%;
        overflow:       hidden;
        pointer-events: none;
        border-radius:  1.65em;

        transition:     border-radius $address-transition;

        &::after {
            content:        '';
            width:          100%;
            height:         100%;
            position:       absolute;
            top:            0;
            left:           0;
            transform:      translateX(-100%);
            pointer-events: none;

            animation:      addressLookupShimmer 10s ease 6;

            @media (min-width: 521px) {
                background: linear-gradient(120deg,
                    rgba(255, 255, 255, 0)  40%,
                    rgba(255, 255, 255, .3) 50%,
                    rgba(255, 255, 255, 0)  60%);

                @keyframes addressLookupShimmer {
                    0%   {transform: translateX(-100%);}
                    50%  {transform: translateX(-100%);}
                    70%  {transform: translateX(100%);}
                    100% {transform: translateX(100%);}
                }
            }

            @media (max-width: $phone) {
                background: linear-gradient(120deg,
                    rgba(255, 255, 255, 0)  30%,
                    rgba(255, 255, 255, .3) 50%,
                    rgba(255, 255, 255, 0)  70%);

                @keyframes addressLookupShimmer {
                    0%   {transform: translateX(-100%);}
                    50%  {transform: translateX(-100%);}
                    65%  {transform: translateX(100%);}
                    100% {transform: translateX(100%);}
                }
            }
        }
    }

    html.address-lookup-typing & .address-widget-shimmer,
    html.show-manual-address & .address-widget-shimmer,
    &.focus .address-widget-shimmer {
        display: none;
    }

    &.typing,
    &.error {
        background-color: $hiper-blue;
    }

    &.sticky {
        position: fixed;
        top:      $height-header;

        &:not(.focus):not(.typing) {
            @media (max-width: $phone) {
                padding: .25em;

                .dawa-address__propositions-wrap.visible {
                    margin-top: .25em;
                }

                .dawa-address__bottom-bar {
                    padding-top: .25em;
                }

                .address-widget__left {
                    margin-right: 0;

                    #address-field {
                        width:  calc(100% - 2.25rem);
                        height: 2rem;
                    }
                }

                html:not(.show-manual-address) & {
                    .address-widget__right .icon {
                        width:  2em;
                        height: 2em;
                    }
                }
            }
        }

        @media (min-width: 865px) {
            &:not(.focus),
            html.show-manual-address &.focus {
                border-radius: 0 0 1.65em 1.65em;
            }

            html:not(.show-manual-address) &:not(.focus):not(.typing),
            .address-widget-shimmer {
                border-radius: 0 0 1.35em 1.35em;
            }
        }

        @media (max-width: 864px) {
            &,
            .address-widget-shimmer {
                border-radius: 0;
            }
        }

        @media (min-width: 521px) {
            html:not(.show-manual-address) &.focus {
                top: calc(50% - 1.75em);
            }
        }
    }

    html:not(.show-manual-address) &.sticky:not(.typing),
    html:not(.show-manual-address) &.focus:not(.typing) {
        background-color: $hiper-red;

        .address-widget__left {
            #address-field {
                background-color: transparent;
                color:            #ffffff;

                &::placeholder {
                    color: #ffffff;
                }

                &:focus {
                    background-color: #ffffff;
                    color:            #000000;

                    &::placeholder {
                        color: $hiper-red;
                    }
                }
            }
        }

        @media (min-width: 521px) {
            &:not(.focus) {
                .address-widget__left {
                    margin-right: 0;

                    #address-field {
                        width:  calc(100% - 2.35rem);
                        height: 2rem;
                    }
                }

                .address-widget__right .icon {
                    width:  2em;
                    height: 2em;
                }
            }
        }
    }

    .rmo-container {
        padding: 1em 0 .5em;

        h1, h2, h3, h4, p {
            color:      #ffffff;
            text-align: center;
        }

        .grid {
            gap: .5em;
        }

        .continue-button {
            width:  2.5em;
            height: 2.5em;
        }

        .form__loader {
            display: block;
            margin:  .5em auto;
        }
    }
}

.dawa-address {
    .hide-dawa-address & {
        @include hidden;
        height: 0;
    }

    .loading {
        ////
        // TODO: Deprecated ?
        ////
        background: transparent imgurl('loader.gif') center center no-repeat;
    }

    li {
        @include font-weight-variable(400);
        display:        block;
        padding:        .5em .25em;
        border-radius:  .25em;
        line-height:    em(26px);
        letter-spacing: .02rem;
        cursor:         pointer;
        color:          #ffffff;

        &:nth-child(odd) {
            background-color: rgba(#ffffff, .075);
        }

        &:hover,
        &:focus {
            background-color: rgba(#ffffff, .2);
        }

        &.focus:hover,
        &.focus:focus,
        &.focus {
            background-color: $hiper-red;
        }
    }


    @at-root .dawa-address__propositions-wrap {
        @include    hidden;
        display:    flex;
        flex-flow:  column;
        height:     0;
        transition: all $address-transition;
        z-index:    15;

        &.visible {
            @include    visible;
            height:     em(220px);
            margin-top: .4em;
        }
    }

    @at-root .dawa-address__address-list {
        flex:                       1 1 auto;
        overflow-y:                 scroll;
        padding:                    0;

        -webkit-overflow-scrolling: touch;

        scrollbar-width:            none;
        -ms-overflow-style:         none;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    @at-root .dawa-address__bottom-bar {
        flex:       0 1 auto;
        padding:    .4em 0 0 0;
        text-align: center;
    }

    @at-root .dawa-address__search-error {
        display:   none;
        padding:   1em;
        color:     #fff;
        font-size: 1em;
        cursor:    pointer;
    }

    @at-root .dawa-address__address-not-found {
        background-color: rgba(#ffffff, .1);
        color:            #ffffff;

        &:hover,
        &:focus {
            background-color: rgba(#ffffff, .2);
        }
    }
}

.manual-address {
    @include hidden;
    height: 0;

    .show-manual-address & {
        @include visible;
        height: auto;
    }

    .street input,
    .manual-address__row:last-child .manual-address__field:first-child input,
    .manual-address__row:last-child .manual-address__field:last-child input {
        transition: border-radius $address-transition;
    }

    .street {
        width: 100%;

        .address-widget:not(.sticky) & input {
            border-radius: 1.25rem .5rem .5rem .5rem;
        }
    }

    .city {
        width: 66.666666666%;
    }

    @at-root .manual-address__row {
        display:        flex;
        max-width:      100%;
        padding-bottom: .4em;

        @media (max-width: $phone) {
            padding-bottom: .25em;
        }

        &:first-child {
            padding-right: 2.9em;

            @media (max-width: $phone) {
                padding-right: 2.75em;
            }
		}

        &:last-child {
            padding-bottom: 0;

            .manual-address__field:first-child input {
                border-radius: .5rem .5rem .5rem 1.25rem;
            }

            .manual-address__field:last-child input {
                border-radius: .5rem .5rem 1.25rem .5rem;
            }

            @media (max-width: 864px) {
                .address-widget.sticky & {
                    .manual-address__field:first-child input,
                    .manual-address__field:last-child input {
                        border-radius: .5rem;
                    }
                }
            }
        }
    }

    @at-root .manual-address__field {
        width:         33.333333333%;
        padding-right: .4em;

        @media (max-width: $phone) {
            padding-right: .25em;
        }

        &:last-child {
            padding-right: 0;
        }
    }
}

.no-js {
    .manual-address {
        @include visible;
        height: auto;
    }

    .dawa-address {
        @include hidden;
        height: 0;
    }
}

.manual-address-forced,
.show-manual-address {
    body:not(.page-order-contact) .address-widget-wrap {
        height: 9.5em;
    }
}
